import React, { useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import colors from "../../../colors";

export default function TermsCondition({ navigation, route }) {
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState();
  const getTermsAndConditions = async () => {
    try {
      setIsLoading(true);
      const res = await axiosInstance.get(
        apiURIV2 + "/content/vendor/terms-and-conditions"
      );
      if (res.status === 200) {
        setTermsAndConditions(res.data["data"]);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="flex-1 rounded-t-2xl lg:rounded-none bg-gray-50">
        <ScrollView>
          <View className=" max-w-screen-lg w-full p-4 mx-auto">
            <View className="my-5 hidden lg:flex">
              <Font className="text-lg font-semibold text-app-d">
                Terms Of Service
              </Font>
            </View>
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <View
                className=" w-full lg:w-4/6 p-0 lg:pr-4 pt-0"
                style={{ height: "auto" }}
              >
                <Card
                // title={`Last Updated : ${getFullDateWithWeekday(Date.now())}`}
                >
                  <View className="w-full p-4">
                    {isLoading ? (
                      <ActivityIndicator
                        animating={true}
                        color={colors.app.e}
                        size={"small"}
                        className="my-8 mx-4"
                      />
                    ) : termsAndConditions ? (
                      <Font className="text-app-d">{termsAndConditions}</Font>
                    ) : (
                      <View className="w-full flex flex-row justify-center items-center bg-app-a rounded-lg py-8 px-4 border border-gray-200 mb-4">
                        <Font className="text-app-d1">No Content Found</Font>
                      </View>
                    )}
                  </View>
                </Card>
              </View>
              <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
                <Widget></Widget>
              </View>
            </View>
            <Footer navigation={navigation}></Footer>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
