import "react-native-gesture-handler";
import "expo-dev-client";
import { Platform, BackHandler } from "react-native";
if (Platform.OS === "web") {
  global._frameTimestamp = null;
}
import "./main.css";
import { StatusBar } from "expo-status-bar";
import {
  useFonts,
  Quicksand_300Light,
  Quicksand_400Regular,
  Quicksand_500Medium,
  Quicksand_600SemiBold,
  Quicksand_700Bold,
} from "@expo-google-fonts/quicksand";
import { Main } from "./src/main";
import { SafeAreaProvider } from "react-native-safe-area-context";
import React from "react";
import { NativeWindStyleSheet } from "nativewind";
import Toast from "react-native-toast-message";
import ConfirmModal from "./src/components/general/modals/ConfirmModal";

NativeWindStyleSheet.setOutput({
  default: "native",
});

export default function App() {
  let [fontsLoaded] = useFonts({
    Quicksand_300Light,
    Quicksand_400Regular,
    Quicksand_500Medium,
    Quicksand_600SemiBold,
    Quicksand_700Bold,
  });

  const [exitModalVisible, setExitModalVisible] = React.useState(false);

  React.useEffect(() => {
    const backAction = () => {
      setExitModalVisible(true); // Open the confirmation modal
      return true; // Prevent default back behavior (exit the app)
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove(); // Cleanup the listener on component unmount
  }, []);

  const handleExitApp = () => {
    handleCancel();
    BackHandler.exitApp(); // Exit the app
  };

  const handleCancel = () => {
    setExitModalVisible(false); // Close the modal without exiting
  };

  return !fontsLoaded ? null : (
    <SafeAreaProvider>
      <Main />
      {exitModalVisible && (
          <ConfirmModal
            visible={exitModalVisible}
            onCancel={handleCancel}
            onConfirm={handleExitApp}
            confirmText={"Exit"}
            bodyText={"Are you sure you want to Exit the app ?"}
            BodyComponent={false}
          ></ConfirmModal>
        )}
      <StatusBar style="light"></StatusBar>
      <Toast />
    </SafeAreaProvider>
  );
}
