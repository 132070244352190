import React from "react";
import {
  Image,
  Pressable,
  View,
  Text,
  ScrollView,
  Animated,
  Platform,
  RefreshControl,
} from "react-native";
import { WebView } from "react-native-webview";
import Icon from "react-native-remix-icon";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Button, Input, Select } from "../../components/input";
import colors from "../../../colors";
import { Maps } from "../../components/maps";
import { Footer } from "../../components/footer";
import { Gallery } from "../../components/gallery";
import { useProductsContext } from "../../contexts/product-context";
import { isSignedIn } from "../../actions/auth-actions";
import { apiURIV2, mediaURI } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { ActivityIndicator } from "react-native";
import { Unauthorized } from "./unauthorized";
import { useAuthContext } from "../../contexts/auth-context";
import { catchAPIErrorText } from "../../utils/error-handler";

const initial_from = 1,
  load_records = 5;

export default function Products({ navigation }) {
  const { user } = useAuthContext();

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const [isProductsFailed, setIsProductsFailed] = React.useState();
  const [productsData, setProductsData] = React.useState();
  const [isProductsLoading, setIsProductsLoading] = React.useState();

  const [isFirstCame, setIsFirstCame] = React.useState(true);
  const {
    tabStatus,
    setTabStatus,
    products,
    setProducts,
    pagination,
    setPagination,
  } = useProductsContext();
  React.useEffect(() => {
    if (isFirstCame) {
      setIsFirstCame((isFirstCame) => !isFirstCame);
      setPagination({
        from: 1,
        to: load_records,
        total: pagination?.total,
      });
    }
  }, [isFirstCame, setPagination, pagination?.total]);

  const getVendorProducts = async (offset, limit, push, status) => {
    setIsProductsLoading(true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/products", {
        params: { offset, limit: limit || load_records, status },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data.data;
        console.log(res.data.data, "get product data ");
      }
    } catch (e) {
      console.log("products e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Products"),
      });
      setIsProductsFailed(true);
    } finally {
      const pagObj = {
        from: (offset || -1) + 1 || initial_from,
        to:
          (offset || -1) + 1
            ? offset + (data?.length || load_records)
            : data?.length || load_records,
        total: total_count,
      };
      setPagination(pagObj);
      setProducts({ data: data, push });
      setProductsData(data);
      setIsProductsLoading(false);
    }
  };

  if (!products && !isProductsLoading && !isProductsFailed) {
    getVendorProducts();
  } else if (products && !productsData) {
    setProductsData(products.slice(0, load_records));
  }

  if (!user) {
    return <Unauthorized />;
  } else if (isProductsFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">Unable to Connect the Server</Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!isProductsLoading) {
                getVendorProducts();
                setIsProductsFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!products || !productsData) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!products && !productsData}
          size="large"
          color={colors.app.e}
          style={{ marginBottom: 4 }}
        />
      </View>
    );
  }

  const onRefresh = () => {
    getVendorProducts(undefined, undefined, false, tabStatus.curr);
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isProductsLoading}
              onRefresh={onRefresh}
            />
          )
        }
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-0 mb-2 lg:my-3 lg:mb-4 p-0 lg:px-4 flex-row items-center justify-between">
            <View className="flex-row items-center">
              <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                Products
              </Font>
              <Pressable
                className="bg-app-g px-2 py-1 rounded-full flex-row justify-center items-center ml-3"
                onPress={() => navigation.navigate("add-product")}
              >
                <RemixIcon
                  name="ri-add-circle-line"
                  color={colors.app.a}
                ></RemixIcon>
                <Font className="text-app-a">Add Product</Font>
              </Pressable>
            </View>
            <View className="w-40">
              {/* <Select
                options={[
                  { label: "All", value: "all" },
                  // { label: "Incomplete", value: "Incomplete" },
                  { label: "Pending", value: "submitted" },
                  { label: "Active", value: "activated" },
                ]}
                value={tabStatus.curr || "all"}
                setValue={(val) => {
                  if (val !== tabStatus.curr) {
                    if (val == "all") {
                      getVendorProducts();
                    } else {
                      getVendorProducts(undefined, undefined, undefined, val);
                    }
                    setTabStatus({ curr: val, prev: tabStatus.curr });
                  }
                }}
              /> */}
            </View>
          </View>
          <View className="p-0 lg:px-4">
            <Card title="All Products">
              <View className="relative w-full">
                {/* {isProductsLoading && (
                  <View className="flex-1 w-full h-full top-0 bottom-0 left-0 right-0 justify-center items-center absolute z-50 bg-black/5">
                    <Font className="bg-app-e/90 text-app-a p-4 pl-5 rounded-full">Loading...</Font>
                  </View>
                )} */}
                {productsData ? (
                  productsData?.length ? (
                    productsData?.map((product, i) =>
                      product?.variants?.map((variant, i) => (
                        <Pressable
                          className="p-4 border-b border-gray-200 flex-row w-full"
                          key={i}
                          onPress={() => {
                            product?.is_active == false
                              ? null
                              : navigation.navigate("update-product", {
                                  pid: product.id,
                                  vid: variant.id,
                                });
                          }}
                        >
                          <Image
                            source={{
                              uri: mediaURI + variant?.cover_image_url,
                            }}
                            className="w-32 h-28 lg:w-40 lg:h-32 rounded-lg"
                            resizeMode="contain"
                          ></Image>
                          <View className="justify-center ml-4 flex-1 w-full">
                            <Font className="text-md text-app-d lg:text-lg font-semibold mb-1 lg:mb-2">
                              {variant?.title}
                            </Font>
                            <View className="flex-row w-full flex-wrap">
                              <Font className="text-app-d1 text-md mb-1 lg:mb-2 leading-4 mr-1">
                                Selling Price:
                              </Font>
                              <Font className="text-app-e1 text-md mb-1 lg:mb-2 leading-4 line-through mx-1">
                                {`${variant?.currency_symbol} ${
                                  [undefined, null, ""].includes(variant?.price)
                                    ? "nill"
                                    : variant?.price / 100
                                }`}
                              </Font>
                              <Font className="text-app-d1 text-md mb-1 lg:mb-2 leading-4 mx-1">
                                {`${variant?.currency_symbol} ${
                                  [undefined, null, ""].includes(
                                    variant?.sale_price
                                  )
                                    ? "nill"
                                    : variant?.sale_price / 100
                                }`}
                              </Font>
                            </View>
                            <View className="flex-row w-full">
                              <Font className="text-app-d1 text-md mb-1 lg:mb-2 mr-1">
                                In Stock:
                              </Font>
                              <Font className="text-app-d1 text-md mb-1 lg:mb-2">
                                {[undefined, null, ""].includes(variant?.stock)
                                  ? "-"
                                  : variant.stock}{" "}
                                nos.
                              </Font>
                            </View>
                            <View className="flex-row w-full flex-wrap">
                              <Font className="text-app-d1 text-md mb-1 lg:mb-2 mr-1">
                                Keywords:{" "}
                              </Font>
                              <Font className="text-app-f text-md mb-1 lg:mb-2">
                                {Platform.OS === "web"
                                  ? (variant?.keywords || "").replaceAll(
                                      ",",
                                      ", "
                                    )
                                  : (variant?.keywords || "")
                                      .split(",")
                                      .join(", ") || "No Keywords"}
                              </Font>
                            </View>
                          </View>
                          <View>
                            <Font className="text-md text-app-d lg:text-lg font-semibold mb-1 lg:mb-2">
                              {product?.is_active == false
                                ? "Disabled by Admin"
                                : ""}
                              {/* {"Disabled by Admin"} */}
                            </Font>
                          </View>
                        </Pressable>
                      ))
                    )
                  ) : (
                    <View className="w-full justify-center items-center pt-4 pb-8">
                      <Font>No Products Found</Font>
                    </View>
                  )
                ) : (
                  <View className="w-full justify-center items-center pt-4 pb-8">
                    <Font>Loading...</Font>
                  </View>
                )}
              </View>

              {load_records < pagination?.total && (
                <View className="flex-row justify-between w-full">
                  {pagination.from > initial_from && (
                    <Pressable
                      className="p-4 "
                      onPress={() => {
                        if (pagination.from > initial_from) {
                          const from =
                            pagination.from === 1
                              ? 1
                              : pagination.from - load_records;
                          const toModulus = pagination.to % load_records;
                          const to =
                            pagination.to -
                            (toModulus > 0
                              ? toModulus
                              : pagination.to > load_records
                              ? load_records
                              : 0);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          setPagination(pagObj);
                          // getProductsData(from - 1, load_records, true);
                          setProductsData(products.slice(from - 1, to));
                        }
                      }}
                    >
                      <Font className="text-app-e"> {"<"} Prev</Font>
                    </Pressable>
                  )}
                  <View className="flex-1" />
                  {pagination.to < pagination.total && (
                    <Pressable
                      className="p-4"
                      onPress={() => {
                        if (pagination.to < pagination.total) {
                          const remList = pagination.total - pagination.to;
                          const from =
                            pagination.from + (remList > 0 ? load_records : 0);
                          const to =
                            pagination.to +
                            (remList < load_records ? remList : load_records);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          const listData = products.slice(from - 1, to);
                          if (listData?.length) {
                            setPagination(pagObj);
                            setProductsData(listData);
                          } else {
                            getVendorProducts(
                              from - 1,
                              load_records,
                              true,
                              tabStatus.curr
                            );
                          }
                        }
                      }}
                    >
                      <Font className="text-app-e"> Next {">"}</Font>
                    </Pressable>
                  )}
                </View>
              )}
            </Card>
          </View>
        </View>
      </ScrollView>
      {Platform.OS === "web" && isProductsLoading && (
        <ActivityIndicator
          animating={isProductsLoading}
          size="small"
          color={colors.app.d}
          className={`mb-4 shadow-md rounded-full p-2 absolute self-center bg-white top-8 transform transition-all ease-in-out delay-100 duration-1000 opacity-0 ${
            isProductsLoading && "opacity-100"
          }`}
        />
      )}
    </View>
  );
}
