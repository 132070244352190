import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Pressable,
  RefreshControl,
} from "react-native";
import {
  getStats,
  getRecentActivites,
  clearAllPackages,
} from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Card } from "../../components/general/cards/card";

import { ScrollView } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import {
  AcceptRequest,
  GetGroupJoinRequest,
  RejectRequest,
} from "../../actions/auth-actions";
import { ActivityIndicator } from "react-native";
import ResponseModal from "../../components/general/modals/ResponseModal";

export default function GroupJoinRequests({ navigation, route }) {
  React.useEffect(() => {
    if (route?.params) {
      const { id, groupId, groupMembersLists } = route?.params;
      setGroupMemberList(groupMembersLists);
      setGroupId(groupId);
      setId(id);
      GetRequests(groupId);
    }
  }, [route?.params]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshingRequests, setRefreshingRequests] = useState(false);
  const [groupId, setGroupId] = useState();
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [id, setId] = useState();

  const [groupMembersList, setGroupMemberList] = useState();

  const GetRequests = async (groupId) => {
    try {
      setIsLoading(true);
      const res = await GetGroupJoinRequest(groupId);
      if (res.status == 200) {
        setGroupMemberList(res.data?.data || []);
        // console.log(res.data.data, "get req data");
      } else {
        console.log("get request error response:", res.status, res.data);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const RejectRequestOfUser = async (request_id) => {
    try {
      const res = await RejectRequest(groupId, request_id);
      if (res.status == 200) {
        setGroupMemberList((prevList) =>
          prevList.filter((member) => member.id !== request_id)
        );
      } else {
        console.log(
          "RejectRequestOfUser error response:",
          res.data,
          res.status
        );
        setResponseModal({
          visible: true,
          message: res.data?.message || "Something went wrong !!!",
          DismissText: "Dismiss",
          onRequestClose: () => {
            setResponseModal({
              visible: false,
              message: "",
              onRequestClose: () => null,
            });
          },
        });
      }
    } catch (error) {
      console.log("RejectRequestOfUser error:", error);
    }
  };
  const AcceptRequestOfUser = async (request_id) => {
    try {
      const res = await AcceptRequest(groupId, request_id);
      if (res.status == 200) {
        setGroupMemberList((prevList) =>
          prevList.filter((member) => member.id !== request_id)
        );
      } else {
        console.log(
          "AcceptRequestOfUser error response: ",
          res.data,
          res.status
        );
        setResponseModal({
          visible: true,
          message: res.data?.message || "Something went wrong !!!",
          DismissText: "Dismiss",
          onRequestClose: () => {
            setResponseModal({
              visible: false,
              message: "",
              onRequestClose: () => null,
            });
          },
        });
      }
    } catch (error) {
      console.log("AcceptRequestOfUser error: ", error);
    }
  };

  // console.log(groupMembersList ,groupMembersList.length,"groupMembersList");

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      refreshControl={
        Platform.OS === "web" ? null : (
          <RefreshControl
            refreshing={refreshingRequests}
            onRefresh={async () => {
              setRefreshingRequests(true);
              await GetRequests(groupId);
              setRefreshingRequests(false);
            }}
          />
        )
      }
    >
      <View className="max-w-screen-lg w-full h-full">
        <View className="max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Join requests - section----------------------------------- */}
          <>
            <View className="w-full flex-col item-center">
              {isLoading ? (
                <ActivityIndicator
                  className="self-center justify-self-center flex-1 w-full h-full"
                  color={colors.app.e}
                  size="small"
                />
              ) : !groupMembersList?.length ? (
                <View className="py-4 flex-col justify-center items-center">
                  <Font>No group join requests yet</Font>
                </View>
              ) : (
                groupMembersList?.map((e, i) => (
                  <Card className="mb-6 lg:mb-0 lg:mr-2 lg:flex-1" key={i}>
                    <TouchableOpacity
                      className="px-4 py-2 flex-row justify-between w-full"
                      // onPress={() => navigation.navigate("add-member")}
                    >
                      <View className="flex-row justify-start items-center">
                        <View className="flex justify-center items-center">
                          <RemixIcon
                            name="ri-user-fill"
                            size={16}
                            color={colors.app.g4}
                            className="mr-1 lg:mr-4 -ml-2.5 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                          />
                        </View>
                        <Font className="font-medium w-[190]">
                          {/* James (james123@gmail.com) */}
                          {e?.requester.first_name +
                            " " +
                            e?.requester.last_name}
                          <Font className="font-medium">
                            {/* James (james123@gmail.com) */}
                            {" " + e?.requester.email + " "}
                          </Font>
                        </Font>
                      </View>
                      <View className="-mr-2.5 lg:mr-0 flex-row justify-center items-center">
                        <Pressable
                          onPress={() => AcceptRequestOfUser(e?.id)}
                          className="p-1 mr-1 lg:mr-2 flex justify-center items-center bg-green-200 border border-green-600 rounded-lg"
                        >
                          <Font className="text-xs text-green-600">Accept</Font>
                        </Pressable>
                        <Pressable
                          onPress={() => RejectRequestOfUser(e?.id)}
                          className="p-1 flex justify-center items-center bg-red-200 border border-app-e rounded-lg"
                        >
                          <Font className="text-xs text-app-e">Decline</Font>
                        </Pressable>
                      </View>
                    </TouchableOpacity>
                  </Card>
                ))
              )}
            </View>
            {responseModal?.visible ? (
              <ResponseModal
                visible={responseModal.visible}
                bodyText={responseModal?.message}
                onRequestClose={responseModal?.onRequestClose}
              ></ResponseModal>
            ) : (
              <></>
            )}
          </>
        </View>
        <Footer navigation={navigation} />
      </View>
    </ScrollView>
  );
}
