import { View, Pressable, ActivityIndicator } from "react-native";
import React, { useContext } from "react";
import { Card } from "../../components/general/cards/card";
import { FlatList } from "react-native";
import { Font } from "../../components/font";
import { Image } from "react-native";
import { apiURIV2, mediaURI } from "../../constants";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import moment from "moment";
import { isSignedIn } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { RefreshControl } from "react-native";
import { Platform } from "react-native";
import { ScrollView } from "react-native";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { AuthContext } from "../../contexts/auth-context";
import { useVendorFeedContext } from "../../contexts/vendor-feed-context";
import { Button } from "../../components/input";
import { Unauthorized } from "./unauthorized";
import { DefaultImages, UserRoles } from "../../constants/predefined-constants";
import { catchAPIErrorText } from "../../utils/error-handler";

const initial_from = 1,
  load_records = 5;

const VendorReviews = ({ navigation, setTabStatus, tabStatus }) => {
  React.useEffect(() => {
    setTabStatus({ curr: "My Reviews", prev: tabStatus.curr });
  }, []);

  const { user } = useContext(AuthContext);

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const vid = +user?.id;
  const [vendorReviewsData, setVendorReviewsData] = React.useState();
  const [vRIsLoading, setVRIsLoading] = React.useState();
  const [isReviewsFailed, setIsReviewsFailed] = React.useState();

  const { reviews, setReviews, reviewsPagination, setReviewsPagination } =
    useVendorFeedContext();

  const getVendorReviews = async (offset, limit, push) => {
    setVRIsLoading(true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/users/me/reviews/", {
        params: { offset, limit: limit || load_records },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data.data;
      }
    } catch (e) {
      console.log("vendors e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Vendors"),
      });
      setIsReviewsFailed(true);
    } finally {
      const pagObj = {
        from: (offset || -1) + 1 || initial_from,
        to:
          (offset || -1) + 1
            ? offset + (data?.length || load_records)
            : data?.length || load_records,
        total: total_count,
      };
      setReviewsPagination({ data: pagObj, id: vid });
      setReviews({ data, id: vid, push });
      setVendorReviewsData(data || []);
      console.log("data:", data);
      setVRIsLoading(false);
    }
  };

  if (!vendorReviewsData && !reviews?.[vid] && !vRIsLoading) {
    getVendorReviews();
  } else if (!vendorReviewsData && reviews?.[vid]) {
    setVendorReviewsData(reviews[vid].slice(0, load_records));
  }

  if (!user) {
    return <Unauthorized />;
  } else if (isReviewsFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">
            Unable to Get Data from the Server
          </Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!vRIsLoading) {
                getVendorReviews();
                setIsReviewsFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!reviews || !vendorReviewsData) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!reviews && !vendorReviewsData}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2"
        />
      </View>
    );
  }

  return (
    <ScrollView
      className="h-full pt-2 px-4 pb-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      showsVerticalScrollIndicator={false}
      refreshControl={
        Platform.OS === "web" ? null : (
          <RefreshControl
            refreshing={vRIsLoading}
            onRefresh={() => {
              getVendorReviews();
            }}
          />
        )
      }
      keyboardShouldPersistTaps="always"
    >
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <View className=" max-w-screen-lg w-full mx-auto h-full pt-2">
        <View className="p-0 lg:px-4">
          <Card title="Reviews">
            <FlatList
              className="w-full"
              data={vendorReviewsData}
              ListEmptyComponent={() => (
                <View className="w-full justify-center items-center pb-4">
                  <Font>{vRIsLoading ? "Loading..." : "No Reviews"}</Font>
                </View>
              )}
              ItemSeparatorComponent={() => (
                <View className="w-full my-4 border-b border-gray-200" />
              )}
              renderItem={({ item: review }) => (
                <Pressable
                  className="px-4 flex-row w-full"
                  key={review.id}
                  onPress={() => {
                    navigation.navigate("vendor-review", {
                      slug: review.id,
                    });
                  }}
                >
                  <View className="justify-start pt-1 items-center w-24 lg:w-32">
                    <Image
                      source={
                        review.user_profile_image_url
                          ? {
                              uri: mediaURI + review.user_profile_image_url,
                            }
                          : DefaultImages.DP_PLACEHOLDER
                      }
                      className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                    ></Image>
                  </View>
                  <View className="justify-start ml-4 flex-1">
                    <View className="flex-row items-center flex-wrap pb-1">
                      <Font className="text-app-d text-base mr-2" weight={600}>
                        {review.user_name}
                      </Font>
                      <Font
                        className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                          review.user_role === UserRoles.CUSTOMER
                            ? "bg-app-d3 text-app-d"
                            : "bg-app-e3 text-app-e"
                        }`}
                      >
                        {review.user_role}
                      </Font>
                    </View>
                    <View className="flex-row items-center">
                      <View className="bg-app-d flex-row items-center rounded-full px-1 py-0.5 mr-1">
                        <Font className="text-app-a text-sm mx-1 leading-4">
                          {parseFloat(review.rating)}/5
                        </Font>
                        <RemixIcon
                          name="star-fill"
                          size={13}
                          color={colors.app.i}
                          className="flex-shrink-0"
                        />
                      </View>
                      <View className="ml-1 flex-row items-center">
                        <RemixIcon
                          name="ri-time-line"
                          size={16}
                          color={colors.app.d}
                          className="mr-1"
                        />
                        <Font className="text-app-d1 leading-4">
                          {moment(review.updated_at).fromNow()}
                        </Font>
                      </View>
                    </View>
                    <View className="flex-row items-center pr-4 justify-start py-3">
                      <RemixIcon
                        className="mr-2 self-start h-full"
                        name="ri-discuss-fill"
                        size={16}
                        color={colors.app.d}
                      />
                      <Font
                        numberOfLines={4}
                        className="text-app-d flex flex-row items-center leading-4"
                      >
                        {review.is_edited && (
                          <>
                            <RemixIcon
                              className="-mb-px self-start"
                              name="ri-pencil-fill"
                              size={14}
                              color={colors.app.d}
                            />
                            &nbsp;
                          </>
                        )}
                        {review.message}
                      </Font>
                    </View>
                    <View className="flex-row items-center">
                      <RemixIcon
                        name="ri-reply-fill"
                        size={16}
                        color={colors.app.d}
                        className="mr-2"
                      />
                      <Font className="text-app-d1">
                        {[0].includes(review.replies?.length)
                          ? review.replies?.length
                          : "-"}{" "}
                        {review.replies?.length == 1 ? "reply" : "replies"}
                      </Font>
                    </View>
                  </View>
                </Pressable>
              )}
            />

            {/* {load_records < reviewsPagination.total && ( */}
            <View className="flex-row justify-between w-full">
              {/* {reviewsPagination.from > initial_from && ( */}
              <Pressable
                className="p-4 "
                onPress={() => {
                  if (reviewsPagination.from > initial_from) {
                    const from =
                      reviewsPagination.from === 1
                        ? 1
                        : reviewsPagination.from - load_records;
                    const toModulus = reviewsPagination.to % load_records;
                    const to =
                      reviewsPagination.to -
                      (toModulus > 0
                        ? toModulus
                        : reviewsPagination.to > load_records
                        ? load_records
                        : 0);
                    const pagObj = {
                      from: from,
                      to: to,
                      total: reviewsPagination.total,
                    };
                    //   setReviewsPagination(pagObj);
                    setReviewsPagination({
                      data: pagObj,
                      id: vid,
                    });
                    // getVendorReviews(from - 1, load_records, true);
                    setVendorReviewsData(reviews?.[vid]?.slice(from - 1, to));
                  }
                }}
                disabled={!reviewsPagination.from > initial_from}
              >
                <Font
                  className={`${
                    reviewsPagination.from > initial_from
                      ? "text-app-e"
                      : "text-app-d2"
                  }`}
                >
                  {"<"} Prev
                </Font>
              </Pressable>
              {/* )} */}
              <View className="flex-1" />
              {/* {reviewsPagination.to < reviewsPagination.total && ( */}
              <Pressable
                className="p-4"
                onPress={() => {
                  if (reviewsPagination.to < reviewsPagination.total) {
                    const remList =
                      reviewsPagination.total - reviewsPagination.to;
                    const from =
                      reviewsPagination.from + (remList > 0 ? load_records : 0);
                    const to =
                      reviewsPagination.to +
                      (remList < load_records ? remList : load_records);
                    const pagObj = {
                      from: from,
                      to: to,
                      total: reviewsPagination.total,
                    };
                    const listData = reviews?.[vid]?.slice(from - 1, to);
                    if (listData?.length) {
                      setReviewsPagination({
                        data: pagObj,
                        id: vid,
                      });
                      setVendorReviewsData(listData);
                    } else {
                      // getVendorReviews(from - 1, load_records, true);
                    }
                  }
                }}
                disabled={!reviewsPagination.to < reviewsPagination.total}
              >
                <Font
                  className={`${
                    reviewsPagination.to < reviewsPagination.total
                      ? "text-app-e"
                      : "text-app-d2"
                  }`}
                >
                  Next {">"}
                </Font>
              </Pressable>
              {/* )} */}
            </View>
            {/* )} */}
          </Card>
        </View>
      </View>
    </ScrollView>
  );
};

export default VendorReviews;
