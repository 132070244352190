import React, { useState } from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { FaqItem } from "../../components/faq";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Button, Input } from "../../components/input";
import { getPublicData } from "../../services/api";
import useInputHandler from "../../utils/useInputHandler";
import axiosInstance from "../../constants/axios-config";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { apiURIV2 } from "../../constants";

export default function ContactUs({ navigation, route }) {
  const [isLoading, setIsLoading] = useState(false);
  const [responseModal, setResponseModal] = useState({
    visible: false,
    message: undefined,
    dismissText: undefined,
    onRequestClose: () => null,
  });
  const {
    currentValue: name,
    inputValue: updatedName,
    hasError: nameHasError,
    onValueChangeHandler: onNameChange,
    onBlurHandler: onNameBlur,
    reset: resetName,
  } = useInputHandler({ required: true });
  const {
    currentValue: email,
    inputValue: updatedEmail,
    hasError: emailHasError,
    onValueChangeHandler: onEmailChange,
    onBlurHandler: onEmailBlur,
    reset: resetEmail,
  } = useInputHandler({ required: true });
  const {
    currentValue: phone,
    inputValue: updatedPhoneNumber,
    hasError: phoneHasError,
    onValueChangeHandler: onPhoneNumberChange,
    onBlurHandler: onPhoneNumberBlur,
    reset: resetPhoneNumber,
  } = useInputHandler({ required: true });
  const {
    currentValue: message,
    inputValue: updatedMessage,
    hasError: messageHasError,
    onValueChangeHandler: onMessageChange,
    onBlurHandler: onMessageBlur,
    reset: resetMessage,
  } = useInputHandler({ required: true });

  const resetFormHandler = () => {
    resetName();
    resetEmail();
    resetPhoneNumber();
    resetMessage();
  };

  const sendContactUsDetails = async () => {
    try {
      setIsLoading(true);
      const res = await axiosInstance.post(apiURIV2 + `/enquiries/contact-us`, {
        name: updatedName,
        email: updatedEmail,
        phone: updatedPhoneNumber,
        message: updatedMessage,
      });
      if ([200, 201].includes(res.status)) {
        setResponseModal({
          visible: true,
          message:
            res.data?.message ||
            "Thanks for contacting us, we'll reach out to you shortly.",
          dismissText: "Okay",
          onRequestClose: () => {
            setResponseModal({
              visible: false,
              message: undefined,
              dismissText: undefined,
              onRequestClose: () => null,
            });
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      resetFormHandler();
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        keyboardShouldPersistTaps="always"
      >
        <View className=" max-w-screen-lg w-full mx-auto p-4">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">Contact Us</Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Contact Us">
                <View className="w-full p-4 pt-0">
                  <View className="flex-col lg:flex-row mb-4">
                    <Input
                      type="text"
                      label={"Name"}
                      placeholder="Full name"
                      className="w-full lg:w-1/2 mb-4 lg:mb-0"
                      onChangeText={onNameChange}
                      onBlur={onNameBlur}
                      value={name}
                      note={nameHasError}
                    ></Input>
                    <Input
                      type="email"
                      label={"Email"}
                      placeholder="Email address"
                      className="w-full lg:w-1/2 lg:ml-4"
                      onChangeText={onEmailChange}
                      onBlur={onEmailBlur}
                      value={email}
                      note={emailHasError}
                    ></Input>
                  </View>
                  <View className="flex-row mb-4">
                    <Input
                      type="number"
                      label={"Contact Number"}
                      placeholder="Contact Number"
                      onChangeText={onPhoneNumberChange}
                      onBlur={onPhoneNumberBlur}
                      value={phone}
                      note={phoneHasError}
                    ></Input>
                  </View>
                  <View className="flex-row mb-4 w-full">
                    <Input
                      type="longtext"
                      label={"Message"}
                      placeholder="Message"
                      className="w-full"
                      onChangeText={onMessageChange}
                      onBlur={onMessageBlur}
                      value={message}
                      note={messageHasError}
                    ></Input>
                  </View>
                  <Button
                    type="primary"
                    label="Send"
                    className="lg:w-60 lg:self-end"
                    onPress={sendContactUsDetails}
                    waitState={isLoading}
                  ></Button>
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
      {responseModal.visible ? (
        <ResponseModal
          visible={responseModal.visible}
          bodyText={responseModal.message}
          dismissText={responseModal?.dismissText}
          onRequestClose={responseModal?.onRequestClose}
          animationType="fade"
        />
      ) : (
        <></>
      )}
    </View>
  );
}
