import React from "react";
import { Feather } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import {
  Pressable,
  View,
  ScrollView,
  RefreshControl,
  Platform,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { Button, Select } from "../../components/input";
import colors from "../../../colors";
import { apiURIV2, mediaURI } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { isSignedIn } from "../../actions/auth-actions";
import { ActivityIndicator } from "react-native";
import { Unauthorized } from "./unauthorized";
import { useAuthContext } from "../../contexts/auth-context";
import { getFullDateTime } from "../../utils/date-time-util";
import { catchAPIErrorText } from "../../utils/error-handler";
import ConfirmModal from "../../components/general/modals/ConfirmModal";

const initial_from = 1,
  load_records = 20;

export default function CouponsList({ navigation }) {
  const { user } = useAuthContext();
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [confirmModal, setConfirmModal] = React.useState({
    show: false,
    cancelText: undefined,
    onCancel: () => null,
    confirmText: undefined,
    onConfirm: () => null,
    bodyText: "",
    BodyComponent: undefined,
  });
  const [couponsData, setCouponsData] = React.useState();
  const [tabStatus, setTabStatus] = React.useState({
    curr: undefined,
    prev: undefined,
  });
  // const [listings, setListings] = React.useState();
  const [pagination, setPagination] = React.useState({
    from: 0,
    to: 0,
    total: 0,
  });

  const [isCouponsFailed, setIsCouponsFailed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [isFirstCame, setIsFirstCame] = React.useState(true);

  React.useEffect(() => {
    if (isFirstCame) {
      setIsFirstCame((isFirstCame) => !isFirstCame);
      setPagination({
        from: 1,
        to: load_records,
        total: pagination?.total,
      });
    }
  }, [isFirstCame, setPagination, pagination?.total]);

  React.useEffect(() => {
    getCouponsData(0, load_records);
  }, []);

  const getCouponsData = async (offset, limit, status, push) => {
    setIsLoading((isLoading) => !isLoading && true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/coupons", {
        params: { offset, limit: limit || load_records, status },
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        data = res.data?.data?.coupons;
        total_count = res.data?.data?.total_count || data?.length;
      }
    } catch (e) {
      console.log("e.response", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Listings"),
      });
      setIsCouponsFailed(true);
    } finally {
      const pagObj = {
        from: (offset || -1) + 1 || initial_from,
        to:
          (offset || -1) + 1
            ? offset + (data?.length || load_records)
            : data?.length || load_records,
        total: total_count,
      };
      setPagination(pagObj);

      setCouponsData(data);

      setIsLoading((isLoading) => isLoading && false);
    }
  };

  const deleteCoupons = async (id) => {
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.delete(apiURIV2 + "/coupons/" + id.cid, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 204) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Successfully deleted Coupon",
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Deleting Coupon"),
      });
    } finally {
      getCouponsData();
    }
  };

  if (!user) {
    return <Unauthorized />;
  } else if (isCouponsFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">Unable to Connect the Server</Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!isLoading) {
                getCouponsData();
                setIsCouponsFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!couponsData) {
    return (
      <View className="flex-1 justify-center items-center relative">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2 absolute"
        />
      </View>
    );
  }

  return (
    <View className="flex-1 bg-app-e flex flex-col lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <View className="rounded-t-2xl flex-1 bg-gray-50 h-full">
        <View className=" max-w-screen-lg w-full mx-auto h-full flex-1">
          <View className="my-0 mb-2 lg:my-3 lg:mb-4 p-4 flex-row items-center justify-between">
            <View className="flex-row items-center">
              <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                Coupons
              </Font>
              <Pressable
                className="bg-app-g px-1.5 py-1 rounded-full flex-row justify-center items-center self-center ml-3"
                onPress={() => navigation.navigate("add-coupons")}
              >
                <RemixIcon
                  name="ri-add-circle-line"
                  color={colors.app.a}
                ></RemixIcon>
                <Font className="text-app-a px-1 pb-[2px]">Add Coupon</Font>
              </Pressable>
            </View>
            <View className="w-40">
              <Select
                options={[
                  { label: "Expired", value: "expired" },
                  { label: "Flat Discount", value: "flat_discount" },
                  { label: "Percent Discount", value: "percent_discount" },
                ]}
                placeholder={"All"}
                placeholderEnabled={true}
                value={tabStatus.curr}
                setValue={(e) => {
                  if (e !== tabStatus.curr) {
                    getCouponsData(undefined, undefined, e);
                    setTabStatus({ curr: e, prev: tabStatus.curr });
                  }
                }}
              ></Select>
            </View>
          </View>

          <ScrollView
            className="w-full lg:px-5 h-full flex-1"
            contentContainerStyle={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
            }}
            refreshControl={
              Platform.OS === "web" ? null : (
                <RefreshControl
                  refreshing={isLoading}
                  onRefresh={() => {
                    getCouponsData(undefined, undefined, tabStatus.curr);
                  }}
                />
              )
            }
          >
            <View className="flex-row w-full p-0 lg:pr-4 flex-wrap px-4">
              {couponsData ? (
                couponsData?.length ? (
                  couponsData?.map((coupon) => (
                    <View
                      className="w-full lg:w-1/3 py-1 lg:p-2"
                      key={coupon.id}
                    >
                      <View className="w-full rounded-lg overflow-hidden border border-gray-200 bg-app-a shadow-black shadow-lg">
                        <View className="p-4">
                          <View className="flex-1 shrink-0 items-center justify-center border-dashed border-2 border-app-d2 mb-3">
                            <Font
                              className="text-[20px] text-app-d flex-auto mb-1 tracking-widest truncate"
                              weight={700}
                              numberOfLines={4}
                              style={
                                {
                                  // letterSpacing: "0.2em",
                                  // overflow: "hidden",
                                  // whiteSpace: "nowrap",
                                  // textOverflow: "ellipsis",
                                }
                              }
                            >
                              {coupon.code}
                            </Font>
                          </View>
                          <View className="flex-row items-center">
                            <Font
                              className="text-[15px] text-app-d"
                              weight={600}
                              numberOfLines={1}
                            >
                              Minimum order amount:{" "}
                            </Font>
                            <Font
                              className="text-md ml-2 text-app-d font-semibold"
                              weight={700}
                              numberOfLines={1}
                            >
                              {coupon?.minimum_order_amount_required
                                ? `$ ${coupon.minimum_order_amount_required}`
                                : ""}
                            </Font>
                          </View>
                          {coupon.type === "flat_discount" ? (
                            <View
                              className="flex-row items-center"
                              style={{
                                marginVertical: 2,
                              }}
                            >
                              <Font
                                className="text-[15px] text-app-d"
                                numberOfLines={1}
                                weight={600}
                              >
                                Flat discount:{" "}
                              </Font>
                              <Font
                                className="text-md text-app-g ml-2 font-semibold"
                                weight={700}
                                numberOfLines={1}
                              >
                                {coupon.flat_discount
                                  ? `$ ${coupon.flat_discount}`
                                  : coupon.discount_percentage_multiplier
                                  ? `${
                                      coupon.discount_percentage_multiplier *
                                      100
                                    } %`
                                  : ""}
                              </Font>
                            </View>
                          ) : (
                            <></>
                          )}
                          {coupon.type === "percent_discount" ? (
                            <View
                              className="flex-row items-center"
                              style={{
                                marginVertical: 2,
                              }}
                            >
                              <Font
                                className="text-[15px] text-app-d"
                                weight={600}
                                numberOfLines={1}
                              >
                                Discount percentage:{" "}
                              </Font>
                              <Font
                                className="text-md text-app-g ml-2 font-semibold"
                                weight={700}
                                numberOfLines={1}
                              >
                                {coupon.discount_percentage_multiplier * 100
                                  ? `${
                                      coupon.discount_percentage_multiplier *
                                      100
                                    } %`
                                  : ""}
                              </Font>
                            </View>
                          ) : (
                            <></>
                          )}
                          {coupon.type === "percent_discount" ? (
                            <View
                              className="flex-row items-center"
                              style={{
                                marginVertical: 2,
                              }}
                            >
                              <Font
                                className="text-[15px] text-app-d"
                                weight={600}
                                numberOfLines={1}
                              >
                                Max discount:{" "}
                              </Font>
                              <Font
                                className="text-md text-app-e ml-2 font-semibold"
                                weight={700}
                                numberOfLines={1}
                              >
                                {coupon.max_discount
                                  ? `$ ${coupon.max_discount}`
                                  : ""}
                              </Font>
                            </View>
                          ) : (
                            <></>
                          )}
                          <View className="flex-row flex-wrap items-center mb-2">
                            <Font
                              className="text-[15px] text-app-d w-full"
                              numberOfLines={1}
                              weight={600}
                            >
                              Expires on:{" "}
                            </Font>
                            <Font
                              className="text-md text-app-d ml-2 font-semibold"
                              weight={700}
                              numberOfLines={1}
                            >
                              {coupon.expires_at
                                ? `${getFullDateTime(+coupon.expires_at)}`
                                : ""}
                            </Font>
                          </View>
                          {coupon.type === "flat_discount" ? (
                            <View
                              className="flex-row items-center opacity-0 invisible h-5"
                              style={{
                                marginVertical: 2,
                              }}
                            ></View>
                          ) : (
                            <></>
                          )}
                          <View className="flex-row justify-end items-center">
                            <Pressable
                              className={`active:rounded-full active:bg-app-d3 py-2 ${
                                Platform.OS === "web"
                                  ? "px-[9.5px]"
                                  : "px-[8.5px]"
                              }`}
                              onPress={() =>
                                navigation.navigate("update-coupons", {
                                  cid: coupon.id,
                                })
                              }
                            >
                              <Feather
                                name="edit"
                                size={21}
                                color={colors.app.g}
                              />
                            </Pressable>
                            <Pressable
                              className="active:bg-app-e3 active:rounded-full p-2"
                              onPress={() =>
                                setConfirmModal((prev) => ({
                                  ...prev,
                                  show: true,
                                  confirmText: undefined,
                                  onConfirm: () => {
                                    deleteCoupons({ cid: coupon.id });
                                  },
                                  BodyComponent: () => (
                                    <View>
                                      <Font>
                                        Do you want to delete this
                                        <Font
                                          className="text-app-e"
                                          weight={600}
                                        >
                                          {" "}
                                          {coupon.code}{" "}
                                        </Font>
                                        coupon?
                                      </Font>
                                    </View>
                                  ),
                                }))
                              }
                            >
                              <MaterialIcons
                                name="delete"
                                size={24}
                                color={colors.app.e1}
                              />
                            </Pressable>
                          </View>
                        </View>
                      </View>
                    </View>
                  ))
                ) : (
                  <View className="w-full justify-center items-center pt-4 pb-8">
                    <Font>No Coupons Found</Font>
                  </View>
                )
              ) : (
                <View className="w-full justify-center items-center pt-4 pb-8">
                  <Font>Loading...</Font>
                </View>
              )}
              {load_records < pagination?.total ? (
                <View className="flex-row justify-between w-full">
                  {pagination.from > initial_from ? (
                    <Pressable
                      className="p-4 "
                      onPress={() => {
                        if (pagination.from > initial_from) {
                          const from =
                            pagination.from === 1
                              ? 1
                              : pagination.from - load_records;
                          const toModulus = pagination.to % load_records;
                          const to =
                            pagination.to -
                            (toModulus > 0
                              ? toModulus
                              : pagination.to > load_records
                              ? load_records
                              : 0);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          getCouponsData(
                            from - 1,
                            load_records,
                            tabStatus.curr,
                            true
                          );
                        }
                      }}
                    >
                      <Font className="text-app-e"> {"<"} Prev</Font>
                    </Pressable>
                  ) : (
                    <></>
                  )}
                  <View className="flex-1" />
                  {pagination.to < pagination.total ? (
                    <Pressable
                      className="p-4"
                      onPress={() => {
                        if (pagination.to < pagination.total) {
                          const remList = pagination.total - pagination.to;
                          const from =
                            pagination.from + (remList > 0 ? load_records : 0);
                          const to =
                            pagination.to +
                            (remList < load_records ? remList : load_records);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          getCouponsData(
                            from - 1,
                            load_records,
                            tabStatus.curr,
                            true
                          );
                        }
                      }}
                    >
                      <Font className="text-app-e"> Next {">"}</Font>
                    </Pressable>
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
          </ScrollView>
        </View>
      </View>
      {Platform.OS === "web" && !!isLoading && (
        <ActivityIndicator
          animating={isLoading}
          size="small"
          color={colors.app.e}
          className={`mb-4 shadow-md rounded-full p-2 absolute self-center bg-white top-8 transform transition-all ease-in-out delay-100 duration-1000 opacity-0 ${
            isLoading && "opacity-100"
          }`}
        />
      )}
      {confirmModal.show ? (
        <ConfirmModal
          BodyComponent={confirmModal?.BodyComponent}
          bodyText={confirmModal.bodyText}
          visible={confirmModal.show}
          onConfirm={() => {
            setConfirmModal({
              show: false,
              cancelText: undefined,
              onCancel: () => null,
              confirmText: undefined,
              onConfirm: () => null,
              bodyText: "",
              BodyComponent: undefined,
            });
            confirmModal?.onConfirm ? confirmModal?.onConfirm() : null;
          }}
          confirmText={confirmModal?.confirmText}
          cancelText={confirmModal?.cancelText}
          onCancel={() => {
            setConfirmModal({
              show: false,
              cancelText: undefined,
              onCancel: () => null,
              confirmText: undefined,
              onConfirm: () => null,
              bodyText: "",
              BodyComponent: undefined,
            });
            confirmModal?.onCancel ? confirmModal?.onCancel() : null;
          }}
        />
      ) : (
        <></>
      )}
    </View>
  );
}
