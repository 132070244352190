import React, { useCallback, useState } from "react";
import { Platform, Pressable } from "react-native";
import { Font } from "../../font";
import {
  getFullDate,
  getFullDateTime,
  getTime,
} from "../../../utils/date-time-util";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../../colors";
import moment from "moment";
const DateTime = Platform.select({
  web: ({ onChange, onBlur, mode, value }) => (
    <input
      type={mode === "datetime" ? "datetime-local" : mode}
      style={{ outline: "none", width: "100%", color: colors.app.d }}
      className="text-sm tracking-widest"
      defaultValue={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  ),
  default: require("@react-native-community/datetimepicker").default,
});

const DateTimePicker = ({
  onChange = () => null,
  onBlur = () => null,
  type: mode = "datetime",
  value,
}) => {
  const [componentState, setComponentState] = useState({
    visible: false,
    current_mode: mode === "datetime" ? "date" : mode,
    dateString: "",
    timeString: "",
    timestamp: "",
  });
  const onDateTimeChange = useCallback(
    (platform, event, isoDateInstance) => {
      if (platform === "web") {
        return onChange(
          event?.target?.valueAsNumber,
          mode === "datetime"
            ? new Date(event?.target?.valueAsNumber)
            : event?.target?.valueAsDate
        );
      } else {
        if (event.type === "dismissed") {
          if (
            componentState.current_mode === "time" ||
            componentState.visible
          ) {
            setComponentState((prev) => ({
              ...prev,
              visible: false,
              current_mode: "date",
              dateString: "",
              timeString: "",
            }));
          }
          return onBlur(
            isoDateInstance
              ? new Date(
                  `${
                    componentState.dateString
                  } ${isoDateInstance.toTimeString()}`
                ).getTime()
              : undefined,
            isoDateInstance
          );
        } else {
          if (mode === "datetime") {
            setComponentState((prev) => {
              const isTimeMode = prev.current_mode === "time";
              return {
                ...prev,
                visible: isTimeMode ? false : true,
                current_mode: isTimeMode ? "date" : "time",
                dateString: isoDateInstance.toDateString(),
                timestamp: isoDateInstance.getTime(),
              };
            });
            if (componentState.dateString) {
              return onChange(
                new Date(
                  `${
                    componentState.dateString
                  } ${isoDateInstance.toTimeString()}`
                ).getTime(),
                isoDateInstance
              );
            }
          } else if (mode) {
            onChange(isoDateInstance.getTime(), isoDateInstance);
            setComponentState((prev) => ({
              ...prev,
              visible: false,
              current_mode: mode,
              timestamp: isoDateInstance.getTime(),
            }));
          }
        }
      }
    },
    [onChange, componentState, mode]
  );
  const onDateTimeBlur = useCallback(
    (event) => {
      return onBlur(
        event?.target?.valueAsNumber,
        mode === "datetime"
          ? new Date(event?.target?.valueAsNumber)
          : event?.target?.valueAsDate
      );
    },
    [onBlur, mode]
  );
  return (
    <>
      {Platform.OS === "web" ? (
        <DateTime
          value={value ? moment(+value).format("YYYY-MM-DDThh:mm") : undefined}
          mode={mode}
          onChange={onDateTimeChange.bind(this, "web")}
          onBlur={onDateTimeBlur}
        />
      ) : (
        <>
          <Pressable
            className="flex-row justify-between flex-1"
            onPress={() => {
              setComponentState((v) => ({ ...v, visible: true }));
            }}
          >
            <Font
              className={`text-sm tracking-widest ${
                componentState.timestamp || value ? "text-app-d" : "text-app-d2"
              }`}
            >
              {mode === "date"
                ? getFullDate(
                    componentState.timestamp
                      ? componentState.timestamp
                      : value
                      ? new Date(+value)
                      : ""
                  ) || "dd-mm-yyyy"
                : mode === "time"
                ? getTime(
                    componentState.timestamp
                      ? componentState.timestamp
                      : value
                      ? new Date(+value)
                      : ""
                  ) || "hh:mm --"
                : getFullDateTime(
                    componentState.timestamp
                      ? componentState.timestamp
                      : value
                      ? new Date(+value)
                      : "",
                    "MMMM D, YYYY h:mm A"
                  ) || "dd-mm-yyyy --:-- --"}
            </Font>
            <RemixIcon
              name={mode === "time" ? "ri-time-fill" : "ri-calendar-fill"}
              color={colors.app.d2}
              size={20}
            />
          </Pressable>
          {componentState.visible ? (
            <DateTime
              value={
                componentState.timestamp
                  ? new Date(+componentState.timestamp)
                  : value
                  ? new Date(+value)
                  : new Date()
              }
              mode={componentState.current_mode}
              is24Hour={false}
              onChange={onDateTimeChange.bind(this, "native")}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default DateTimePicker;
