import React, { createContext, useState } from "react";
const BlogsContext = createContext({
  blogs: [],
  setBlogs: () => null,
});

export const BlogsContextProvider = (props) => {
  const [blogs, setBlogs] = useState([]);
  return (
    <BlogsContext.Provider
      value={{
        blogs: blogs,
        setBlogs: setBlogs,
      }}
    >
      {props.children}
    </BlogsContext.Provider>
  );
};
export default BlogsContext;
