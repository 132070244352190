import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { Font } from "../../components/font";
import RemixIcon from "react-native-remix-icon";
import { Card } from "../../components/general/cards/card";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import useInputHandler from "../../utils/useInputHandler";
import { createCrossPlatformFormData } from "../../utils/encDec";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { isSignedIn } from "../../actions/auth-actions";
import { Button, Input, Select } from "../../components/input";
import { catchAPIErrorText } from "../../utils/error-handler";

export default function UpdateCoupons({ route, navigation }) {
  const [couponData, setCouponData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const cid = route.params?.cid;

  useEffect(() => {
    const getCouponByID = async () => {
      //  setpCIsLoading(true);
      let data;
      try {
        const token = await isSignedIn();
        const res = await axiosInstance.get(apiURIV2 + "/coupons/" + cid, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.status === 200) {
          data = res.data.data;
          setCouponData(data);
        }
      } catch (e) {
        console.log("e.res", e?.response, e);
      }
    };
    getCouponByID();
  }, [route.params]);

  const {
    inputValue: codeInputValue,
    currentValue: codeValue,
    onValueChangeHandler: onChangeCodeText,
    onBlurHandler: onBlurCode,
    hasError: codeHasError,
    reset: resetCode,
  } = useInputHandler({ defaultValue: couponData?.code });

  const {
    inputValue: minimumOrderAmountRequiredInputValue,
    currentValue: minimumOrderAmountRequiredValue,
    onValueChangeHandler: onChangeMinimumOrderAmountRequiredText,
    onBlurHandler: onBlurMinimumOrderAmountRequired,
    hasError: minimumOrderAmountRequiredHasError,
    reset: resetMinimumOrderAmountRequired,
  } = useInputHandler({
    defaultValue: couponData?.minimum_order_amount_required,
  });

  const {
    inputValue: typeInputValue,
    currentValue: typeValue,
    onValueChangeHandler: onChangeType,
    onBlurHandler: onBlurType,
    hasError: typeHasError,
    reset: resetType,
  } = useInputHandler({ defaultValue: couponData?.type });

  const {
    inputValue: flatDiscountInputValue,
    currentValue: flatDiscountValue,
    onValueChangeHandler: onChangeFlatDiscountText,
    onBlurHandler: onBlurFlatDiscount,
    hasError: flatDiscountHasError,
    reset: resetFlatDiscount,
  } = useInputHandler({ defaultValue: couponData?.flat_discount });

  const {
    inputValue: discountPercentageMultiplierInputValue,
    currentValue: discountPercentageMultiplierValue,
    onValueChangeHandler: onChangeDiscountPercentageMultiplierText,
    onBlurHandler: onBlurDiscountPercentageMultiplier,
    hasError: discountPercentageMultiplierHasError,
    reset: resetDiscountPercentageMultiplier,
  } = useInputHandler({
    defaultValue: +couponData?.discount_percentage_multiplier * 100,
  });

  const {
    inputValue: maxDiscountInputValue,
    currentValue: maxDiscountValue,
    onValueChangeHandler: onChangeMaxDiscountText,
    onBlurHandler: onBlurMaxDiscount,
    hasError: maxDiscountHasError,
    reset: resetMaxDiscount,
  } = useInputHandler({ defaultValue: couponData?.max_discount });

  const {
    inputValue: expiresAtInputValue,
    currentValue: expiresAtValue,
    onValueChangeHandler: onChangeExpiresAtText,
    onBlurHandler: onBlurExpiresAt,
    hasError: expiresAtHasError,
    reset: resetExpiresAt,
  } = useInputHandler({ defaultValue: couponData?.expires_at });

  useEffect(() => {
    if (
      !isButtonDisabled &&
      !codeInputValue &&
      !minimumOrderAmountRequiredInputValue &&
      !typeInputValue &&
      !discountPercentageMultiplierInputValue &&
      !maxDiscountInputValue &&
      !flatDiscountInputValue &&
      !expiresAtInputValue
    )
      setIsButtonDisabled(true);
    else if (isButtonDisabled) setIsButtonDisabled(false);
  }, [
    codeInputValue,
    minimumOrderAmountRequiredInputValue,
    typeInputValue,
    discountPercentageMultiplierInputValue,
    maxDiscountInputValue,
    flatDiscountInputValue,
    expiresAtInputValue,
  ]);

  const handleSubmitUpdate = () => {
    let updateObj = {};
    if (codeInputValue) updateObj.code = codeInputValue;
    if (minimumOrderAmountRequiredInputValue)
      updateObj.minimum_order_amount_required =
        minimumOrderAmountRequiredInputValue;
    if (typeInputValue) updateObj.type = typeInputValue;
    if (typeValue === "percent_discount") {
      if (discountPercentageMultiplierInputValue)
        updateObj.discount_percentage_multiplier =
          +discountPercentageMultiplierInputValue / 100;
      if (maxDiscountInputValue) updateObj.max_discount = maxDiscountInputValue;
      updateObj.flat_discount = null;
    }
    if (typeValue === "flat_discount") {
      if (flatDiscountInputValue)
        updateObj.flat_discount = flatDiscountInputValue;
      updateObj.discount_percentage_multiplier = null;
      updateObj.max_discount = null;
    }
    if (expiresAtInputValue) updateObj.expires_at = expiresAtInputValue;

    const updateCoupons = async () => {
      try {
        setIsSubmitting(true);
        const token = await isSignedIn();
        const res = await axiosInstance.patch(
          apiURIV2 + "/coupons/" + cid,
          updateObj,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.status === 201) {
          setCouponData(res.data.data);
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
              navigation.replace("coupons");
            },
            bodyText: `Coupon has been Successfully Updated!`,
            dismissText: "Okay",
          });
        }
      } catch (e) {
        console.log("Value of 'e':", e);
        console.log(
          "e.res",
          e?.response,
          "\n\n",
          e?.response?.status,
          "\n\n",
          e?.response?.data,
          "\n\n"
          // e.toJSON()
        );

        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(e, "Unknown Error while Updating Coupon"),
        });
      } finally {
        setIsSubmitting(false);
      }
    };
    updateCoupons();
    console.log("Coupon updated successfully");
  };

  return (
    <>
      <ScrollView
        className="rounded-t-2xl"
        overScrollMode="never"
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 16,
          paddingBottom: 16,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        keyboardShouldPersistTaps="always"
      >
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
            dismissText={showResponseModal?.dismissText}
          />
        )}
        <Card title="Add Coupon" className="mt-4">
          <View className="flex-row flex-wrap px-2 w-full">
            <View className="px-2 w-full lg:w-1/3 pb-4">
              <Input
                type="text"
                label="Code"
                value={codeValue}
                onChangeText={(e) => {
                  e = e.trim().toUpperCase();
                  onChangeCodeText(e);
                }}
                onBlur={onBlurCode}
                note={codeHasError}
              />
            </View>
            <View className="px-2 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Minimum Order Amount For Discount"
                placeholder="Minimum Order Amount Required"
                value={minimumOrderAmountRequiredValue}
                onChangeText={onChangeMinimumOrderAmountRequiredText}
                onBlur={onBlurMinimumOrderAmountRequired}
                note={minimumOrderAmountRequiredHasError}
                leftText={"$"}
                keyboardType="numeric"
              />
            </View>
            <View className="px-2 w-full lg:w-1/3 pb-4">
              <Select
                label="Type"
                options={[
                  { label: "Flat Discount", value: "flat_discount" },
                  { label: "Percent Discount", value: "percent_discount" },
                ]}
                placeholder="Select type"
                value={typeValue}
                setValue={onChangeType}
                note={typeHasError}
              />
            </View>
            {typeValue === "flat_discount" && (
              <View className="px-2 w-full lg:w-1/3 pb-4">
                <Input
                  type="number"
                  label="Flat Discount"
                  value={flatDiscountValue}
                  onChangeText={onChangeFlatDiscountText}
                  onBlur={onBlurFlatDiscount}
                  leftText={"$"}
                  note={flatDiscountHasError}
                />
              </View>
            )}
            {typeValue === "percent_discount" && (
              <View className="px-2 w-full lg:w-1/3 pb-4">
                <Input
                  type="number"
                  label="Discount Percentage"
                  value={discountPercentageMultiplierValue}
                  onChangeText={onChangeDiscountPercentageMultiplierText}
                  onBlur={onBlurDiscountPercentageMultiplier}
                  keyboardType="numeric"
                  note={discountPercentageMultiplierHasError}
                  rightText={"%"}
                  infoButtonText="Decimal input value for Discount Percentage. For example - 10%"
                />
              </View>
            )}
            {typeValue === "percent_discount" && (
              <View className="px-2 w-full lg:w-1/3 pb-4">
                <Input
                  type="number"
                  label="Max Discount"
                  value={maxDiscountValue}
                  onChangeText={onChangeMaxDiscountText}
                  onBlur={onBlurMaxDiscount}
                  keyboardType="numeric"
                  note={maxDiscountHasError}
                  leftText={"$"}
                  infoButtonText="Max Discount Amount is only applicable when discount type is Percentage Discount. For example - $10"
                />
              </View>
            )}
            <View className="px-2 w-full lg:w-1/3 pb-4">
              <Input
                label="Expires on Date and Time"
                type="datetime"
                value={expiresAtValue}
                setValue={onChangeExpiresAtText}
                onBlur={onBlurExpiresAt}
                note={expiresAtHasError}
              />
            </View>
          </View>
        </Card>
      </ScrollView>
      <View className="w-full flex-row justify-center lg:justify-end">
        <View className="w-full lg:w-60 p-4">
          <Button
            label={"Update Coupon"}
            type="primary"
            onPress={handleSubmitUpdate}
            disabled={isButtonDisabled || isSubmitting}
          ></Button>
        </View>
      </View>
    </>
  );
}
