import React, { useContext, useEffect, useState } from "react";
import {
  Pressable,
  View,
  Text,
  ScrollView,
  Platform,
  FlatList,
  TextInput,
} from "react-native";
import { WebView } from "react-native-webview";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Button, Input, Select, SelectLocation } from "../../components/input";
import colors from "../../../colors";
import { Maps } from "../../components/maps";
import { Gallery } from "../../components/gallery";
import { Galleryupload } from "../../components/galleryupload";
import PlanCard from "../../components/general/cards/plan-card";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2, mediaURI } from "../../constants";
import { useListingContext } from "../../contexts/listing-context";
import MediaUpload from "../../components/general/uploads/media-upload";
import { useNavigation } from "@react-navigation/native";
import { isSignedIn } from "../../actions/auth-actions";
import useInputHandler from "../../utils/useInputHandler";
import { Modal } from "react-native";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import VideoPlayer from "../../components/general/Players/VideoPlayer";
import _, { cloneDeep } from "lodash";
import { Calendar } from "../../components/calendar";
import { useSharedContext } from "../../contexts/shared-context";
import Calendar1 from "../../components/general/widgets/calendar1";
import AvailabilitySlotCard from "../../components/general/cards/availability-slot-card";
import {
  parseSlot,
  parseSlotsArray,
  parseSlotsObject,
} from "../../utils/availability-slots-util";
import { TouchableWithoutFeedback } from "react-native";
import { RefreshControl } from "react-native";
import { monthsWithDateIndex } from "../../constants/calendar-constant";
import axios from "axios";
import { Image } from "react-native";
import { getFullDateTime } from "../../utils/date-time-util";
import moment from "moment";
import { AuthContext } from "../../contexts/auth-context";
import { DefaultImages, UserRoles } from "../../constants/predefined-constants";
import { createCrossPlatformFormData } from "../../utils/encDec";
import { ActivityIndicator } from "react-native";
import { catchAPIErrorText } from "../../utils/error-handler";

const currencyList = [{ label: "USD ($)", value: "USD", symbol: "$" }];

const initial_from = 1,
  load_records = 5;

// const UpgradePackages = ({
//   onPackageSelect,
//   currentPackageID,
//   listingPackages,
//   isEditingForm,
//   setIsEditingForm,
// }) => {
//   const { screen } = useSharedContext();

//   return (
//     <>
//       <Card title="Upgrade Package">
//         <View className="w-full flex-row flex-wrap px-4 pb-4">
//           <FlatList
//             data={listingPackages}
//             key={screen?.width}
//             numColumns={screen?.width >= 768 ? 2 : 1}
//             renderItem={({
//               item: { id, title, subTitle, is_featured, features, prices },
//             }) => (
//               <View className="w-full md:w-1/2 mb-4 md:m-0 md:p-4">
//                 <PlanCard
//                   planTitle={title}
//                   planPrice={
//                     prices[0].currency_symbol +
//                     " " +
//                     prices[0].discounted_price / 100
//                   }
//                   planSubtitle={subTitle}
//                   planFeatures={features}
//                   onPress={onPackageSelect}
//                   isCurrentPlan={id === currentPackageID}
//                   isFeatured={is_featured}
//                 />
//               </View>
//             )}
//           />
//         </View>
//       </Card>
//     </>
//   );
// };

const ListingDetails = ({
  listingGeneralData: {
    contact_email,
    contact_phone,
    description,
    enable_contact_widget,
    facebook_link,
    // facebook_page_url,
    gallery_images,
    google_maps_url,
    instagram_link,
    cover_image_url,
    keywords,
    average_rating,
    address_line_1,
    address_line_2,
    landmark,
    locality,
    city,
    state,
    country,
    postal_code,
    lat,
    listing_category,
    listing_features,
    lng,
    max_price,
    min_price,
    skype_link,
    title,
    twitter_link,
    video_link,
    website_link,
    whatsapp_link,
    youtube_link,
  },
  // currentPackageDetails,
  currencySymbol,
  onUpgradePlanPress,
  updateListingDetails,
  saveClicked,
  setSaveClicked,
  resetClicked,
  setResetClicked,
  isEditingForm,
  setIsEditingForm,
  setShowResponseModal,
  lid,
  coupons,
  setCoupons,
}) => {
  const [showContactWidget, setShowContactWidget] = React.useState();
  const [selectedFeatures, setSelectedFeatures] = useState({
    listingFeatures: undefined,
    currSelectedIDs: [],
    currDeselectedIDs: [],
  });
  const [lCIsLoading, setLCIsLoading] = useState(false);
  const [allotUnallotIsLoading, setAllotUnallotIsLoading] = useState();
  const [lFIsLoading, setLFIsLoading] = useState(false);
  const [pickedImage, setPickedImage] = useState();
  const [mapAddress, setMapAddress] = useState("");
  const [latLng, setLatLng] = useState();
  const [galleryImages, setGalleryImages] = useState({
    addedImages: [],
    removedImages: [],
  });
  const [couponsList, setCouponsList] = useState();
  const [isCouponsLoading, setIsCouponsLoading] = useState();
  const {
    listingCategories,
    setListingCategories,
    listingFeatures,
    setListingFeatures,
    deleteListingDetailsData,
  } = useListingContext();

  if (!listingCategories && !lCIsLoading) {
    const getListingCategories = async () => {
      setLCIsLoading(true);
      let data;
      const token = await isSignedIn();
      try {
        const res = await axiosInstance.get(apiURIV2 + "/listing-categories", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.status === 200) {
          data = res.data.data;
        }
      } catch (e) {
        console.log("lc e.res", e?.response, e);
      } finally {
        setListingCategories({
          data: data?.map((v) => ({ label: v.title, value: v.id })),
        });
        setLCIsLoading(false);
      }
    };
    getListingCategories();
  }
  const getListingFeatures = React.useCallback(
    async (listing_category_id) => {
      setLFIsLoading(true);
      let data;
      try {
        const token = await isSignedIn();
        const res = await axiosInstance.get(apiURIV2 + "/listing-features", {
          params: {
            listing_category_id: listing_category_id
              ? listing_category_id
              : listing_category?.id || undefined,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.status === 200) {
          data = res.data.data;
        }
      } catch (e) {
        console.log("lf e.res", e?.response, e);
      } finally {
        setListingFeatures({
          data: data?.map((v) => ({ label: v.title, value: v.id })),
        });
        setLFIsLoading(false);
      }
    },
    [listing_category?.id, setListingFeatures]
  );

  if (!listingFeatures && !lFIsLoading) {
    getListingFeatures();
  }
  const getAddrDetails = React.useCallback(
    async (latitude, longitude) =>
      await axiosInstance.get(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
      ),
    []
  );

  React.useEffect(() => {
    if (!mapAddress) {
      (async () => {
        try {
          let addr = await getAddrDetails(lat, lng);
          setMapAddress(() => addr.data?.display_name);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, []);

  if (![true, false].includes(showContactWidget)) {
    setShowContactWidget(enable_contact_widget);
  }

  if (!selectedFeatures.listingFeatures) {
    setSelectedFeatures((data) => {
      data.listingFeatures = [...(listing_features || [])];
      return { ...data };
    });
  }

  const {
    inputValue: enteredTitleValue,
    currentValue: titleValue,
    onValueChangeHandler: onChangeTitleText,
    onBlurHandler: onBlurTitle,
    hasError: titleHasError,
    reset: resetTitle,
  } = useInputHandler({ defaultValue: title });

  const {
    inputValue: listingCategoryInputValue,
    currentValue: listingCategoryValue,
    onValueChangeHandler: onListingCategoryChange,
    // onBlurHandler: onBlurListingCategory,
    hasError: listingCategoryHasError,
    reset: resetListingCategory,
  } = useInputHandler({ defaultValue: listing_category.id });

  const {
    inputValue: enteredKeywordsValue,
    currentValue: keywordsValue,
    onValueChangeHandler: onChangeKeywordsText,
    onBlurHandler: onBlurKeywords,
    hasError: keywordsHasError,
    reset: resetKeywords,
  } = useInputHandler({ defaultValue: keywords });

  const {
    inputValue: addressLine1InputValue,
    currentValue: addressLine1Value,
    onValueChangeHandler: onChangeAddressLine1,
    onBlurHandler: onBlurAddressLine1,
    hasError: addressLine1HasError,
    reset: resetAddressLine1,
  } = useInputHandler({
    defaultValue: address_line_1,
  });

  const {
    inputValue: addressLine2InputValue,
    currentValue: addressLine2Value,
    onValueChangeHandler: onChangeAddressLine2,
    onBlurHandler: onBlurAddressLine2,
    hasError: addressLine2HasError,
    reset: resetAddressLine2,
  } = useInputHandler({
    defaultValue: address_line_2,
  });

  const {
    inputValue: landmarkInputValue,
    currentValue: landmarkValue,
    onValueChangeHandler: onChangeLandmark,
    onBlurHandler: onBlurLandmark,
    hasError: landmarkHasError,
    reset: resetLandmark,
  } = useInputHandler({
    defaultValue: landmark,
  });

  const {
    inputValue: localityInputValue,
    currentValue: localityValue,
    onValueChangeHandler: onChangeLocality,
    onBlurHandler: onBlurLocality,
    hasError: localityHasError,
    reset: resetLocality,
  } = useInputHandler({
    defaultValue: locality,
  });

  const {
    inputValue: cityInputValue,
    currentValue: cityValue,
    onValueChangeHandler: onChangeCity,
    onBlurHandler: onBlurCity,
    hasError: cityHasError,
    reset: resetCity,
  } = useInputHandler({
    defaultValue: city,
  });

  const {
    inputValue: stateInputValue,
    currentValue: stateValue,
    onValueChangeHandler: onChangeState,
    onBlurHandler: onBlurState,
    hasError: stateHasError,
    reset: resetState,
  } = useInputHandler({
    defaultValue: state,
  });

  const {
    inputValue: countryInputValue,
    currentValue: countryValue,
    onValueChangeHandler: onChangeCountry,
    onBlurHandler: onBlurCountry,
    hasError: countryHasError,
    reset: resetCountry,
  } = useInputHandler({
    defaultValue: country,
  });

  const {
    inputValue: postalCodeInputValue,
    currentValue: postalCodeValue,
    onValueChangeHandler: onChangePostalCode,
    onBlurHandler: onBlurPostalCode,
    hasError: postalCodeHasError,
    reset: resetPostalCode,
  } = useInputHandler({
    defaultValue: postal_code,
  });

  const {
    inputValue: descriptionInputValue,
    currentValue: descriptionValue,
    onValueChangeHandler: onChangeDescription,
    onBlurHandler: onBlurDescription,
    hasError: descriptionHasError,
    reset: resetDescription,
  } = useInputHandler({
    defaultValue: description,
  });

  const {
    inputValue: videoLinkInputValue,
    currentValue: videoLinkValue,
    onValueChangeHandler: onChangeVideoLink,
    onBlurHandler: onBlurVideoLink,
    hasError: videoLinkHasError,
    reset: resetVideoLink,
  } = useInputHandler({
    defaultValue: video_link,
  });

  const {
    inputValue: contactPhoneInputValue,
    currentValue: contactPhoneValue,
    onValueChangeHandler: onChangeContactPhone,
    onBlurHandler: onBlurContactPhone,
    hasError: contactPhoneHasError,
    reset: resetContactPhone,
  } = useInputHandler({
    defaultValue: contact_phone,
  });

  const {
    inputValue: websiteLinkInputValue,
    currentValue: websiteLinkValue,
    onValueChangeHandler: onChangeWebsiteLink,
    onBlurHandler: onBlurWebsiteLink,
    hasError: websiteLinkHasError,
    reset: resetWebsiteLink,
  } = useInputHandler({
    defaultValue: website_link,
  });

  const {
    inputValue: contactEmailInputValue,
    currentValue: contactEmailValue,
    onValueChangeHandler: onChangeContactEmail,
    onBlurHandler: onBlurContactEmail,
    hasError: contactEmailHasError,
    reset: resetContactEmail,
  } = useInputHandler({
    defaultValue: contact_email,
  });

  const {
    inputValue: facebookLinkInputValue,
    currentValue: facebookLinkValue,
    onValueChangeHandler: onChangeFacebookLink,
    onBlurHandler: onBlurFacebookLink,
    hasError: facebookLinkHasError,
    reset: resetFacebookLink,
  } = useInputHandler({
    defaultValue: facebook_link,
  });

  const {
    inputValue: twitterLinkInputValue,
    currentValue: twitterLinkValue,
    onValueChangeHandler: onChangeTwitterLink,
    onBlurHandler: onBlurTwitterLink,
    hasError: twitterLinkHasError,
    reset: resetTwitterLink,
  } = useInputHandler({
    defaultValue: twitter_link,
  });

  const {
    inputValue: youtubeLinkInputValue,
    currentValue: youtubeLinkValue,
    onValueChangeHandler: onChangeYoutubeLink,
    onBlurHandler: onBlurYoutubeLink,
    hasError: youtubeLinkHasError,
    reset: resetYoutubeLink,
  } = useInputHandler({
    defaultValue: youtube_link,
  });

  const {
    inputValue: instaLinkInputValue,
    currentValue: instaLinkValue,
    onValueChangeHandler: onChangeInstaLink,
    onBlurHandler: onBlurInstaLink,
    hasError: instaLinkHasError,
    reset: resetInstaLink,
  } = useInputHandler({
    defaultValue: instagram_link,
  });

  const {
    inputValue: whatsappLinkInputValue,
    currentValue: whatsappLinkValue,
    onValueChangeHandler: onChangeWhatsappLink,
    onBlurHandler: onBlurWhatsappLink,
    hasError: whatsappLinkHasError,
    reset: resetWhatsappLink,
  } = useInputHandler({
    defaultValue: whatsapp_link,
  });

  const {
    inputValue: skypeLinkInputValue,
    currentValue: skypeLinkValue,
    onValueChangeHandler: onChangeSkypeLink,
    onBlurHandler: onBlurSkypeLink,
    hasError: skypeLinkHasError,
    reset: resetSkypeLink,
  } = useInputHandler({
    defaultValue: skype_link,
  });

  const {
    inputValue: minPriceInputValue,
    currentValue: minPriceValue,
    onValueChangeHandler: onChangeMinPrice,
    onBlurHandler: onBlurMinPrice,
    hasError: minPriceHasError,
    reset: resetMinPrice,
  } = useInputHandler({
    defaultValue:
      min_price && !isNaN(min_price)
        ? min_price / 100
        : min_price ?? true
        ? min_price || ""
        : min_price,
  });

  const {
    inputValue: maxPriceInputValue,
    currentValue: maxPriceValue,
    onValueChangeHandler: onChangeMaxPrice,
    onBlurHandler: onBlurMaxPrice,
    hasError: maxPriceHasError,
    reset: resetMaxPrice,
  } = useInputHandler({
    defaultValue:
      max_price && !isNaN(max_price)
        ? max_price / 100
        : max_price ?? true
        ? max_price || ""
        : max_price,
  });

  const {
    inputValue: googleMapURLInputValue,
    currentValue: googleMapURLValue,
    onValueChangeHandler: onChangeGoogleMapURL,
    onBlurHandler: onBlurGoogleMapURL,
    hasError: googleMapURLHasError,
    reset: resetGoogleMapURL,
  } = useInputHandler({
    defaultValue: google_maps_url,
  });

  // const {
  //   inputValue: facebookPageURLInputValue,
  //   currentValue: facebookPageURLValue,
  //   onValueChangeHandler: onChangeFacebookPageURL,
  //   onBlurHandler: onBlurFacebookPageURL,
  //   hasError: facebookPageURLHasError,
  //   reset: resetFacebookPageURL,
  // } = useInputHandler({
  //   defaultValue: facebook_page_url,
  // });

  React.useEffect(() => {
    if (
      enteredTitleValue !== undefined ||
      listingCategoryInputValue !== undefined ||
      enteredKeywordsValue ||
      latLng?.lat ||
      latLng?.lng ||
      addressLine1InputValue !== undefined ||
      addressLine2InputValue !== undefined ||
      landmarkInputValue !== undefined ||
      localityInputValue !== undefined ||
      cityInputValue !== undefined ||
      stateInputValue !== undefined ||
      countryInputValue !== undefined ||
      postalCodeInputValue !== undefined ||
      descriptionInputValue !== undefined ||
      contactPhoneInputValue !== undefined ||
      contactEmailInputValue !== undefined ||
      showContactWidget !== enable_contact_widget ||
      videoLinkInputValue !== undefined ||
      websiteLinkInputValue !== undefined ||
      twitterLinkInputValue !== undefined ||
      facebookLinkInputValue !== undefined ||
      youtubeLinkInputValue !== undefined ||
      instaLinkInputValue !== undefined ||
      whatsappLinkInputValue !== undefined ||
      skypeLinkInputValue !== undefined ||
      maxPriceInputValue !== undefined ||
      minPriceInputValue !== undefined ||
      googleMapURLInputValue !== undefined ||
      pickedImage ||
      selectedFeatures?.currDeselectedIDs?.length ||
      selectedFeatures?.currSelectedIDs?.length ||
      galleryImages?.removedImages?.length ||
      galleryImages?.addedImages?.length
    ) {
      setIsEditingForm(true);
    } else {
      setIsEditingForm(false);
    }
  }, [
    setIsEditingForm,
    enteredTitleValue,
    keywords,
    listingCategoryInputValue,
    keywordsValue,
    latLng?.lat,
    latLng?.lng,
    addressLine1InputValue,
    addressLine2InputValue,
    landmarkInputValue,
    localityInputValue,
    cityInputValue,
    stateInputValue,
    countryInputValue,
    postalCodeInputValue,
    descriptionInputValue,
    contactPhoneInputValue,
    contactEmailInputValue,
    showContactWidget,
    enable_contact_widget,
    googleMapURLInputValue,
    videoLinkInputValue,
    websiteLinkInputValue,
    twitterLinkInputValue,
    facebookLinkInputValue,
    youtubeLinkInputValue,
    instaLinkInputValue,
    whatsappLinkInputValue,
    skypeLinkInputValue,
    maxPriceInputValue,
    minPriceInputValue,
    pickedImage,
    selectedFeatures?.currDeselectedIDs?.length,
    selectedFeatures?.currSelectedIDs?.length,
    galleryImages?.removedImages?.length,
    galleryImages?.addedImages?.length,
  ]);

  const resetFormData = React.useCallback(
    ({
      resetAddedGalleryImages,
      resetRemovedGalleryImages,
      resetCurrSelectedFeatures,
      resetCurrDeselectedFeatures,
      resetOtherFormData,
    }) => {
      if (resetOtherFormData) {
        resetTitle();
        resetListingCategory();
        getListingFeatures();
        resetKeywords();
      }
      if (resetCurrSelectedFeatures) {
        setSelectedFeatures((data) => ({
          ...data,
          listingFeatures: data.listingFeatures?.filter((d) =>
            data.currSelectedIDs.includes(d.id) ? false : true
          ),
          currSelectedIDs: [],
        }));
      }
      if (resetCurrDeselectedFeatures) {
        setSelectedFeatures((data) => ({
          ...data,
          listingFeatures: [
            ...(listing_features?.filter((d) =>
              data.currDeselectedIDs.includes(d.id)
            ) || []),
            ...(data.listingFeatures || []),
          ],
          currDeselectedIDs: [],
        }));
      }
      if (resetOtherFormData) {
        setLatLng({ lat: undefined, lng: undefined });
        setMapAddress("");
        resetAddressLine1();
        resetAddressLine2();
        resetLandmark();
        resetLocality();
        resetCity();
        resetState();
        resetCountry();
        resetPostalCode();
        setPickedImage();
      }
      if (resetAddedGalleryImages) {
        setGalleryImages((data) => ({
          ...data,
          addedImages: [],
        }));
      }
      if (resetRemovedGalleryImages) {
        setGalleryImages((data) => ({
          ...data,
          removedImages: [],
        }));
      }
      if (resetOtherFormData) {
        resetDescription();
        resetContactPhone();
        resetContactEmail();
        resetVideoLink();
        resetWebsiteLink();
        resetFacebookLink();
        resetTwitterLink();
        resetYoutubeLink();
        resetInstaLink();
        resetWhatsappLink();
        resetSkypeLink();
        resetMaxPrice();
        resetMinPrice();
        resetGoogleMapURL();
        // resetFacebookPageURL();
      }
    },
    [
      resetTitle,
      resetListingCategory,
      getListingFeatures,
      resetKeywords,
      listing_features,
      setLatLng,
      resetAddressLine1,
      resetAddressLine2,
      resetLandmark,
      resetLocality,
      resetCity,
      resetState,
      resetCountry,
      resetPostalCode,
      setPickedImage,
      resetDescription,
      resetContactPhone,
      resetContactEmail,
      resetVideoLink,
      resetWebsiteLink,
      resetFacebookLink,
      resetTwitterLink,
      resetYoutubeLink,
      resetInstaLink,
      resetWhatsappLink,
      resetSkypeLink,
      resetMaxPrice,
      resetMinPrice,
      resetGoogleMapURL,
      // resetFacebookPageURL,
    ]
  );

  React.useEffect(() => {
    if (resetClicked) {
      setResetClicked((resetClicked) => !resetClicked);
      resetFormData({
        resetAddedGalleryImages: true,
        resetRemovedGalleryImages: true,
        resetCurrSelectedFeatures: true,
        resetCurrDeselectedFeatures: true,
        resetOtherFormData: true,
      });
    }
  }, [resetClicked, setResetClicked, resetFormData]);

  if (saveClicked) {
    setSaveClicked(false);
    let updateObj = {};
    if (listingCategoryInputValue)
      updateObj.category_id = listingCategoryInputValue;
    // updateObj.price_id
    if (enteredTitleValue === titleValue) updateObj.title = enteredTitleValue;
    if (enteredKeywordsValue === keywordsValue)
      updateObj.keywords = JSON.stringify(
        (enteredKeywordsValue || "").split(",").map((v) => v.trim())
      );
    if (latLng?.lat && latLng?.lng) {
      updateObj.lat = latLng.lat;
      updateObj.lng = latLng.lng;
    }
    if (
      addressLine1InputValue === addressLine1Value &&
      addressLine1Value !== address_line_1
    )
      updateObj.address_line_1 = addressLine1InputValue;
    if (
      addressLine2InputValue === addressLine2Value &&
      addressLine2Value !== address_line_2
    )
      updateObj.address_line_2 = addressLine2InputValue;
    if (landmarkInputValue === landmarkValue && landmarkValue !== landmark)
      updateObj.landmark = landmarkInputValue;
    if (localityInputValue === localityValue && localityValue !== locality)
      updateObj.locality = localityInputValue;
    if (cityInputValue === cityValue && cityValue !== city)
      updateObj.city = cityInputValue;
    if (stateInputValue === stateValue && stateValue !== state)
      updateObj.state = stateInputValue;
    if (countryInputValue === countryValue && countryValue !== country)
      updateObj.country = countryInputValue;
    if (
      postalCodeInputValue === postalCodeValue &&
      postalCodeValue !== postal_code
    )
      updateObj.postal_code = postalCodeInputValue;
    if (
      descriptionInputValue === descriptionValue &&
      descriptionValue !== description
    )
      updateObj.description = descriptionInputValue;
    if (
      contactPhoneInputValue === contactPhoneValue &&
      contactPhoneValue !== contact_phone
    )
      updateObj.contact_phone = contactPhoneInputValue;
    if (
      contactEmailInputValue === contactEmailValue &&
      contactEmailValue !== contact_email
    )
      updateObj.contact_email = contactEmailInputValue;
    if (showContactWidget !== enable_contact_widget) {
      updateObj.enable_contact_widget = showContactWidget;
    }
    if (videoLinkInputValue === videoLinkValue && videoLinkValue !== video_link)
      updateObj.video_link = videoLinkInputValue;
    if (
      websiteLinkInputValue === websiteLinkValue &&
      websiteLinkValue !== website_link
    )
      updateObj.website_link = websiteLinkInputValue;
    if (
      facebookLinkInputValue === facebookLinkValue &&
      facebookLinkValue !== facebook_link
    )
      updateObj.facebook_link = facebookLinkInputValue;
    if (
      twitterLinkInputValue === twitterLinkValue &&
      twitterLinkValue !== twitter_link
    )
      updateObj.twitter_link = twitterLinkInputValue;
    if (
      youtubeLinkInputValue === youtubeLinkValue &&
      youtubeLinkValue !== youtube_link
    )
      updateObj.youtube_link = youtubeLinkInputValue;
    if (
      instaLinkInputValue === instaLinkValue &&
      instaLinkValue !== instagram_link
    )
      updateObj.instagram_link = instaLinkInputValue;
    if (
      whatsappLinkInputValue === whatsappLinkValue &&
      whatsappLinkValue !== whatsapp_link
    )
      updateObj.whatsapp_link = whatsappLinkInputValue;
    if (skypeLinkInputValue === skypeLinkValue && skypeLinkValue !== skype_link)
      updateObj.skype_link = skypeLinkInputValue;
    if (
      googleMapURLInputValue === googleMapURLValue &&
      googleMapURLValue !== google_maps_url
    )
      updateObj.google_maps_url = googleMapURLInputValue;
    if (
      maxPriceInputValue === maxPriceValue &&
      +maxPriceValue !== +max_price / 100
    )
      updateObj.max_price = parseFloat(maxPriceInputValue || 0) * 100;

    if (
      minPriceInputValue === minPriceValue &&
      +minPriceValue !== +min_price / 100
    )
      updateObj.min_price = parseFloat(minPriceInputValue || 0) * 100;
    if (pickedImage !== undefined) updateObj.cover_image = pickedImage;

    if (Object.keys(updateObj)?.length) {
      const updateBasicDetails = async () => {
        let data;
        if (!Object.keys(updateObj)?.length) {
          return;
        }
        const formData = createCrossPlatformFormData({
          blobDataKeys: "cover_image",
          data: updateObj,
        });
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.patch(
            apiURIV2 + "/listings/" + lid,
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (res.status === 200) {
            data = res.data.data;
            updateListingDetails((ldd) => {
              ldd = { ...ldd, ...data };
              return { ...ldd };
            });
            resetFormData({
              resetOtherFormData: true,
            });
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: "Listing Successfully Updated",
            });
          }
        } catch (e) {
          console.log("e.res", e?.response, e);
          if (e?.response?.data?.message) {
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: e?.response?.data?.message,
            });
          }
        }
      };
      updateBasicDetails();
    }
    if (selectedFeatures.currDeselectedIDs?.length) {
      const updateDeselectedFeatures = async (fid) => {
        let data;
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.delete(
            apiURIV2 + "/listings/" + lid + "/features/" + fid,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.status === 204) {
            // data = res.data.data;
            updateListingDetails((ldd) => {
              ldd.listing_features = ldd.listing_features.filter(
                (v) => v.id !== fid
              );
              return { ...ldd };
            });
            setSelectedFeatures((d) => {
              d = {
                ...d,
                currDeselectedIDs: d.currDeselectedIDs.filter((v) => v !== fid),
              };
              return { ...d };
            });
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: "Listing Successfully Updated",
            });
          }
        } catch (e) {
          console.log("e.res", e?.response, e);
        }
      };
      let promiseArr = [];
      selectedFeatures.currDeselectedIDs.forEach((id) => {
        promiseArr.push(updateDeselectedFeatures(id));
      });
      Promise.all(promiseArr)
        .then((values) => {
          resetFormData({
            resetCurrDeselectedFeatures: true,
          });
        })
        .catch((e) => console.log(e));
    }
    if (selectedFeatures.currSelectedIDs?.length) {
      const updateSelectedFeatures = async (fid) => {
        let data;
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.post(
            apiURIV2 + "/listings/" + lid + "/features/" + fid,
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.status === 200) {
            // data = res.data.data;
            updateListingDetails((ldd) => {
              const aLD = listingFeatures.find((v) => v.value === fid);
              ldd.listing_features = [
                ...(ldd.listing_features || []),
                { id: aLD.value, title: aLD.label },
              ];
              return { ...ldd };
            });
            setSelectedFeatures((d) => {
              d = {
                ...d,
                currSelectedIDs: d.currSelectedIDs.filter((v) => v !== fid),
              };
              return { ...d };
            });
          }
        } catch (e) {
          console.log("e.res", e?.response, e);
        }
      };
      let promiseArr = [];
      selectedFeatures.currSelectedIDs.forEach((id) => {
        promiseArr.push(updateSelectedFeatures(id));
      });
      Promise.all(promiseArr)
        .then((values) => {
          resetFormData({
            resetCurrSelectedFeatures: true,
          });
        })
        .catch((e) => console.log(e));
    }
    if (galleryImages.removedImages?.length) {
      const removeGalleryImages = async (giid) => {
        let data;
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.delete(
            apiURIV2 + "/listings/" + lid + "/gallery/images/" + giid,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.status === 204) {
            updateListingDetails((ldd) => {
              ldd.gallery_images = ldd.gallery_images.filter(
                (d) => d.id !== giid
              );
              return { ...ldd };
            });
            setGalleryImages((d) => {
              d.removedImages = d.removedImages.filter((v) => v !== giid);
              return { ...d };
            });
          }
        } catch (e) {
          console.log("e.res", e?.response, e);
        }
      };
      const promiseArr = galleryImages.removedImages.map((d) => {
        removeGalleryImages(d);
      });
      Promise.all(promiseArr)
        .then((values) => {
          resetFormData({
            resetRemovedGalleryImages: true,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (galleryImages.addedImages?.length) {
      const addGalleryImages = async (dataArray) => {
        let data;
        const formData = createCrossPlatformFormData({
          blobDataKeys: "gallery_images",
          data: { gallery_images: dataArray },
        });
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.post(
            apiURIV2 + "/listings/" + lid + "/gallery/images",
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (res.status === 201) {
            data = res.data.data;
            updateListingDetails((ldd) => {
              ldd.gallery_images = [...(ldd.gallery_images || []), data];
              return { ...ldd };
            });
            setGalleryImages((d) => {
              d.addedImages = [];
              return { ...d };
            });
            resetFormData({
              resetAddedGalleryImages: true,
            });
          }
        } catch (e) {
          console.log("e.res", e?.response, e);
        }
      };
      // const promiseArr = galleryImages.addedImages.map((d) => {
      //   addGalleryImages(d);
      // });
      // Promise.all(promiseArr)
      //   .then((values) => {
      //     console.log("gallery promise all values: ", values);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      addGalleryImages([...(galleryImages.addedImages || [])]);
    }
  }

  const getCouponsData = async () => {
    setIsCouponsLoading((isLoading) => !isLoading && true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/coupons", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        data = res.data.data.coupons;
      }
    } catch (e) {
      console.log("e.response", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Coupons"),
      });
    } finally {
      setCouponsList(
        (data || [])
          .filter((v) => {
            const index = coupons.findIndex((c) => c.id == v.id);
            if (index > -1) {
              return false;
            }
            return true;
          })
          .map((v) => ({ label: v.code, value: v.id }))
      );
      setIsCouponsLoading((isLoading) => isLoading && false);
    }
  };

  const allotUnallotCoupon = async (coupon_id, code, operation) => {
    setAllotUnallotIsLoading((isLoading) => (!isLoading ? true : true));
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + `/coupons/${coupon_id}/${operation}`,
        {
          listing_id: lid,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        setCoupons((c) => {
          c[c.length - 1] = res.data.data.coupon;
          return [...c];
        });
        setCouponsList((v) => {
          return v.filter((v) => v.id === coupon_id);
        });
      } else if (res.status === 204) {
        setCouponsList((v) => {
          v.push({ label: code, value: coupon_id });
          return v;
        });
      }
    } catch (e) {
      console.log("error:", e);
      if (e?.response) {
        console.log("e.response", e?.response);
        if (e.response.status === 409) {
          setCoupons((c) => {
            c.splice(c.length - 1, 1);
            return [...c];
          });
        }
      }

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while alloting/unalloting coupon for listing"
        ),
      });
    } finally {
      setAllotUnallotIsLoading((isLoading) => (isLoading ? false : false));
    }
  };

  return (
    <>
      {/* <Card title="Current Package">
        <View className="flex-row flex-wrap px-4 w-full ">
          <View className="p-4 w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 lg:flex-row">
            {currentPackageDetails ? (
              <>
                {Object.keys(currentPackageDetails)?.length ? (
                  <>
                    <View className="flex-col justify-between flex-auto">
                      <Font
                        weight={600}
                        className="font-semibold text-app-d text-lg leading-5 capitalize pb-2"
                      >
                        {currentPackageDetails?.title}
                      </Font>
                      <View className="w-full pt-2 pb-4">
                        <Font className="text-sm text-app-d1">
                          {currentPackageDetails?.subtitle}
                        </Font>
                      </View>
                    </View>
                  </>
                ) : (
                  <View className="flex-1 justify-center items-center h-14">
                    <Font className="text-app-d1">
                      Active Package Details Not Found
                    </Font>
                  </View>
                )}
                <View className="w-full lg:w-60 justify-center">
                  <Pressable
                    className="border border-app-h bg-app-h3 items-center justify-center p-2 rounded-full"
                    onPress={onUpgradePlanPress}
                  >
                    <Font className="text-app-h font-semibold">Manage</Font>
                  </Pressable>
                </View>
              </>
            ) : (
              <View className="flex-1 justify-center items-center h-14">
                <Font>Loading...</Font>
              </View>
            )}
          </View>
        </View>
      </Card> */}
      <Card title="Basic Information">
        <View className="flex-row flex-wrap px-2 w-full">
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Title"
              value={titleValue}
              onChangeText={onChangeTitleText}
              onBlur={onBlurTitle}
              note={titleHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Select
              label="Category"
              options={listingCategories}
              placeholder="Select category"
              value={listingCategoryValue}
              setValue={(e) => {
                e = parseInt(e);
                onListingCategoryChange(e);
                getListingFeatures(e);
              }}
              note={listingCategoryHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Keywords"
              value={keywordsValue}
              onChangeText={onChangeKeywordsText}
              onBlur={onBlurKeywords}
              note={keywordsHasError}
              placeholder="e.g. hotel, store, grocery"
            />
          </View>
          <View className="px-2 w-full pb-4">
            <View className="w-full h-8 items-center flex-row">
              <Font className="flex-1  text-app-d1" weight={600}>
                Other Features
              </Font>
            </View>
            <View className="w-full">
              <FlatList
                className="flex-1 w-full pb-4 pt-2"
                ListEmptyComponent={() => (
                  <View className="flex-1 w-full justify-center items-center border border-app-d3 rounded-md py-3 px-8">
                    <Font>{lFIsLoading ? "Loading..." : "Not Available"}</Font>
                  </View>
                )}
                horizontal={!!listingFeatures?.length}
                data={listingFeatures}
                ItemSeparatorComponent={() => <View className="w-2"></View>}
                renderItem={({ item }) => (
                  <Pressable
                    className={`border p-3 rounded-lg ${
                      selectedFeatures.listingFeatures?.find(
                        (v) => v.id === item.value
                      )
                        ? "border-app-g bg-app-g3"
                        : "border-gray-200"
                    } overflow-hidden`}
                    onPress={() => {
                      const i = selectedFeatures.listingFeatures?.findIndex(
                        (v) => v.id === item.value
                      );
                      const alreadyAvailable = listing_features.find(
                        (v) => v.id === item.value
                      );
                      if (i === -1) {
                        setSelectedFeatures((data) => ({
                          currDeselectedIDs: alreadyAvailable
                            ? data.currDeselectedIDs.filter(
                                (v) => v !== item.value
                              )
                            : data.currDeselectedIDs,
                          listingFeatures: [
                            ...data.listingFeatures,
                            { title: item.label, id: item.value },
                          ],
                          currSelectedIDs: alreadyAvailable
                            ? data.currSelectedIDs
                            : [...(data.currSelectedIDs || []), item.value],
                        }));
                      } else if (i > -1) {
                        setSelectedFeatures((data) => {
                          data.listingFeatures.splice(i, 1);
                          data = {
                            currSelectedIDs: alreadyAvailable
                              ? data.currSelectedIDs
                              : data.currSelectedIDs.filter(
                                  (v) => v !== item.value
                                ),
                            listingFeatures: [...data.listingFeatures],
                            currDeselectedIDs: alreadyAvailable
                              ? [...(data.currDeselectedIDs || []), item.value]
                              : data.currDeselectedIDs,
                          };
                          return { ...data };
                        });
                      }
                    }}
                  >
                    <Font>{item.label}</Font>
                    {selectedFeatures.listingFeatures?.find(
                      (v) => v.id === item.value
                    ) && (
                      <View className="rounded-full bg-app-g p-5 absolute -right-4 -bottom-6">
                        <RemixIcon
                          name="ri-check-fill"
                          className="-my-5 -ml-4 -mr-1 -pb-8"
                          size={20}
                          color={colors.app.a}
                        />
                      </View>
                    )}
                  </Pressable>
                )}
              />
            </View>
          </View>
        </View>
      </Card>
      <Card title="Location">
        <View className="flex-row flex-wrap px-2 pb-4">
          <View className="w-full h-80 pb-4 px-2">
            <Maps
              url={websiteLinkValue}
              image={pickedImage?.uri || mediaURI + cover_image_url}
              title={title}
              location={`${addressLine1Value ? addressLine1Value : ""}${
                addressLine2Value
                  ? (addressLine1Value ? ", " : "") + addressLine2Value
                  : ""
              }${
                localityValue
                  ? (addressLine2Value ? ", " : "") + localityValue
                  : ""
              }${cityValue ? (localityValue ? ", " : "") + cityValue : ""}${
                stateValue ? (cityValue ? ", " : "") + stateValue : ""
              }${countryValue ? (stateValue ? ", " : "") + countryValue : ""}${
                postalCodeValue ? " - " + postalCodeValue : ""
              }`}
              latitude={latLng?.lat || lat}
              longitude={latLng?.lng || lng}
              rating={average_rating}
              priceMin={minPriceValue}
              priceMax={maxPriceValue}
              onLocate={async ({ data: { latitude, longitude } }) => {
                setLatLng({ lat: latitude, lng: longitude });
                const addrDetails = await getAddrDetails(latitude, longitude);
                setMapAddress(addrDetails.data?.display_name);
              }}
            ></Maps>
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Map's Address"
              value={mapAddress}
              editable={false}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Latitude"
              value={latLng?.lat || lat}
              keyboardType="numeric"
              inputMode="numeric"
              editable={false}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Longitude"
              value={latLng?.lng || lng}
              keyboardType="numeric"
              inputMode="numeric"
              editable={false}
            />
          </View>
          {/* <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Friendly Address"
              value={friendlyAddressValue}
              onChangeText={onChangeFriendlyAddress}
              onBlur={onBlurFriendlyAddress}
              note={friendlyAddressHasError}
            />
          </View> */}
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Address Line 1"
              value={addressLine1Value}
              onChangeText={onChangeAddressLine1}
              onBlur={onBlurAddressLine1}
              note={addressLine1HasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Address Line 2"
              value={addressLine2Value}
              onChangeText={onChangeAddressLine2}
              onBlur={onBlurAddressLine2}
              note={addressLine2HasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Landmark"
              value={landmarkValue}
              onChangeText={onChangeLandmark}
              onBlur={onBlurLandmark}
              note={landmarkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Locality"
              value={localityValue}
              onChangeText={onChangeLocality}
              onBlur={onBlurLocality}
              note={localityHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="City"
              value={cityValue}
              onChangeText={onChangeCity}
              onBlur={onBlurCity}
              note={cityHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="State"
              value={stateValue}
              onChangeText={onChangeState}
              onBlur={onBlurState}
              note={stateHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Country"
              value={countryValue}
              onChangeText={onChangeCountry}
              onBlur={onBlurCountry}
              note={countryHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Postal Code"
              value={postalCodeValue}
              onChangeText={onChangePostalCode}
              onBlur={onBlurPostalCode}
              note={postalCodeHasError}
              keyboardType="numeric"
              inputMode="numeric"
            />
          </View>
        </View>
      </Card>
      <Card title="Cover Image">
        <View className="px-4 pt-4 pb-8 w-full">
          <View className="w-full lg:w-96 h-max flex-row flex-wrap">
            <MediaUpload
              pickedImage={pickedImage}
              onImagePick={(data) => setPickedImage(data)}
              placeholderImage={
                cover_image_url ? { uri: mediaURI + cover_image_url } : null
              }
            />
          </View>
        </View>
      </Card>
      <Card title="Gallery">
        <View
          className={`px-4 pt-4 pb-8 w-fullflex-row basis-0 items-start w-full ${
            Platform.OS === "web" ? "flex-1" : ""
          }`}
        >
          <View className="w-full h-full flex-row flex-1">
            <Galleryupload
              images={gallery_images.map((v) => ({
                id: v.id,
                uri: mediaURI + v.image_url,
              }))}
              onAddImage={(addedImage) =>
                setGalleryImages((d) => {
                  d.addedImages = [...(d.addedImages || []), addedImage];
                  return { ...d };
                })
              }
              onRemoveImage={(removedImage) =>
                setGalleryImages((d) => {
                  if ((removedImage.id + "").includes("-local")) {
                    d.addedImages = d.addedImages.filter(
                      (v) => v.id !== removedImage.id
                    );
                  } else {
                    d.removedImages = [
                      ...(d.removedImages || []),
                      removedImage.id,
                    ];
                  }
                  return { ...d };
                })
              }
            />
          </View>
        </View>
      </Card>
      <Card title="Details">
        <View className="flex-row flex-wrap px-2 pb-4">
          <View className="px-2 w-full pb-4">
            <Input
              type="longtext"
              label="Description"
              value={descriptionValue}
              onChangeText={onChangeDescription}
              onBlur={onBlurDescription}
              note={descriptionHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Video Link"
              value={videoLinkValue}
              onChangeText={onChangeVideoLink}
              onBlur={onBlurVideoLink}
              note={videoLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="number"
              label="Phone"
              value={contactPhoneValue}
              onChangeText={onChangeContactPhone}
              onBlur={onBlurContactPhone}
              note={contactPhoneHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Website"
              value={websiteLinkValue}
              onChangeText={onChangeWebsiteLink}
              onBlur={onBlurWebsiteLink}
              note={websiteLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Email"
              value={contactEmailValue}
              onChangeText={onChangeContactEmail}
              onBlur={onBlurContactEmail}
              note={contactEmailHasError}
            />
          </View>
          <View className="px-2 w-full pb-4">
            <Input
              type="toggle"
              label="Enable Contact Widget"
              setValue={() => setShowContactWidget(!showContactWidget)}
              value={showContactWidget}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Facebook"
              value={facebookLinkValue}
              onChangeText={onChangeFacebookLink}
              onBlur={onBlurFacebookLink}
              note={facebookLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Twitter"
              value={twitterLinkValue}
              onChangeText={onChangeTwitterLink}
              onBlur={onBlurTwitterLink}
              note={twitterLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Youtube"
              value={youtubeLinkValue}
              onChangeText={onChangeYoutubeLink}
              onBlur={onBlurYoutubeLink}
              note={youtubeLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Instagram"
              value={instaLinkValue}
              onChangeText={onChangeInstaLink}
              onBlur={onBlurInstaLink}
              note={instaLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Whatsapp"
              value={whatsappLinkValue}
              onChangeText={onChangeWhatsappLink}
              onBlur={onBlurWhatsappLink}
              note={whatsappLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Skype"
              value={skypeLinkValue}
              onChangeText={onChangeSkypeLink}
              onBlur={onBlurSkypeLink}
              note={skypeLinkHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="number"
              label="Minimum Price"
              leftText={currencySymbol}
              value={minPriceValue}
              onChangeText={onChangeMinPrice}
              onBlur={onBlurMinPrice}
              note={minPriceHasError}
            />
          </View>
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="number"
              label="Maximum Price"
              leftText={currencySymbol}
              value={maxPriceValue}
              onChangeText={onChangeMaxPrice}
              onBlur={onBlurMaxPrice}
              note={maxPriceHasError}
            />
          </View>
        </View>
      </Card>
      <Card
        title="Enable Coupon Widget"
        Widget={() => (
          <View className="w-8">
            <Input type="toggle" value={1} setValue={() => null} />
          </View>
        )}
        className="mb-4"
      >
        <View className="px-4 pb-4 w-full">
          <View className="flex-row justify-between items-center mb-4 w-full">
            <Font weight="600" className="capitalize">
              Coupons
            </Font>
            <Pressable
              className="p-1"
              onPress={() => {
                if (!couponsList) getCouponsData();
                setCoupons((coupons) => [
                  ...(coupons || []),
                  { id: coupons.length + 1, code: undefined },
                ]);
              }}
            >
              <RemixIcon
                name="ri-add-circle-line"
                color={colors.app.g}
                size={25}
              />
            </Pressable>
          </View>
          <View className="mb-4 w-full gap-4 flex-row flex-wrap">
            {!coupons ? (
              <View className="w-full flex-row justify-center items-center border border-app-d3 rounded-md py-2 pl-4 pr-8 mt-2">
                <Font>Loading...</Font>
              </View>
            ) : !coupons?.length ? (
              <View className="w-full flex-row justify-center items-center border border-app-d3 rounded-md py-2 pl-4 pr-8 mt-2">
                <Font>No Coupons</Font>
              </View>
            ) : (
              coupons.map((v1, i1) => (
                <View
                  key={"coupons" + i1}
                  className="w-full lg:w-1/3 flex-row justify-center items-center border border-app-c rounded-md p-1 mt-2"
                >
                  {/* <View className="flex-1 flex-row justify-center items-center"> */}
                  {v1.code ? (
                    <Input type="text" value={v1.code} editable={false} />
                  ) : (
                    <Select
                      options={couponsList || []}
                      placeholder={
                        isCouponsLoading
                          ? "Loading..."
                          : "Select Coupons From List"
                      }
                      value={v1.id}
                      setValue={(v) => {
                        allotUnallotCoupon(v, undefined, "allot");
                        setCoupons((c) => {
                          const selectedCoupon = couponsList.find(
                            (data) => data.value === v
                          );
                          c[i1] = {
                            id: v,
                            code: selectedCoupon?.label,
                          };
                          return [...c];
                        });
                      }}
                      enabled={!allotUnallotIsLoading}
                    />
                  )}
                  {/* </View> */}
                  <View className="px-0.5" />
                  <Pressable
                    className="overflow-visible justify-center items-center border border-app-c h-10 w-10 rounded cursor-pointer"
                    onPress={() => {
                      if (v1.code) {
                        allotUnallotCoupon(v1.id, v1.code, "un-allot");
                      }
                      setCoupons((coupons) => {
                        coupons.splice(i1, 1);
                        return [...coupons];
                      });
                    }}
                    disabled={allotUnallotIsLoading}
                  >
                    <RemixIcon
                      name="ri-close-fill"
                      // className="-mr-1"
                      size={25}
                      color={colors.app.e}
                    />
                  </Pressable>
                </View>
              ))
            )}
          </View>
        </View>
      </Card>
      <Card title="Third-party reviews">
        <View className="flex-row flex-wrap px-2 pb-4 w-full">
          <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Google Map’s URL"
              value={googleMapURLValue}
              onChangeText={onChangeGoogleMapURL}
              onBlur={onBlurGoogleMapURL}
              note={googleMapURLHasError}
            />
          </View>
          {/* <View className="px-2 w-full lg:w-1/3 pb-4">
            <Input
              type="text"
              label="Facebook Page URL"
              value={facebookPageURLValue}
              onChangeText={onChangeFacebookPageURL}
              onBlur={onBlurFacebookPageURL}
              note={facebookPageURLHasError}
            />
          </View> */}
        </View>
      </Card>
      <View className="py-8" />
    </>
  );
};

const ListingServices = ({
  bookable_services,
  lid,
  saveClicked,
  setSaveClicked,
  isEditingForm,
  setIsEditingForm,
  updateListingDetails,
  setShowResponseModal,
  resetClicked,
  setResetClicked,
  currencySymbol,
  setCurrencySymbol,
  enable_services,
}) => {
  const [getItemDetails, setGetItemDetails] = useState(false);
  const [isShowBookableServicesToggling, setIsShowBookableServicesToggling] =
    useState(false);
  const [bookableServices, setBookableServices] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [serviceCategoriesState, setServiceCategoriesState] = useState({
    sCIsLoading: false,
    serviceCategories: null,
  });
  const [addedServices, setAddedServices] = useState();
  const [updatedServices, setUpdatedServices] = useState();
  const [savingDetails, setSavingDetails] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });
  const [showEditServiceModal, setShowEditServiceModal] = useState({
    visible: false,
    onRequestClose: () =>
      setShowEditServiceModal((d) => {
        d.visible = false;
        d.data.category = null;
        d.data.index = null;
        return { ...d };
      }),
    data: {
      isModal: true,
      isEditMode: true,
      category: null,
      index: null,
    },
  });
  const [deleteServiceIDs, setDeleteServiceIDs] = useState({});
  const [showBookableServices, setShowBookableServices] = useState();

  if (!bookableServices && bookable_services) {
    setBookableServices(bookable_services);
  }
  if (![true, false].includes(showBookableServices)) {
    setShowBookableServices(enable_services);
  }

  if (
    !serviceCategoriesState.serviceCategories &&
    !serviceCategoriesState.sCIsLoading
  ) {
    const getListingCategories = async () => {
      setServiceCategoriesState((d) => {
        d.sCIsLoading = true;
        return { ...d };
      });
      let data = [];
      try {
        const res = await axiosInstance.get(apiURIV2 + "/service-categories");
        if (res.status === 200) {
          data = res.data.data;
        }
      } catch (e) {
        console.log("e.res", e?.response, e);
      } finally {
        setServiceCategoriesState((d) => {
          d.serviceCategories = data.map((d1) => ({
            value: d1.id,
            label: d1.title,
          }));
          return { ...d };
        });
        setServiceCategoriesState((d) => {
          d.sCIsLoading = false;
          return { ...d };
        });
      }
    };
    getListingCategories();
  }

  React.useEffect(() => {
    if (
      Object.keys(deleteServiceIDs || {})?.length ||
      updatedServices?.length ||
      addedServices?.length
    ) {
      setIsEditingForm(true);
    } else {
      setIsEditingForm(false);
    }
  }, [setIsEditingForm, deleteServiceIDs, updatedServices, addedServices]);

  // const resetServicesData = ({
  //   resetDeleteServicesIDs,
  //   resetUpdatedServices,
  //   resetAddedServices,
  // }) => {
  //   if (resetDeleteServicesIDs) {
  //     setDeleteServiceIDs({});
  //   }
  //   if (resetUpdatedServices) {
  //     setUpdatedServices();
  //   }
  //   if (resetAddedServices) {
  //     setAddedServices();
  //   }
  // };

  // React.useEffect(() => {
  //   if (resetClicked) {
  //     setResetClicked(false);
  //     resetServicesData({
  //       resetDeleteServicesIDs: true,
  //       resetUpdatedServices: true,
  //       resetAddedServices: true,
  //     });
  //   }
  // }, [setResetClicked, resetClicked]);

  if (saveClicked && !savingDetails) {
    setSaveClicked(false);
    setSavingDetails(true);
    if (Object.keys(deleteServiceIDs || {})?.length) {
      const deleteServices = async ({ id, category }) => {
        let data;
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.delete(
            apiURIV2 + "/listings/" + lid + "/services/" + id,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if ([204, 404].includes(res.status)) {
            updateListingDetails((ldd) => {
              const i = ldd.bookable_services[category].findIndex(
                (v) => parseInt(v.id) === parseInt(id)
              );
              if (i > -1) {
                ldd.bookable_services[category].splice(i, 1);
              }
              if (!ldd.bookable_services[category]?.length) {
                delete ldd.bookable_services[category];
              }
              return { ...ldd };
            });
            setDeleteServiceIDs((aS) => {
              delete aS[id];
              return { ...aS };
            });
          }
          return res;
        } catch (e) {
          console.log("e.res", e?.response, e);
          return e.response;
        }
      };
      let promiseArr = [];
      for (const key in deleteServiceIDs) {
        promiseArr.push(
          deleteServices({ id: key, category: deleteServiceIDs[key].category })
        );
      }
      Promise.all(promiseArr)
        .then((values) => {
          // let successCount = 0;
          // values.forEach((v, i) => {
          // if ([204, 404].includes(v?.status)) {
          //   successCount = i + 1;
          // }
          setShowResponseModal({
            visible: true,
            bodyText: "Listing Successfully Updated",
            onRequestClose: () =>
              setShowResponseModal({
                visible: false,
                bodyText: "",
                onRequestClose: () => null,
              }),
          });
          // const onRequestClose = () => {
          //   setShowResponseModal({
          //     visible: false,
          //     bodyText: "",
          //     onRequestClose: () => null,
          //   });
          // };
          // if (successCount === 0) {
          //   setShowResponseModal({
          //     bodyText: "Failed to Update the Details... try again...",
          //     visible: true,
          //     onRequestClose,
          //   });
          // } else if (successCount < values.length) {
          //   setShowResponseModal({
          //     bodyText: "Failed to Update Some of the Details",
          //     visible: true,
          //     onRequestClose,
          //   });
          // } else if (successCount === values.length) {
          //   setShowResponseModal({
          //     bodyText: "All the details are Successfully Updated",
          //     visible: true,
          //     onRequestClose,
          //   });
          // }
          // resetServicesData({
          //   resetDeleteServicesIDs: true,
          // });
          // });
        })
        .catch((e) => {
          e?.forEach((v, i) => {
            const msg = v?.response?.data?.data?.message;
            if (msg)
              setShowResponseModal({
                visible: true,
                bodyText: v?.response?.data?.data?.message,
                onRequestClose: () =>
                  setShowResponseModal({
                    visible: false,
                    bodyText: "",
                    onRequestClose: () => null,
                  }),
              });
          });
        })
        .finally(() => {
          setSavingDetails(false);
        });
    }
    if (updatedServices) {
      const updateServices = async ({ id, updateData, uiData }) => {
        let data;
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.patch(
            apiURIV2 + "/listings/" + lid + "/services/" + id,
            updateData,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.status === 200) {
            const categoryTitle = serviceCategoriesState.serviceCategories.find(
              (v) => v.value === parseInt(uiData.listing_service_category.id)
            ).label;
            updateListingDetails((ldd) => {
              const i =
                ldd.bookable_services?.[categoryTitle]?.findIndex(
                  (v) => v.id === id
                ) || -1;
              if (i > -1) {
                ldd.bookable_services[categoryTitle][i] = {
                  ...ldd.bookable_services[categoryTitle][i],
                  uiData,
                };
              } else {
                if (!ldd.bookable_services[categoryTitle]?.length) {
                  ldd.bookable_services[categoryTitle] = [];
                }
                ldd.bookable_services[categoryTitle].push(uiData);
              }
              return { ...ldd };
            });
            setUpdatedServices((aS) => {
              const i = aS.findIndex((v) => v.id === id);
              if (i > -1) {
                aS.splice(i, 1);
              }
              return [...aS];
            });
          }
          return res;
        } catch (e) {
          console.log("e.res", e?.response, e);
          return e.response;
        }
      };
      const promiseArr = updatedServices.map((d) => {
        return updateServices(d);
      });
      Promise.all(promiseArr)
        .then((values) => {
          setSavingDetails(false);
          // resetServicesData({
          //   resetUpdatedServices: true,
          // });
        })
        .catch((e) => console.log(e));
    }
    if (addedServices) {
      const addServices = async (bodyData, id) => {
        let data;
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.post(
            apiURIV2 + "/listings/" + lid + "/services",
            bodyData,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.status === 201) {
            data = res.data.data;
            data.service_unit.currency_symbol =
              data.service_unit.currency === "USD"
                ? "$"
                : data.service_unit.currency;
            const categoryTitle = serviceCategoriesState.serviceCategories.find(
              (v) => v.value === parseInt(bodyData.service_category_id)
            ).label;
            updateListingDetails((ldd) => {
              ldd.bookable_services[categoryTitle] = [
                ...(ldd.bookable_services[categoryTitle] || []),
                data,
              ];
              setBookableServices(ldd.bookable_services);
              return { ...ldd };
            });
            setAddedServices((aS) => {
              const i = aS.findIndex((v) => v.id === id);
              if (i > -1) {
                aS.splice(i, 1);
              }
              return [...aS];
            });
          }
          return res;
        } catch (e) {
          console.log("e.res", e?.response, e);
          return e.response;
        }
      };
      const promiseArr = addedServices.map((data) => {
        let d = { ...data };
        const id = d.id;
        delete d.id;
        return addServices(d, id);
      });
      Promise.all(promiseArr)
        .then((values) => {
          setSavingDetails(false);
          // resetServicesData({
          //   resetAddedServices: true,
          // });
        })
        .catch((e) => console.log(e));
    }
  }

  const GetItemDetailsComponent = (props) => {
    const {
      id,
      title,
      subtitle,
      cancellation_penalty,
      // gap_between_booking_and_appointment_in_hours,
      // allow_reschedule,
    } = bookableServices?.[props?.category]?.[props?.index] || {};
    const { id: service_category_id } =
      bookableServices?.[props?.category]?.[props?.index]
        ?.listing_service_category || {};
    const {
      name: service_unit_name,
      price,
      weekend_price,
      special_price,
      currency,
      allow_multiple_per_booking,
      max_per_booking,
    } = bookableServices?.[props?.category]?.[props?.index]?.service_unit || {};

    const [selectedCategoryID, setSelectedCategoryID] = useState();
    const [allowMultipleUnitsBooking, setAllowMultipleUnitsBooking] =
      useState();

    if (!selectedCategoryID && service_category_id) {
      setSelectedCategoryID(service_category_id);
    }
    if (allowMultipleUnitsBooking === undefined && allow_multiple_per_booking) {
      setAllowMultipleUnitsBooking(allow_multiple_per_booking);
    }

    const {
      inputValue: titleInputValue,
      currentValue: titleValue,
      onValueChangeHandler: onChangeTitleText,
      onBlurHandler: onBlurTitle,
      hasError: titleHasError,
      reset: resetTitle,
    } = useInputHandler({ defaultValue: title });

    const {
      inputValue: descriptionInputValue,
      currentValue: descriptionValue,
      onValueChangeHandler: onChangeDescriptionText,
      onBlurHandler: onBlurDescription,
      hasError: descriptionHasError,
      reset: resetDescription,
    } = useInputHandler({ defaultValue: subtitle });

    const {
      inputValue: serviceUnitInputValue,
      currentValue: serviceUnitValue,
      onValueChangeHandler: onChangeServiceUnitText,
      onBlurHandler: onBlurServiceUnit,
      hasError: serviceUnitHasError,
      reset: resetServiceUnit,
    } = useInputHandler({ defaultValue: service_unit_name });

    const {
      inputValue: currencyInputValue,
      currentValue: currencyValue,
      onValueChangeHandler: onChangeCurrencyText,
      // onBlurHandler: onBlurCurrency,
      hasError: currencyHasError,
      reset: resetCurrency,
    } = useInputHandler({ defaultValue: currency || "USD" });

    const {
      inputValue: regularPriceInputValue,
      currentValue: regularPriceValue,
      onValueChangeHandler: onChangeRegularPriceText,
      onBlurHandler: onBlurRegularPrice,
      hasError: regularPriceHasError,
      reset: resetRegularPrice,
    } = useInputHandler({
      defaultValue: price ? price / 100 : price ?? true ? "" : price,
    });

    const {
      inputValue: weekendPriceInputValue,
      currentValue: weekendPriceValue,
      onValueChangeHandler: onChangeWeekendPriceText,
      onBlurHandler: onBlurWeekendPrice,
      hasError: weekendPriceHasError,
      reset: resetWeekendPrice,
    } = useInputHandler({
      defaultValue: weekend_price
        ? weekend_price / 100
        : weekend_price ?? true
        ? ""
        : weekend_price,
    });

    const {
      inputValue: specialPriceInputValue,
      currentValue: specialPriceValue,
      onValueChangeHandler: onChangeSpecialPriceText,
      onBlurHandler: onBlurSpecialPrice,
      hasError: specialPriceHasError,
      reset: resetSpecialPrice,
    } = useInputHandler({
      defaultValue: special_price
        ? special_price / 100
        : special_price ?? true
        ? ""
        : special_price,
    });

    const {
      inputValue: maxServiceUnitInputValue,
      currentValue: maxServiceUnitValue,
      onValueChangeHandler: onChangeMaxServiceUnitText,
      onBlurHandler: onBlurMaxServiceUnit,
      hasError: maxServiceUnitHasError,
      reset: resetMaxServiceUnit,
    } = useInputHandler({
      defaultValue: max_per_booking
        ? max_per_booking
        : max_per_booking ?? true
        ? ""
        : max_per_booking,
    });

    const {
      inputValue: cancelationPenaltyInputValue,
      currentValue: cancelationPenaltyValue,
      onValueChangeHandler: onChangeCancelationPenaltyText,
      onBlurHandler: onBlurCancelationPenalty,
      hasError: cancelationPenaltyHasError,
      reset: resetCancelationPenalty,
    } = useInputHandler({
      defaultValue: cancellation_penalty
        ? cancellation_penalty
        : cancellation_penalty ?? true
        ? ""
        : cancellation_penalty,
    });

    const onAddServiceItem = () => {
      if (props.isEditMode) {
        let bookableServiceData = bookableServices[props.category][props.index];
        let updatedServiceDetails = {};
        if (selectedCategoryID !== service_category_id) {
          updatedServiceDetails.service_category_id = selectedCategoryID;
          bookableServiceData.listing_service_category.id = selectedCategoryID;
        }
        if (titleInputValue === titleValue) {
          updatedServiceDetails = {
            ...updatedServiceDetails,
            title: titleInputValue,
          };
          bookableServiceData.title = titleInputValue;
        }
        if (descriptionInputValue === descriptionValue) {
          updatedServiceDetails = {
            ...updatedServiceDetails,
            subtitle: descriptionInputValue,
          };
          bookableServiceData.subtitle = descriptionInputValue;
        }
        if (cancelationPenaltyInputValue === cancelationPenaltyValue) {
          updatedServiceDetails = {
            ...updatedServiceDetails,
            cancellation_penalty: cancelationPenaltyInputValue,
          };
          bookableServiceData.cancellation_penalty =
            cancelationPenaltyInputValue || 0;
        }
        if (serviceUnitInputValue === serviceUnitValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            name: serviceUnitInputValue,
          };
          bookableServiceData.service_unit.name = serviceUnitInputValue;
        }
        if (currencyInputValue === currencyValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            currency: currencyInputValue,
          };
          bookableServiceData.service_unit.currency = currencyInputValue;
        }
        if (regularPriceInputValue === regularPriceValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            price: parseFloat(regularPriceInputValue) * 100,
          };
          bookableServiceData.service_unit.price =
            parseFloat(regularPriceInputValue) * 100;
        }
        if (weekendPriceInputValue === weekendPriceValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            weekend_price: parseFloat(weekendPriceInputValue) * 100,
          };
          bookableServiceData.service_unit.weekend_price =
            parseFloat(weekendPriceInputValue) * 100;
        }
        if (specialPriceInputValue === specialPriceValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            special_price: parseFloat(specialPriceInputValue) * 100,
          };
          bookableServiceData.service_unit.special_price =
            parseFloat(specialPriceInputValue) * 100;
        }
        if (
          allowMultipleUnitsBooking !=
          bookableServiceData.service_unit.allow_multiple_per_booking
        ) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            allow_multiple_per_booking: allowMultipleUnitsBooking,
          };
          bookableServiceData.service_unit.allow_multiple_per_booking =
            allowMultipleUnitsBooking;
        }
        if (maxServiceUnitInputValue === maxServiceUnitValue) {
          updatedServiceDetails.service_unit = {
            ...updatedServiceDetails.service_unit,
            max_per_booking: maxServiceUnitInputValue,
          };
          bookableServiceData.service_unit.max_per_booking =
            maxServiceUnitInputValue;
        }
        setBookableServices((bs) => {
          bs = cloneDeep(bs);
          if (selectedCategoryID !== service_category_id) {
            const selectedCategoryTitle =
              serviceCategoriesState.serviceCategories.find(
                (v) => v.value === parseInt(selectedCategoryID)
              ).label;
            bs[props?.category].splice(props.index, 1);
            if (bs[props?.category].length === 0) {
              delete bs[props?.category];
            }
            if (!bs?.[selectedCategoryTitle]?.length) {
              bs[selectedCategoryTitle] = [];
            }
            bs[selectedCategoryTitle].push(bookableServiceData);
          } else {
            bs[props?.category][props.index] = bookableServiceData;
          }
          return bs;
        });
        setUpdatedServices((uS) => {
          uS = [
            ...(uS || []),
            {
              id: id,
              updateData: updatedServiceDetails,
              uiData: bookableServiceData,
            },
          ];
          return [...uS];
        });
      } else {
        const selectedCategoryTitle =
          serviceCategoriesState.serviceCategories.find(
            (v) => v.value === parseInt(selectedCategoryID)
          ).label;
        const addedServiceDetails = {
          service_category_id: selectedCategoryID,
          title: titleInputValue,
          subtitle: descriptionInputValue,
          cancellation_penalty: cancelationPenaltyInputValue,
          // "gap_between_booking_and_appointment_in_hours": 0,
          // "allow_reschedule": true,
          service_unit: {
            name: serviceUnitInputValue,
            price: parseFloat(regularPriceInputValue) * 100,
            weekend_price: parseFloat(weekendPriceInputValue) * 100,
            special_price: parseFloat(specialPriceInputValue) * 100,
            currency: currencyValue,
            currency_symbol: "$",
            allow_multiple_per_booking: allowMultipleUnitsBooking,
            max_per_booking: allowMultipleUnitsBooking
              ? maxServiceUnitInputValue || 0
              : null,
            // "allow_multiple_per_availability_slot": true
          },
        };
        setAddedServices((aS) => {
          aS = [
            ...(aS || []),
            { ...addedServiceDetails, id: Date.now() + "-local" },
          ];
          return [...aS];
        });
        setBookableServices((bs) => {
          bs = cloneDeep(bs);
          bs[selectedCategoryTitle] = [
            ...(bs?.[selectedCategoryTitle] || []),
            addedServiceDetails,
          ];
          return bs;
        });
      }
    };

    return (
      <Card
        title="Item Details"
        Widget={() => (
          <Pressable
            onPress={() => {
              if (props.isModal) {
                props.onRequestClose();
              } else {
                setGetItemDetails(false);
              }
            }}
          >
            <RemixIcon
              name="ri-close-fill"
              className=""
              size={25}
              color={colors.app.e}
            />
          </Pressable>
        )}
        className={`${props.isModal ? "w-82 lg:w-2/3" : ""}`}
      >
        <ScrollView
          className={`mb-4 mx-4 ${
            props.isModal
              ? `${Platform.OS === "web" ? "h-96 lg:h-[368px]" : "h-[75%]"}`
              : "w-full"
          }`}
          keyboardShouldPersistTaps="always"
        >
          <View className="flex-row flex-wrap">
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Select
                label="Category"
                options={serviceCategoriesState.serviceCategories}
                value={selectedCategoryID}
                setValue={(e) => setSelectedCategoryID(e)}
                placeholder="Select Category"
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="text"
                label="Title"
                value={titleValue}
                onChangeText={onChangeTitleText}
                onBlur={onBlurTitle}
                note={titleHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="text"
                label="Description"
                value={descriptionValue}
                onChangeText={onChangeDescriptionText}
                onBlur={onBlurDescription}
                note={descriptionHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Select
                label="Currency"
                options={currencyList}
                value={currencyValue}
                setValue={(e) => {
                  onChangeCurrencyText(e);
                  setCurrencySymbol(currencyList.find((v) => v.value === e));
                }}
                placeholder={
                  currencyValue === "USD" ? "Select Currency" : currencyValue
                }
                enabled={false}
                note={currencyHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="text"
                label="Service Unit Alias"
                value={serviceUnitValue}
                onChangeText={onChangeServiceUnitText}
                onBlur={onBlurServiceUnit}
                note={serviceUnitHasError}
                infoButtonText="A unit of measurement for your service. For e.g. A Car Wash Service can be measured on per wash basis, making 'wash' as the service unit. If price is set to 10$, customer will see the service listed at $10 / <service unit> i.e. in our case $10 / wash"
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Regular Price"
                leftText={currencySymbol}
                rightText={`/ ${serviceUnitValue || "-"}`}
                keyboardType="numeric"
                inputMode="numeric"
                value={regularPriceValue}
                onChangeText={onChangeRegularPriceText}
                onBlur={onBlurRegularPrice}
                note={regularPriceHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Weekend Price"
                leftText={currencySymbol}
                rightText={`/ ${serviceUnitValue || "-"}`}
                keyboardType="numeric"
                inputMode="numeric"
                value={weekendPriceValue}
                onChangeText={onChangeWeekendPriceText}
                onBlur={onBlurWeekendPrice}
                note={weekendPriceHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Special Price"
                leftText={currencySymbol}
                rightText={`/ ${serviceUnitValue || "-"}`}
                keyboardType="numeric"
                inputMode="numeric"
                value={specialPriceValue}
                onChangeText={onChangeSpecialPriceText}
                onBlur={onBlurSpecialPrice}
                note={specialPriceHasError}
              />
            </View>
            <View className="px-4 w-full lg:w-1/3 pb-4">
              <Input
                type="number"
                label="Cancellation Penalty in Percentage"
                rightText="%"
                keyboardType="numeric"
                inputMode="numeric"
                value={cancelationPenaltyValue}
                onChangeText={onChangeCancelationPenaltyText}
                onBlur={onBlurCancelationPenalty}
                note={cancelationPenaltyHasError}
                placeholder="e.g. 30 (without % sign)"
              />
            </View>
            <View className="px-4 w-full pb-4">
              <Card
                title="Allow Multiple Unit per Booking"
                Widget={() => (
                  <View className="w-8">
                    <Input
                      type="toggle"
                      value={allowMultipleUnitsBooking}
                      setValue={setAllowMultipleUnitsBooking}
                    />
                  </View>
                )}
                className="mb-0"
              >
                {allowMultipleUnitsBooking && (
                  <View className="px-4 w-full pb-4 lg:w-1/3 lg:self-start">
                    <Input
                      type="number"
                      label="Max Service Units per Booking"
                      keyboardType="numeric"
                      inputMode="numeric"
                      value={maxServiceUnitValue}
                      onChangeText={onChangeMaxServiceUnitText}
                      onBlur={onBlurMaxServiceUnit}
                      note={maxServiceUnitHasError}
                    />
                  </View>
                )}
              </Card>
            </View>
            <View className="px-4 w-full pt-4">
              <View className="lg:self-end w-full lg:w-60">
                <Button
                  label={props.isEditMode ? "Update Service" : "Add Service"}
                  type="primary"
                  onPress={() => {
                    if (props.isModal) {
                      props.onRequestClose();
                    } else {
                      setGetItemDetails(false);
                    }
                    return onAddServiceItem();
                  }}
                ></Button>
              </View>
            </View>
          </View>
        </ScrollView>
      </Card>
    );
  };

  return (
    <>
      {showConfirmModal.visible && <ConfirmModal {...showConfirmModal} />}
      {showEditServiceModal.visible && (
        <Modal
          animationType="fade"
          transparent={true}
          visible={showEditServiceModal.visible}
          onRequestClose={showEditServiceModal.onRequestClose}
        >
          <View className="flex-1 justify-center items-center bg-black/50 px-8">
            {/* <View className="w-80 lg:w-2/3 h-96 max-h-[60%] bg-app-a border border-app-c rounded-lg justify-center items-center"> */}
            <GetItemDetailsComponent
              onRequestClose={showEditServiceModal.onRequestClose}
              {...showEditServiceModal.data}
            />
            {/* </View> */}
          </View>
        </Modal>
      )}
      <Card
        title="Pricing & bookable services"
        Widget={() => (
          <View className="w-8">
            <Input
              type="toggle"
              value={showBookableServices}
              wait={isShowBookableServicesToggling}
              setValue={() => {
                (async (data) => {
                  setIsShowBookableServicesToggling(true);
                  const token = await isSignedIn();
                  const formData = new FormData();
                  formData.append("enable_services", data);
                  axiosInstance
                    .patch(apiURIV2 + "/listings/" + lid, formData, {
                      headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setShowBookableServices(data);
                      }
                    })
                    .catch((e) => console.log("e.res", e?.response, e))
                    .finally(() => setIsShowBookableServicesToggling(false));
                })(!showBookableServices);
              }}
            />
          </View>
        )}
      >
        <View className="w-full px-4 mb-4">
          <FlatList
            data={Object.keys(bookableServices || {})}
            renderItem={({ item }) => (
              <>
                <View className="w-full bg-app-c/50 rounded relative mb-4 items-center flex-row py-3">
                  <View className="h-2 absolute w-1 bg-app-e rounded-r"></View>
                  <Font
                    className="px-4 w-full text-black leading-5 text-[15px] flex-1 capitalize"
                    numberOfLines={2}
                  >
                    {item}
                  </Font>
                  <Pressable
                    className="mr-4"
                    onPress={() => {
                      setShowConfirmModal((d) => ({
                        ...d,
                        visible: true,
                        BodyComponent: () => (
                          <Font>
                            Do you want to delete All the{" "}
                            <Font className="text-app-f capitalize">
                              {item}
                            </Font>{" "}
                            Category Services?
                          </Font>
                        ),
                        onConfirm: () => {
                          setDeleteServiceIDs((dSIDs) => {
                            bookableServices[item].forEach((v) => {
                              dSIDs[v.id] = { category: item };
                            });
                            return { ...dSIDs };
                          });
                          delete bookableServices[item];
                          setShowConfirmModal((d1) => {
                            d1.visible = false;
                            d1.onConfirm = () => null;
                            d1.onCancel = () => null;
                            d1.cancelText = "Cancel";
                            d1.confirmText = "Confirm";
                            d1.bodyText = "";
                            return { ...d1 };
                          });
                        },
                        onCancel: () =>
                          setShowConfirmModal((d1) => {
                            d1.visible = false;
                            d1.onConfirm = () => null;
                            d1.onCancel = () => null;
                            d1.cancelText = "Cancel";
                            d1.confirmText = "Confirm";
                            d1.bodyText = "";
                            return { ...d1 };
                          }),
                      }));
                    }}
                  >
                    <RemixIcon
                      name="ri-delete-bin-5-line"
                      className="self-start"
                      size={20}
                      color={colors.app.d2}
                    />
                  </Pressable>
                </View>
                <View className="w-full">
                  <FlatList
                    ItemSeparatorComponent={() => {
                      return (
                        <View
                          style={{
                            marginHorizontal: 10,
                          }}
                        />
                      );
                    }}
                    data={bookableServices?.[item]}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    renderItem={({
                      item: { id, title, subtitle, service_unit } = {},
                      index,
                    }) => (
                      <View
                        key={"bs" + index}
                        className={`rounded-md bg-app-c3 border border-app-c1 p-4 mb-4 ${
                          Platform.OS === "web"
                            ? "min-w-[208px] max-w-[328px]"
                            : "min-w-[60vw] w-fit max-w-[83vw]"
                        }`}
                      >
                        <View className="flex-row items-start relative">
                          {/* <View className="h-6 absolute w-1 bg-app-e  rounded-r"></View> */}
                          <Font
                            weight={700}
                            className="font-semibold text-base flex-1"
                            numberOfLines={2}
                          >
                            {title}
                          </Font>
                          <Pressable
                            className="mr-2"
                            onPress={() => {
                              setShowEditServiceModal((d) => {
                                d.visible = true;
                                d.data.category = item;
                                d.data.index = index;
                                return { ...d };
                              });
                            }}
                          >
                            <RemixIcon
                              name="ri-pencil-fill"
                              className="self-start mt-1 ml-2"
                              size={20}
                              color={colors.app.d2}
                            />
                          </Pressable>
                          <Pressable
                            onPress={() => {
                              setShowConfirmModal((d) => ({
                                ...d,
                                visible: true,
                                BodyComponent: () => (
                                  <Font>
                                    Do you want to delete{" "}
                                    <Font className="text-app-f capitalize">
                                      {title}
                                    </Font>{" "}
                                    Service?
                                  </Font>
                                ),
                                onConfirm: () => {
                                  setDeleteServiceIDs((dSIDs) => {
                                    dSIDs[id] = { category: item };
                                    return { ...dSIDs };
                                  });
                                  if (bookableServices[item]?.length === 1) {
                                    setBookableServices((bs) => {
                                      delete bs[item];
                                      return bs;
                                    });
                                  } else {
                                    const i = bookableServices[item].findIndex(
                                      (v) => v.id === id
                                    );
                                    if (i > -1)
                                      setBookableServices((bs) => {
                                        bs[item].splice(i, 1);
                                        return bs;
                                      });
                                  }
                                  setShowConfirmModal((d1) => {
                                    d1.visible = false;
                                    d1.onConfirm = () => null;
                                    d1.onCancel = () => null;
                                    d1.cancelText = "Cancel";
                                    d1.confirmText = "Confirm";
                                    d1.bodyText = "";
                                    return { ...d1 };
                                  });
                                },
                                onCancel: () =>
                                  setShowConfirmModal((d1) => {
                                    d1.visible = false;
                                    d1.onConfirm = () => null;
                                    d1.onCancel = () => null;
                                    d1.cancelText = "Cancel";
                                    d1.confirmText = "Confirm";
                                    d1.bodyText = "";
                                    return { ...d1 };
                                  }),
                              }));
                            }}
                          >
                            <RemixIcon
                              name="ri-delete-bin-5-fill"
                              className="self-start mt-1 ml-1"
                              size={20}
                              color={colors.app.d2}
                            />
                          </Pressable>
                        </View>
                        <Font className="py-1">{subtitle}</Font>
                        <Font weight={600} className="font-semibold capitalize">
                          {`${service_unit.currency_symbol} ${
                            service_unit.price / 100
                          } / ${service_unit.name}`}
                        </Font>
                      </View>
                    )}
                  />
                </View>
              </>
            )}
          />
          <View className="mb-4">
            <View className="flex-row w-full">
              <Pressable
                className="bg-app-a flex-1 lg:flex-none px-2 py-1 rounded-full border border-app-g flex-row justify-start items-center mx-1"
                onPress={() => {
                  setGetItemDetails(true);
                }}
              >
                <RemixIcon
                  name="ri-add-circle-line"
                  className="mr-2"
                  color={colors.app.g}
                ></RemixIcon>
                <Font className="text-app-g leading-4">Add Service</Font>
              </Pressable>
              {/* <Pressable
                className="bg-app-a px-2 py-1 flex-1 lg:flex-none rounded-full border border-app-g flex-row justify-start items-center mx-2"
                onPress={() => {
                  setAddCategory(true);
                  if (getItemDetails) setGetItemDetails(false);
                }}
              >
                <RemixIcon
                  name="ri-add-circle-line"
                  className="mr-2"
                  color={colors.app.g}
                ></RemixIcon>
                <Font className="text-app-g leading-4">Add Category</Font>
              </Pressable> */}
            </View>
          </View>
          {getItemDetails && <GetItemDetailsComponent />}
          {/* {addCategory && (
            <Card
              title="Service Category"
              Widget={() => (
                <Pressable onPress={() => setAddCategory(false)}>
                  <RemixIcon
                    name="ri-close-fill"
                    className=""
                    size={25}
                    color={colors.app.e}
                  />
                </Pressable>
              )}
            >
              <View className="w-full mb-4">
                <View className="mx-4 lg:w-1/3 lg:self-start">
                  <Select
                    mode=""
                    options={serviceCategoriesState.serviceCategories}
                    value={selectedCategory}
                    setValue={(e) => setSelectedCategory(e)}
                    placeholder="Select Category"
                  />
                </View>
                <View className="mx-4 mt-5">
                  <View className="lg:self-end w-full lg:w-60">
                    <Button
                      label="Done"
                      type="primary"
                      onPress={() => setAddCategory(false)}
                    ></Button>
                  </View>
                </View>
              </View>
            </Card>
          )} */}
        </View>
      </Card>
      {/* <Card
      title="Enable Booking"
      Widget={() => (
        <View className="w-8">
          <Input type="toggle"/>
        </View>
      )}
    >
      <View></View>
    </Card> */}
      {/* <Card
      title="Booking Prices & settings"
      Widget={() => (
        <View className="w-8">
          <Input type="toggle"/>
        </View>
      )}
    >
      <View className="flex-row flex-wrap px-2">
        <View className="px-2 w-1/3 mb-4">
          <Input type="text" label="Regular Price"/>
        </View>
        <View className="px-2 w-1/3 mb-4">
          <Input type="text" label="Weekend Price"/>
        </View>
        <View className="px-2 w-1/3 mb-4">
          <Input type="text" label="Special Price"/>
        </View>
        <View className="px-2 w-1/3 mb-4">
          <Input type="text" label="Reservation Fee"/>
        </View>
        <View className="px-2 w-1/3 mb-4">
          <Input
            type="text"
            label="Reservation Expire After"
          />
        </View>

        <View className="px-2 w-1/3 mb-4">
          <Input
            type="toggle"
            label="Enable Instant Booking"
          />
        </View>
        <View className="px-2 w-1/3 mb-4">
          <Input
            type="toggle"
            label="Enable Price Per Guest"
          />
        </View>
        <View className="px-2 w-1/3 mb-4">
          <Input
            type="toggle"
            label="Enable End Hour Time-picker"
          />
        </View>
        <View className="px-2 w-1/3 mb-4">
          <Input
            type="text"
            label="Maximum Number Of Guest"
          />
        </View>
      </View>
    </Card> */}
    </>
  );
};

const ListingSchedule = ({
  available_slots,
  opening_hours,
  bookable_services,
  saveClicked,
  setSaveClicked,
  updateListingDetails,
  resetClicked,
  setResetClicked,
  isEditingForm,
  setIsEditingForm,
  setShowResponseModal,
  enable_opening_hours,
  enable_availability_calendar_slots,
  lid,
}) => {
  const weekDaysForUI = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const [isShowOpeningHoursToggling, setIsShowOpeningHoursToggling] =
    useState(false);
  const [isShowCalendarToggling, setIsShowCalendarToggling] = useState(false);
  const [showOpeningHours, setShowOpeningHours] = useState();
  const [openingHoursData, setOpeningHoursData] = useState();
  const [openingHours, setOpeningHours] = useState({
    delete: null,
    update: null,
    add: null,
  });

  const [showCalendarAvailabilitySlots, setShowCalendarAvailabilitySlots] =
    useState();
  const [availableSlotsData, setAvailableSlotsData] = useState();
  const [availableSlots, setAvailableSlots] = useState({
    delete: null,
    update: null,
    updateServices: null,
    add: null,
  });

  const [availabilityServices, setAvailabilityServices] = useState();

  const [showCalendarAvailabilityModal, setShowCalendarAvailabilityModal] =
    useState({
      visible: false,
      onRequestClose: () => null,
      slots: [],
      isExpired: null,
      year: null,
      month: null,
      date: null,
      weekday: null,
    });

  const [calendarSlots, setCalendarSlots] = useState();
  const [calendarSlotsUpdateData, setCalendarSlotsUpdateData] = useState({
    added: [],
    updated: [],
    removed: [],
  });
  const [calendarOperationInProgress, setCalendarOperationInProgress] =
    useState();

  const [cSIsLoading, setCSIsLoading] = useState();

  if (![true, false].includes(showOpeningHours)) {
    setShowOpeningHours(enable_opening_hours);
  }

  if (![true, false].includes(showCalendarAvailabilitySlots)) {
    setShowCalendarAvailabilitySlots(enable_availability_calendar_slots);
  }

  const getCalendarSlots = async () => {
    try {
      setCSIsLoading(true);
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/listings/" + lid + "/calendar",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setCalendarSlots(res.data?.data);
      }
    } catch (e) {
      console.log("Get Calendar Error:", e);
    } finally {
      setCSIsLoading(false);
    }
  };

  if (!calendarSlots && !cSIsLoading) {
    getCalendarSlots();
  }

  React.useEffect(() => {
    if (!openingHoursData && opening_hours) {
      setOpeningHoursData(opening_hours);
    }
  }, [opening_hours, openingHoursData]);

  React.useEffect(() => {
    if (!availableSlotsData && available_slots) {
      setAvailableSlotsData(available_slots);
    }
  }, [available_slots, availableSlotsData]);

  // const { listingDetailsData, setListingDetailsData } = useListingContext();

  React.useEffect(() => {
    if (!availabilityServices) {
      const availability_services = [];
      if (bookable_services) {
        for (const key in bookable_services) {
          if (Array.isArray(bookable_services[key])) {
            bookable_services[key].forEach((d) => {
              availability_services.push({ label: d.title, value: d.id });
            });
          }
        }
        setAvailabilityServices(availability_services);
      }
    }
  }, [bookable_services, availabilityServices]);

  React.useMemo(() => {
    if (
      openingHours?.add ||
      openingHours?.delete ||
      openingHours?.update ||
      availableSlots?.add ||
      availableSlots?.update ||
      availableSlots?.updateServices ||
      availableSlots?.delete
    ) {
      setIsEditingForm(true);
    } else {
      setIsEditingForm(false);
    }
  }, [
    setIsEditingForm,
    openingHours?.add,
    openingHours?.delete,
    openingHours?.update,
    availableSlots?.add,
    availableSlots?.update,
    availableSlots?.updateServices,
    availableSlots?.delete,
  ]);

  const resetOpeningHours = ({
    resetAddedOpeningHours,
    resetUpdatedOpeningHours,
    resetDeletedOpeningHours,
  }) => {
    if (resetAddedOpeningHours) {
      setOpeningHours((openingHours) => ({
        ...openingHours,
        add: null,
      }));
    }
    if (resetUpdatedOpeningHours) {
      setOpeningHours((openingHours) => ({
        ...openingHours,
        update: null,
      }));
    }
    if (resetDeletedOpeningHours) {
      setOpeningHours((openingHours) => ({
        ...openingHours,
        delete: null,
      }));
    }
  };
  const resetAvailableSlots = ({
    resetAddedAvailableSlots,
    resetUpdatedAvailableSlots,
    resetDeletedAvailableSlots,
  }) => {
    if (resetAddedAvailableSlots) {
      // setAvailableSlotsData((data) => {
      //   for (const key in availableSlots.add) {
      //     availableSlots.add[key].forEach((v) => {
      //       const i = data[key].findIndex((d) => d.id === v.id);
      //       if (i > -1) {
      //         data[key].splice(i, 1);
      //       }
      //     });
      //   }
      //   return { ...data };
      // });
      setAvailableSlots((availableSlots) => ({
        ...availableSlots,
        add: null,
      }));
    }
    if (resetUpdatedAvailableSlots) {
      // setAvailableSlotsData((data) => {
      //   for (const key in availableSlots.update) {
      //     for (const key1 in availableSlots.update[key]) {
      //       const i = data[key].findIndex((v) => v.id === key1);
      //       if (i > -1) {
      //         const i1 = available_slots[key].findIndex((v) => v.id === key1);
      //         if (i1 > -1) {
      //           data[key][i] = available_slots[key][i1];
      //         }
      //       }
      //     }
      //   }
      //   return { ...data };
      // });
      setAvailableSlots((availableSlots) => ({
        ...availableSlots,
        update: null,
        updateServices: null,
      }));
    }
    if (resetDeletedAvailableSlots) {
      setAvailableSlots((availableSlots) => ({
        ...availableSlots,
        delete: null,
      }));
    }
  };

  // React.useEffect(() => {
  //   if (resetClicked) {
  //     setResetClicked((resetClicked) => !resetClicked);
  //     resetOpeningHours({
  //       resetAddedOpeningHours: true,
  //       resetUpdatedOpeningHours: true,
  //       resetDeletedOpeningHours: true,
  //     });
  //     resetAvailableSlots({
  //       resetAddedAvailableSlots: true,
  //       resetUpdatedAvailableSlots: true,
  //       resetDeletedAvailableSlots: true,
  //     });
  //   }
  // }, [resetClicked, setResetClicked]);

  const addOpeningHours = async ({ weekDay, bodyData }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.post(
        apiURIV2 + "/listings/" + lid + "/opening-hours/slots",
        { [weekDay]: bodyData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 201) {
        data = res.data?.data;
        if (data) {
          data[weekDay] = parseSlotsArray(data[weekDay]);
        }
        updateListingDetails((ldd) => {
          if (!ldd.opening_hours) {
            ldd.opening_hours = {};
          }
          ldd.opening_hours[weekDay] = [
            ...(ldd.opening_hours?.[weekDay] || []),
            ...(res.data?.data?.[weekDay] || []),
          ];
          setOpeningHoursData(ldd.opening_hours);
          return { ...ldd };
        });
        setOpeningHours((oH) => {
          delete oH.add[weekDay];
          return { ...oH };
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setOpeningHoursData(opening_hours);
    }
  };

  const updateOpeningHours = async ({
    weekDay,
    slot_id,
    start_in_min,
    end_in_min,
  }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.patch(
        apiURIV2 + "/listings/" + lid + "/opening-hours/slots/" + slot_id,
        {
          start_in_min,
          end_in_min,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );
      if (res.status) {
        data = res.data;
        updateListingDetails((ldd) => {
          const i = ldd.opening_hours[weekDay]?.findIndex(
            (v) => v.id === slot_id
          );
          if (i > -1) {
            ldd.opening_hours[weekDay][i] = {
              ...(ldd?.opening_hours?.[weekDay]?.[i] || {}),
              start_in_min,
              end_in_min,
            };
          }
          setOpeningHoursData(ldd.opening_hours);
          return { ...ldd };
        });
        setOpeningHours((oH) => {
          delete oH.update[weekDay][slot_id];
          return { ...oH };
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setOpeningHoursData(opening_hours);
    }
  };

  const deleteOpeningHours = async ({ slot_id, weekDay }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.delete(
        apiURIV2 + "/listings/" + lid + "/opening-hours/slots/" + slot_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status) {
        data = res.data;
        updateListingDetails((ldd) => {
          const i = ldd.opening_hours[weekDay]?.findIndex((v) => {
            return v.id == slot_id;
          });
          if (i > -1) {
            ldd.opening_hours[weekDay].splice(i, 1);
          }
          setOpeningHoursData(ldd.opening_hours);
          return { ...ldd };
        });
        setOpeningHours((oH) => {
          delete oH.delete[slot_id];
          return { ...oH };
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setOpeningHoursData(opening_hours);
    }
  };

  const addAvailabilitySlots = async ({ weekDay, bodyData }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.post(
        apiURIV2 +
          "/listings/" +
          lid +
          "/services/" +
          bodyData.service_id +
          "/availability/slots",
        { [weekDay]: [bodyData] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 201) {
        data = res.data?.data?.availability_slots?.[weekDay];
        const calendar_slots = res.data?.data?.calendar_slots;
        for (const k in calendar_slots) {
          const dateObj = new Date(k);
          const year = dateObj.getFullYear(),
            month = monthsWithDateIndex[dateObj.getMonth()],
            date = dateObj.getDate();
          setCalendarSlots((cs) => {
            cs = cloneDeep(cs);
            if (!cs?.[year]) {
              cs[year] = {};
            }
            if (!cs[year]?.[month]) {
              cs[year][month] = {};
            }
            if (!cs[year][month]?.[date]) {
              cs[year][month][date] = [];
            }
            cs[year][month][date].push(...(calendar_slots[k] || []));
            return cs;
          });
        }
        data[0] = parseSlot(data[0]);
        updateListingDetails((ldd) => {
          if (!ldd.available_slots) {
            ldd.available_slots = {};
          }
          ldd.available_slots[weekDay] = [
            ...(ldd?.available_slots?.[weekDay] || []),
            ...(data || []), // nested object with unexpected name availability_slots
          ];
          setAvailableSlotsData(ldd.available_slots);
          return ldd;
        });
        setAvailableSlots((aS) => {
          aS.add[weekDay] = aS.add[weekDay].filter((d) => d.id !== bodyData.id);
          return { ...aS };
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setAvailableSlotsData(available_slots);
    }
  };

  const updateAvailabilitySlots = async ({
    weekDay,
    slot_id,
    // start_in_min,
    // end_in_min,
    // service_unit_capacity,
    service_id,
    old_service_id,
  }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.patch(
        apiURIV2 +
          "/listings/" +
          lid +
          "/services/" +
          old_service_id +
          "/availability/slots/" +
          slot_id,
        {
          // start_in_min,
          // end_in_min,
          // service_unit_capacity,
          service_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        data = res.data;
        updateListingDetails((ldd) => {
          const i = ldd.available_slots[weekDay]?.findIndex(
            (v) => v.id === slot_id
          );
          if (i > -1) {
            ldd.available_slots[weekDay][i] = {
              ...(ldd?.available_slots?.[weekDay]?.[i] || {}),
              // start_in_min,
              // end_in_min,
              // service_unit_capacity,
              service_id,
            };
          }
          setAvailableSlotsData(ldd.available_slots);
          return { ...ldd };
        });
        setAvailableSlots((aS) => {
          delete aS.updateServices[weekDay][slot_id];
          return { ...aS };
        });
        getCalendarSlots();
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setAvailableSlotsData(available_slots);
    }
  };

  const bulkUpdateAvailabilitySlots = async (slotsData) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.patch(
        apiURIV2 + "/listings/" + lid + "/availability/slots",
        slotsData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        data = res.data;
        updateListingDetails((ldd) => {
          for (let key in slotsData) {
            slotsData[key].forEach((slot) => {
              const i = ldd.available_slots[key]?.findIndex(
                (v) => v.id === slot.id
              );
              if (i > -1) {
                ldd.available_slots[key][i] = {
                  ...(ldd?.available_slots?.[key]?.[i] || {}),
                  slot,
                };
              }
            });
          }
          setAvailableSlotsData(ldd.available_slots);
          return { ...ldd };
        });
        setAvailableSlots((aS) => {
          aS.update = null;
          return { ...aS };
        });
        getCalendarSlots();
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setAvailableSlotsData(available_slots);
    }
  };

  const deleteAvailabilitySlots = async ({ slot_id, service_id, weekDay }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.delete(
        apiURIV2 +
          "/listings/" +
          lid +
          "/services/" +
          service_id +
          "/availability/slots/" +
          slot_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 204) {
        data = res.data;
        updateListingDetails((ldd) => {
          const i = ldd.available_slots[weekDay]?.findIndex(
            (v) => v.id === slot_id
          );
          if (i > -1) {
            ldd.available_slots[weekDay].splice(i, 1);
          }
          setAvailableSlotsData(ldd.available_slots);
          return { ...ldd };
        });
        setAvailableSlots((aS) => {
          const i = aS.delete[weekDay].findIndex((v) => v.id === slot_id);
          if (i > -1) {
            aS.delete[weekDay].splice(i, 1);
          }
          return { ...aS };
        });
      }
    } catch (e) {
      console.log("lad e.res", e?.response, e);
      setAvailableSlotsData(available_slots);
    }
  };

  if (saveClicked) {
    setSaveClicked(false);
    if (Object.propertyIsEnumerable.call(openingHours, "add")) {
      let promiseArr = [];
      for (const key in openingHours.add) {
        promiseArr.push(
          addOpeningHours({
            weekDay: key,
            bodyData: openingHours.add[key],
          })
        );
      }
      Promise.all(promiseArr)
        .then((values) => {
          resetOpeningHours({
            resetAddedOpeningHours: true,
          });
          setShowResponseModal({
            visible: true,
            bodyText: "Listing Successfully Updated",
            onRequestClose: () =>
              setShowResponseModal({
                visible: false,
                bodyText: "",
                onRequestClose: () => null,
              }),
          });
        })
        .catch((e) => {
          e?.forEach((v, i) => {
            const msg = v?.response?.data?.data?.message;
            if (msg)
              setShowResponseModal({
                visible: true,
                bodyText: v?.response?.data?.data?.message,
                onRequestClose: () =>
                  setShowResponseModal({
                    visible: false,
                    bodyText: "",
                    onRequestClose: () => null,
                  }),
              });
          });
        });
    }
    if (Object.propertyIsEnumerable.call(openingHours, "update")) {
      for (const key in openingHours.update) {
        let promiseArr = [];
        for (const key1 in openingHours.update[key]) {
          promiseArr.push(
            updateOpeningHours({
              weekDay: key,
              slot_id: key1,
              start_in_min: openingHours.update[key][key1].start_in_min,
              end_in_min: openingHours.update[key][key1].end_in_min,
            })
          );
        }
        Promise.all(promiseArr)
          .then((values) => {
            resetOpeningHours({
              resetUpdatedOpeningHours: true,
            });
            setShowResponseModal({
              visible: true,
              bodyText: "Listing Successfully Updated",
              onRequestClose: () =>
                setShowResponseModal({
                  visible: false,
                  bodyText: "",
                  onRequestClose: () => null,
                }),
            });
          })
          .catch((e) => {
            e?.forEach((v, i) => {
              const msg = v?.response?.data?.data?.message;
              if (msg)
                setShowResponseModal({
                  visible: true,
                  bodyText: v?.response?.data?.data?.message,
                  onRequestClose: () =>
                    setShowResponseModal({
                      visible: false,
                      bodyText: "",
                      onRequestClose: () => null,
                    }),
                });
            });
          });
      }
    }
    if (Object.propertyIsEnumerable.call(openingHours, "delete")) {
      let promiseArr = [];
      for (const key in openingHours.delete) {
        openingHours.delete[key].forEach((v) =>
          promiseArr.push(deleteOpeningHours({ slot_id: v.id, weekDay: key }))
        );
        Promise.all(promiseArr)
          .then((values) => {
            resetOpeningHours({
              resetDeletedOpeningHours: true,
            });
            setShowResponseModal({
              visible: true,
              bodyText: "Listing Successfully Updated",
              onRequestClose: () =>
                setShowResponseModal({
                  visible: false,
                  bodyText: "",
                  onRequestClose: () => null,
                }),
            });
          })
          .catch((e) => {
            e?.forEach((v, i) => {
              const msg = v?.response?.data?.data?.message;
              if (msg)
                setShowResponseModal({
                  visible: true,
                  bodyText: v?.response?.data?.data?.message,
                  onRequestClose: () =>
                    setShowResponseModal({
                      visible: false,
                      bodyText: "",
                      onRequestClose: () => null,
                    }),
                });
            });
          });
      }
    }
    if (Object.propertyIsEnumerable.call(availableSlots, "add")) {
      let promiseArr = [];
      for (const key in availableSlots.add) {
        availableSlots.add[key].forEach((d) => {
          promiseArr.push(
            addAvailabilitySlots({
              weekDay: key,
              bodyData: d,
              service_id: d.service_id,
            })
          );
        });
      }
      Promise.all(promiseArr)
        .then((values) => {
          resetAvailableSlots({
            resetAddedAvailableSlots: true,
          });
          setShowResponseModal({
            visible: true,
            bodyText: "Listing Successfully Updated",
            onRequestClose: () =>
              setShowResponseModal({
                visible: false,
                bodyText: "",
                onRequestClose: () => null,
              }),
          });
        })
        .catch((e) => {
          e?.forEach((v, i) => {
            const msg = v?.response?.data?.data?.message;
            if (msg)
              setShowResponseModal({
                visible: true,
                bodyText: v?.response?.data?.data?.message,
                onRequestClose: () =>
                  setShowResponseModal({
                    visible: false,
                    bodyText: "",
                    onRequestClose: () => null,
                  }),
              });
          });
        });
    }
    let bulkUpdatePromiseArray = [],
      updateServicesPromissArray = [];
    if (Object.keys(availableSlots?.update || {})?.length) {
      bulkUpdatePromiseArray.push(
        bulkUpdateAvailabilitySlots(availableSlots.update)
      );
    }
    if (Object.keys(availableSlots.updateServices || {})?.length) {
      for (const key in availableSlots.updateServices) {
        availableSlots.updateServices[key].forEach((v) => {
          updateServicesPromissArray.push(
            updateAvailabilitySlots({
              weekDay: key,
              ...v,
              slot_id: v.id,
              old_service_id: v.old_service_id,
            })
          );
        });
      }
    }
    if (bulkUpdatePromiseArray?.length) {
      Promise.all(bulkUpdatePromiseArray)
        .then((values) =>
          updateServicesPromissArray?.length
            ? Promise.all(updateServicesPromissArray)
            : values
        )
        .then((values) => {
          resetAvailableSlots({
            resetUpdatedAvailableSlots: true,
          });
          setShowResponseModal({
            visible: true,
            bodyText: "Listing Successfully Updated",
            onRequestClose: () =>
              setShowResponseModal({
                visible: false,
                bodyText: "",
                onRequestClose: () => null,
              }),
          });
        })
        .catch((e) => {
          e?.forEach((v, i) => {
            const msg = v?.response?.data?.data?.message;
            if (msg)
              setShowResponseModal({
                visible: true,
                bodyText: v?.response?.data?.data?.message,
                onRequestClose: () =>
                  setShowResponseModal({
                    visible: false,
                    bodyText: "",
                    onRequestClose: () => null,
                  }),
              });
          });
        });
    }
    if (updateServicesPromissArray?.length) {
      Promise.all(updateServicesPromissArray)
        .then((values) => {
          resetAvailableSlots({
            resetUpdatedAvailableSlots: true,
          });
          setShowResponseModal({
            visible: true,
            bodyText: "Listing Successfully Updated",
            onRequestClose: () =>
              setShowResponseModal({
                visible: false,
                bodyText: "",
                onRequestClose: () => null,
              }),
          });
        })
        .catch((e) => {
          e?.forEach((v, i) => {
            const msg = v?.response?.data?.data?.message;
            if (msg)
              setShowResponseModal({
                visible: true,
                bodyText: v?.response?.data?.data?.message,
                onRequestClose: () =>
                  setShowResponseModal({
                    visible: false,
                    bodyText: "",
                    onRequestClose: () => null,
                  }),
              });
          });
        });
    }
    if (Object.propertyIsEnumerable.call(availableSlots, "delete")) {
      let promiseArr = [];
      for (const key in availableSlots.delete) {
        availableSlots.delete[key].forEach((v) =>
          promiseArr.push(
            deleteAvailabilitySlots({
              slot_id: v.id,
              service_id: v.service_id,
              weekDay: key,
            })
          )
        );
      }
      Promise.all(promiseArr)
        .then((values) => {
          resetAvailableSlots({
            resetDeletedAvailableSlots: true,
          });
          setShowResponseModal({
            visible: true,
            bodyText: "Listing Successfully Updated",
            onRequestClose: () =>
              setShowResponseModal({
                visible: false,
                bodyText: "",
                onRequestClose: () => null,
              }),
          });
        })
        .catch((e) => {
          e?.forEach((v, i) => {
            const msg = v?.response?.data?.data?.message;
            if (msg)
              setShowResponseModal({
                visible: true,
                bodyText: v?.response?.data?.data?.message,
                onRequestClose: () =>
                  setShowResponseModal({
                    visible: false,
                    bodyText: "",
                    onRequestClose: () => null,
                  }),
              });
          });
        });
    }
  }

  const deleteCalendarSlots = async ({
    slot_id,
    service_id,
    year,
    month,
    date,
    callBack,
  }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.delete(
        apiURIV2 +
          "/listings/" +
          lid +
          "/services/" +
          service_id +
          "/calendar/slots/" +
          slot_id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 204) {
        setCalendarSlots((cals) => {
          cals = cloneDeep(cals);
          cals[year][month][date] = cals?.[year]?.[month]?.[date]?.filter(
            (v) => v.id != slot_id
          );
          return cals;
        });
      }
    } catch (e) {
      console.log("lad e.res", e?.response, e);
    } finally {
      callBack();
    }
  };

  const updateCalendarSlots = async ({
    date,
    month,
    year,
    bodyData,
    old_service_id,
    callBack,
  }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.patch(
        apiURIV2 +
          "/listings/" +
          lid +
          "/services/" +
          (old_service_id || bodyData.service_id) +
          "/calendar/slots/" +
          bodyData.id,
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        data = res.data?.data;
        if (data) {
          if (!data.service_id) {
            data.service_id = bodyData.service_id;
          }
          setCalendarSlots((cals) => {
            if (cals) {
              cals = cloneDeep(cals);
            } else {
              cals = {};
            }
            if (!cals?.[year]) {
              cals[year] = {};
            }
            if (!cals[year]?.[month]) {
              cals[year][month] = {};
            }
            if (!cals[year][month]?.[date]) {
              cals[year][month][date] = [];
            }
            const i = cals[year][month][date].findIndex((v) => v.id == data.id);
            if (i > -1) {
              cals[year][month][date][i] = data;
            }
            return cals;
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
    } finally {
      callBack();
    }
  };

  const addCalendarSlots = async ({
    date,
    month,
    year,
    bodyData,
    callBack,
  }) => {
    let data;
    const token = await isSignedIn();
    try {
      const res = await axiosInstance.post(
        apiURIV2 +
          "/listings/" +
          lid +
          "/services/" +
          bodyData.service_id +
          "/calendar/slots",
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 201) {
        data = res.data?.data;
        if (data) {
          if (!data.service_id) {
            data.service_id = bodyData.service_id;
          }
          setCalendarSlots((cals) => {
            if (cals) {
              cals = cloneDeep(cals);
            } else {
              cals = {};
            }
            if (!cals?.[year]) {
              cals[year] = {};
            }
            if (!cals[year]?.[month]) {
              cals[year][month] = {};
            }
            if (!cals[year][month]?.[date]) {
              cals[year][month][date] = [];
            }
            cals[year][month][date].push(data);
            return cals;
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
    } finally {
      callBack();
    }
  };

  const onCalendarSlotModalPress = () => {
    const promiseArray = [];
    if (calendarSlotsUpdateData?.removed?.length) {
      promiseArray.push(
        calendarSlotsUpdateData?.removed.map((v) =>
          deleteCalendarSlots({
            slot_id: v.id,
            service_id: v.service_id,
            callBack: () => null,
            date: showCalendarAvailabilityModal.date,
            month: showCalendarAvailabilityModal.month,
            year: showCalendarAvailabilityModal.year,
          })
        )
      );
    } else if (calendarSlotsUpdateData?.updated?.length) {
      promiseArray.push(
        calendarSlotsUpdateData?.updated.map((v) => {
          updateCalendarSlots({
            callBack: () => null,
            date: showCalendarAvailabilityModal.date,
            month: showCalendarAvailabilityModal.month,
            year: showCalendarAvailabilityModal.year,
            bodyData: v,
            old_service_id: v?.old_service_id,
          });
        })
      );
    } else if (calendarSlotsUpdateData?.added?.length) {
      promiseArray.push(
        calendarSlotsUpdateData?.added.map((v) => {
          addCalendarSlots({
            callBack: () => null,
            date: showCalendarAvailabilityModal.date,
            month: showCalendarAvailabilityModal.month,
            year: showCalendarAvailabilityModal.year,
            bodyData: v,
          });
        })
      );
    } else {
      showCalendarAvailabilityModal.onRequestClose();
    }
    if (promiseArray?.length) {
      setCalendarOperationInProgress(true);
      axios
        .all(promiseArray)
        .then(() => {
          showCalendarAvailabilityModal.onRequestClose();
        })
        .finally(() => {
          setCalendarOperationInProgress(false);
        });
    }
  };

  return (
    <>
      {showCalendarAvailabilityModal.visible && (
        <Modal
          animationType="fade"
          transparent={true}
          visible={showCalendarAvailabilityModal.visible}
          onRequestClose={showCalendarAvailabilityModal.onRequestClose}
        >
          {/* <TouchableWithoutFeedback
            onPress={() =>
              setShowCalendarAvailabilityModal({
                visible: false,
                onRequestClose: () => null,
                slots: [],
              })
            }
          > */}
          <View className="flex-1 justify-center items-center bg-black/50 px-4">
            <View
              // className={`w-full md:w-2/4 lg:w-1/3 xs:px-4 ${
              //   Platform.OS === "web" ? "h-[55%] lg:h-10" : "h-[55%] -mt-16"
              // }`}
              className="w-full md:w-2/4 lg:w-1/3 px-4 lg:"
            >
              <Card
                title="Availability Slots"
                Widget={
                  showCalendarAvailabilityModal.isExpired
                    ? null
                    : () => (
                        <Pressable
                          className="active:bg-app-g/10 rounded p-1 -m-1 justify-center items-center"
                          onPress={() => {
                            const id = Date.now() + "-local",
                              year = showCalendarAvailabilityModal.year,
                              month =
                                monthsWithDateIndex.indexOf(
                                  showCalendarAvailabilityModal.month
                                ) + 1,
                              date = showCalendarAvailabilityModal.date,
                              data = {
                                id: id,
                                start_in_min: {
                                  h: "09",
                                  m: "00",
                                  tim: 540,
                                },
                                end_in_min: {
                                  h: "21",
                                  m: "00",
                                  tim: 1260,
                                },
                                service_unit_capacity: 1,
                                service_id: "",
                                date_time_stamp: `${year}-${
                                  month < 10 ? "0" + month : month
                                }-${date < 10 ? "0" + date : date}`,
                              };
                            setShowCalendarAvailabilityModal((d) => {
                              d = cloneDeep(d);
                              if (d.slots?.length) {
                                d.slots.unshift(data);
                              } else {
                                d.slots = [data];
                              }
                              return d;
                            });
                            setCalendarSlotsUpdateData((cals) => {
                              let data1 = cloneDeep(data);
                              data1.start_in_min = 540;
                              data1.end_in_min = 1260;
                              if (!cals.added) {
                                cals.added = [];
                              }
                              cals.added.push(data1);
                              return cals;
                            });
                          }}
                        >
                          <RemixIcon
                            name="ri-add-circle-line"
                            className="-my-1"
                            size={25}
                            color={colors.app.g}
                          />
                        </Pressable>
                      )
                }
              >
                <FlatList
                  key={showCalendarAvailabilityModal.slots?.length}
                  className={`w-max h-40 ${
                    Platform.OS === "web" ? "h-[408px] lg:h-96" : "h-[70%]"
                  }`}
                  data={showCalendarAvailabilityModal.slots}
                  extraData={showCalendarAvailabilityModal.slots}
                  ListEmptyComponent={() => (
                    <View className="flex-1 w-full h-full justify-center items-center">
                      <Font>Not Available</Font>
                    </View>
                  )}
                  ItemSeparatorComponent={() => (
                    <View style={{ marginVertical: 10 }} />
                  )}
                  contentContainerStyle={{ paddingHorizontal: 16 }}
                  renderItem={({ item, index }) => {
                    const slot = parseSlot(cloneDeep(item));
                    const isExpired = showCalendarAvailabilityModal.isExpired;
                    return (
                      <AvailabilitySlotCard
                        slotStartHour={slot.start_in_min.h}
                        onSlotStartHourChange={
                          isExpired
                            ? null
                            : (e) => {
                                let intValue = parseInt(e || 0);
                                if (intValue < 0) {
                                  e = "00";
                                  intValue = 0;
                                } else if (intValue > 23) {
                                  e = "23";
                                  intValue = 23;
                                } else if (isNaN(e)) {
                                  e = e !== "" ? "00" : e;
                                  intValue = 0;
                                }
                                const exstMins = parseInt(
                                  slot.start_in_min.m || 0
                                );
                                setShowCalendarAvailabilityModal((data) => {
                                  data = cloneDeep(data);
                                  data.slots[index].start_in_min = {
                                    h: e,
                                    m:
                                      exstMins < 10 ? "0" + exstMins : exstMins,
                                    tim: intValue * 60 + exstMins,
                                  };
                                  return data;
                                });
                                setCalendarSlotsUpdateData((cals) => {
                                  if ((item.id + "").includes("-local")) {
                                    const i = cals.added.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    cals.added[i].start_in_min =
                                      intValue * 60 + exstMins;
                                  } else {
                                    if (!cals.updated) {
                                      cals.updated = [];
                                    }
                                    const i = cals.updated.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    if (i > -1) {
                                      cals.updated[i].start_in_min =
                                        intValue * 60 + exstMins;
                                    } else {
                                      const data1 = cloneDeep(item);
                                      data1.start_in_min =
                                        intValue * 60 + exstMins;
                                      cals.updated.push(data1);
                                    }
                                  }
                                  return cals;
                                });
                              }
                        }
                        slotStartMinute={slot.start_in_min.m}
                        onSlotStartMinuteChange={
                          isExpired
                            ? null
                            : (e) => {
                                let intValue = parseInt(e || 0);
                                if (intValue < 0) {
                                  e = "00";
                                  intValue = 0;
                                } else if (intValue > 59) {
                                  e = "59";
                                  intValue = parseInt(59);
                                } else if (isNaN(e)) {
                                  e = e !== "" ? "00" : e;
                                  intValue = 0;
                                }
                                const exstHrs = parseInt(
                                  slot.start_in_min.h || 0
                                );
                                setShowCalendarAvailabilityModal((data) => {
                                  data = cloneDeep(data);
                                  data.slots[index].start_in_min = {
                                    h: exstHrs < 10 ? "0" + exstHrs : exstHrs,
                                    m: e,
                                    tim: intValue + exstHrs * 60,
                                  };
                                  return data;
                                });
                                setCalendarSlotsUpdateData((cals) => {
                                  if ((item.id + "").includes("-local")) {
                                    const i = cals.added.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    cals.added[i].start_in_min =
                                      intValue + exstHrs * 60;
                                  } else {
                                    if (!cals.updated) {
                                      cals.updated = [];
                                    }
                                    const i = cals.updated.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    if (i > -1) {
                                      cals.updated[i].start_in_min =
                                        intValue + exstHrs * 60;
                                    } else {
                                      const data1 = cloneDeep(item);
                                      data1.start_in_min =
                                        intValue + exstHrs * 60;
                                      cals.updated.push(data1);
                                    }
                                  }
                                  return cals;
                                });
                              }
                        }
                        slotEndHour={slot.end_in_min.h}
                        onSlotEndHourChange={
                          isExpired
                            ? null
                            : (e) => {
                                let intValue = parseInt(e || 0);
                                if (intValue < 0) {
                                  e = "00";
                                  intValue = 0;
                                } else if (intValue > 23) {
                                  e = "23";
                                  intValue = 23;
                                } else if (isNaN(e)) {
                                  e = e !== "" ? "00" : e;
                                  intValue = 0;
                                }
                                const exstMins = parseInt(
                                  slot.end_in_min.m || 0
                                );
                                setShowCalendarAvailabilityModal((data) => {
                                  data = cloneDeep(data);
                                  data.slots[index].end_in_min = {
                                    h: e,
                                    m:
                                      exstMins < 10 ? "0" + exstMins : exstMins,
                                    tim: intValue * 60 + exstMins,
                                  };
                                  return data;
                                });
                                setCalendarSlotsUpdateData((cals) => {
                                  if ((item.id + "").includes("-local")) {
                                    const i = cals.added.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    cals.added[i].end_in_min =
                                      intValue * 60 + exstMins;
                                  } else {
                                    if (!cals.updated) {
                                      cals.updated = [];
                                    }
                                    const i = cals.updated.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    if (i > -1) {
                                      cals.updated[i].end_in_min =
                                        intValue * 60 + exstMins;
                                    } else {
                                      const data1 = cloneDeep(item);
                                      data1.end_in_min =
                                        intValue * 60 + exstMins;
                                      cals.updated.push(data1);
                                    }
                                  }
                                  return cals;
                                });
                              }
                        }
                        slotEndMinute={slot.end_in_min.m}
                        onSlotEndMinuteChange={
                          isExpired
                            ? null
                            : (e) => {
                                let intValue = parseInt(e || 0);
                                if (intValue < 0) {
                                  e = "00";
                                  intValue = 0;
                                } else if (intValue > 59) {
                                  e = "59";
                                  intValue = parseInt(59);
                                } else if (isNaN(e)) {
                                  e = e !== "" ? "00" : e;
                                  intValue = 0;
                                }
                                const exstHrs = parseInt(
                                  slot.end_in_min.h || 0
                                );
                                setShowCalendarAvailabilityModal((data) => {
                                  data = cloneDeep(data);
                                  data.slots[index].end_in_min = {
                                    h: exstHrs < 10 ? "0" + exstHrs : exstHrs,
                                    m: e,
                                    tim: intValue + exstHrs * 60,
                                  };
                                  return data;
                                });
                                setCalendarSlotsUpdateData((cals) => {
                                  if ((item.id + "").includes("-local")) {
                                    const i = cals.added.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    cals.added[i].end_in_min =
                                      intValue + exstHrs * 60;
                                  } else {
                                    if (!cals.updated) {
                                      cals.updated = [];
                                    }
                                    const i = cals.updated.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    if (i > -1) {
                                      cals.updated[i].end_in_min =
                                        intValue + exstHrs * 60;
                                    } else {
                                      const data1 = cloneDeep(item);
                                      data1.end_in_min =
                                        intValue + exstHrs * 60;
                                      cals.updated.push(data1);
                                    }
                                  }
                                  return cals;
                                });
                              }
                        }
                        services={availabilityServices}
                        serviceValue={slot?.new_service_id || slot.service_id}
                        servicesEnabled={(slot.id + "").includes("-local")}
                        onServiceSelect={
                          isExpired
                            ? null
                            : (e) => {
                                const old_service_id = slot.service_id;
                                const intValue = parseInt(e);
                                setShowCalendarAvailabilityModal((data) => {
                                  data = cloneDeep(data);
                                  data.slots[index].new_service_id = intValue;
                                  return data;
                                });
                                setCalendarSlotsUpdateData((cals) => {
                                  if ((item.id + "").includes("-local")) {
                                    const i = cals.added.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    cals.added[i].service_id = intValue;
                                  } else {
                                    if (!cals.updated) {
                                      cals.updated = [];
                                    }
                                    const i = cals.updated.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    if (i > -1) {
                                      cals.updated[i].service_id = intValue;
                                      cals.updated[i].old_service_id =
                                        old_service_id;
                                    } else {
                                      const data1 = cloneDeep(item);
                                      data1.service_id = intValue;
                                      data1.old_service_id = old_service_id;
                                      cals.updated.push(data1);
                                    }
                                  }
                                  return cals;
                                });
                              }
                        }
                        counterValue={slot.service_unit_capacity}
                        onCounterValueChange={
                          isExpired
                            ? null
                            : (e) => {
                                const intValue = parseInt(
                                  e(slot.service_unit_capacity)
                                );
                                setShowCalendarAvailabilityModal((data) => {
                                  data = cloneDeep(data);
                                  data.slots[index].service_unit_capacity =
                                    intValue;
                                  return data;
                                });
                                setCalendarSlotsUpdateData((cals) => {
                                  if ((item.id + "").includes("-local")) {
                                    const i = cals.added.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    cals.added[i].service_unit_capacity =
                                      intValue;
                                  } else {
                                    if (!cals.updated) {
                                      cals.updated = [];
                                    }
                                    const i = cals.updated.findIndex(
                                      (d) => d.id == item.id
                                    );
                                    if (i > -1) {
                                      cals.updated[i].service_unit_capacity =
                                        intValue;
                                    } else {
                                      const data1 = cloneDeep(item);
                                      data1.service_unit_capacity = intValue;
                                      cals.updated.push(data1);
                                    }
                                  }
                                  return cals;
                                });
                              }
                        }
                        onRemoveSlot={
                          isExpired
                            ? null
                            : () => {
                                let delData;
                                setShowCalendarAvailabilityModal((data) => {
                                  data = cloneDeep(data);
                                  [delData] = data.slots.splice(index, 1);
                                  return data;
                                });
                                setCalendarSlotsUpdateData((cals) => {
                                  if ((item.id + "").includes("-local")) {
                                    const i = cals.added.findIndex(
                                      (d) => d.id !== item.id
                                    );
                                    cals.added.splice(i, 1);
                                  } else {
                                    if (!cals.removed) {
                                      cals.removed = [];
                                    }
                                    cals.removed.push(delData);
                                  }
                                  return cals;
                                });
                              }
                        }
                      />
                    );
                  }}
                />
                <View className="w-full p-4">
                  <Button
                    className="self-center w-full md:w-60"
                    label={
                      calendarOperationInProgress
                        ? "Please Wait"
                        : calendarSlotsUpdateData?.added?.length ||
                          calendarSlotsUpdateData?.updated?.length ||
                          calendarSlotsUpdateData?.removed?.length
                        ? "Update"
                        : "Close"
                    }
                    waitState={calendarOperationInProgress}
                    type="primary"
                    onPress={onCalendarSlotModalPress}
                  />
                </View>
              </Card>
            </View>
          </View>
          {/* </TouchableWithoutFeedback> */}
        </Modal>
      )}
      <Card
        title="Opening Hours"
        Widget={() => (
          <View className="w-8">
            <Input
              type="toggle"
              value={showOpeningHours}
              wait={isShowOpeningHoursToggling}
              setValue={() => {
                (async (data) => {
                  setIsShowOpeningHoursToggling(true);
                  const token = await isSignedIn();
                  const formData = new FormData();
                  formData.append("enable_opening_hours", data);
                  axiosInstance
                    .patch(apiURIV2 + "/listings/" + lid, formData, {
                      headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setShowOpeningHours(data);
                      }
                    })
                    .catch((e) => console.log("e.res", e?.response, e))
                    .finally(() => setIsShowOpeningHoursToggling(false));
                })(!showOpeningHours);
              }}
            />
          </View>
        )}
      >
        <View className="px-6 w-full">
          <View className="mb-4 w-full flex-row flex-wrap">
            <FlatList
              data={weekDaysForUI}
              renderItem={({ item, index }) => (
                <>
                  <View
                    className={`flex-row justify-between items-center w-full py-2 ${
                      index > 0 ? "mt-4" : ""
                    }`}
                  >
                    <Font weight="600" className="capitalize">
                      {item}
                    </Font>
                    <Pressable
                      onPress={() => {
                        if (openingHoursData) {
                          const startInMin = { h: "09", m: "00" };
                          const endInMin = { h: "21", m: "00" };
                          const id = Date.now() + "-local";
                          setOpeningHoursData((oHD) => {
                            oHD = cloneDeep(oHD);
                            if (oHD[item]?.length) {
                              oHD[item].push({
                                id: id,
                                start_in_min: startInMin,
                                end_in_min: endInMin,
                              });
                            } else {
                              oHD[item] = [
                                {
                                  id: id,
                                  start_in_min: startInMin,
                                  end_in_min: endInMin,
                                },
                              ];
                            }
                            return { ...oHD };
                          });
                          setOpeningHours((openingHours) => {
                            if (!openingHours) {
                              openingHours = {};
                            }
                            if (!openingHours?.add) {
                              openingHours.add = {};
                            }
                            if (openingHours.add?.[item]?.length) {
                              openingHours.add[item].push({
                                id: id,
                                start_in_min: 540,
                                end_in_min: 1260,
                              });
                            } else {
                              openingHours.add[item] = [
                                { id: id, start_in_min: 540, end_in_min: 1260 },
                              ];
                            }
                            return openingHours;
                          });
                        }
                      }}
                    >
                      <RemixIcon
                        name="ri-add-circle-line"
                        color={colors.app.g}
                        size={25}
                      />
                    </Pressable>
                  </View>
                  {openingHoursData ? (
                    <FlatList
                      data={openingHoursData?.[item]}
                      ListEmptyComponent={() => (
                        <View className="flex-1 w-full justify-center items-center border border-app-d3 rounded-md py-3 px-8">
                          <Font>Not Available</Font>
                        </View>
                      )}
                      horizontal={!!openingHoursData?.[item]?.length}
                      showsHorizontalScrollIndicator={true}
                      persistentScrollbar={true}
                      ItemSeparatorComponent={() => {
                        return (
                          <View
                            style={{
                              marginHorizontal: 10,
                            }}
                          />
                        );
                      }}
                      contentContainerStyle={{ marginBottom: 20 }}
                      renderItem={({ item: item1, index: index1 }) => (
                        <View
                          key={item1.id}
                          className=" flex-row justify-around items-center border border-app-d3 w-72 rounded-md py-2"
                        >
                          <View className="flex-1 flex-row justify-center items-center border-app-d3 border-r px-4">
                            <View className="flex-1 flex-row justify-around items-center">
                              <TextInput
                                className="font-medium flex-1 w-full text-center"
                                inputMode="numeric"
                                keyboardType="numeric"
                                style={
                                  Platform.OS === "web"
                                    ? {
                                        outlineColor: "transparent",
                                      }
                                    : {}
                                }
                                value={"" + item1.start_in_min.h}
                                onChangeText={(e) => {
                                  if (e?.length > 2) {
                                    return;
                                  }
                                  let intValue = parseInt(e || 0);
                                  if (intValue < 0) {
                                    e = "00";
                                    intValue = 0;
                                  } else if (intValue > 23) {
                                    e = "23";
                                    intValue = 23;
                                  } else if (isNaN(e)) {
                                    e = e !== "" ? "00" : e;
                                    intValue = 0;
                                  }
                                  const exstMins = parseInt(
                                      item1.start_in_min.m || 0
                                    ),
                                    startInMin = intValue * 60 + exstMins;
                                  setOpeningHoursData((oHD) => {
                                    oHD = cloneDeep(oHD);
                                    oHD[item][index1].start_in_min = {
                                      h: e,
                                      m:
                                        parseInt(exstMins) < 10
                                          ? "0" + exstMins
                                          : exstMins,
                                    };
                                    return { ...oHD };
                                  });
                                  setOpeningHours((oH) => {
                                    if (("" + item1.id).endsWith("-local")) {
                                      const i = oH.add[item].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        oH.add[item][i].start_in_min =
                                          startInMin;
                                      }
                                    } else {
                                      if (!oH.update) {
                                        oH.update = {};
                                      }
                                      if (!oH.update[item]) {
                                        oH.update[item] = {};
                                      }
                                      oH.update[item][item1.id] = {
                                        ...oH.update[item][item1.id],
                                        start_in_min: startInMin,
                                      };
                                    }
                                    return oH;
                                  });
                                }}
                              />
                              <Font
                                weight={800}
                                className="font-bold pb-[2px] px-1"
                              >
                                :
                              </Font>
                              <TextInput
                                className="font-medium flex-1 w-full text-center"
                                inputMode="numeric"
                                keyboardType="numeric"
                                style={
                                  Platform.OS === "web"
                                    ? {
                                        outlineColor: "transparent",
                                      }
                                    : {}
                                }
                                value={"" + item1.start_in_min.m}
                                onChangeText={(e) => {
                                  if (e?.length > 2) {
                                    return;
                                  }
                                  let intValue = parseInt(e || 0);
                                  if (intValue < 0) {
                                    e = "00";
                                    intValue = 0;
                                  } else if (intValue > 59) {
                                    e = "59";
                                    intValue = parseInt(59);
                                  } else if (isNaN(e)) {
                                    e = e !== "" ? "00" : e;
                                    intValue = 0;
                                  }
                                  const exstHrs = parseInt(
                                      item1.start_in_min.h || 0
                                    ),
                                    startInMin = intValue + exstHrs * 60;
                                  setOpeningHoursData((oHD) => {
                                    oHD = cloneDeep(oHD);
                                    oHD[item][index1].start_in_min = {
                                      h:
                                        parseInt(exstHrs) < 10
                                          ? "0" + exstHrs
                                          : exstHrs,
                                      m: e,
                                    };
                                    return { ...oHD };
                                  });
                                  setOpeningHours((oH) => {
                                    if (("" + item1.id).endsWith("-local")) {
                                      const i = oH.add[item].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        oH.add[item][i].start_in_min =
                                          startInMin;
                                      }
                                    } else {
                                      if (!oH.update) {
                                        oH.update = {};
                                      }
                                      if (!oH.update[item]) {
                                        oH.update[item] = {};
                                      }
                                      oH.update[item][item1.id] = {
                                        ...oH.update[item][item1.id],
                                        start_in_min: startInMin,
                                      };
                                    }
                                    return { ...oH };
                                  });
                                }}
                              />
                            </View>
                            <Font className="font-bold px-4">-</Font>
                            <View className="flex-1 flex-row justify-around items-center">
                              <TextInput
                                className="font-medium flex-1 w-full text-center"
                                inputMode="numeric"
                                keyboardType="numeric"
                                style={
                                  Platform.OS === "web"
                                    ? {
                                        outlineColor: "transparent",
                                      }
                                    : {}
                                }
                                value={"" + item1.end_in_min.h}
                                onChangeText={(e) => {
                                  if (e?.length > 2) {
                                    return;
                                  }
                                  let intValue = parseInt(e || 0);
                                  if (intValue < 0) {
                                    e = "00";
                                    intValue = 0;
                                  } else if (intValue > 23) {
                                    e = "23";
                                    intValue = 23;
                                  } else if (isNaN(e)) {
                                    e = e !== "" ? "00" : e;
                                    intValue = 0;
                                  }
                                  const exstMins = parseInt(
                                      item1.end_in_min.m || 0
                                    ),
                                    startInMin = intValue * 60 + exstMins;
                                  setOpeningHoursData((oHD) => {
                                    oHD = cloneDeep(oHD);
                                    oHD[item][index1].end_in_min = {
                                      h: e,
                                      m:
                                        parseInt(exstMins) < 10
                                          ? "0" + exstMins
                                          : exstMins,
                                    };
                                    return { ...oHD };
                                  });
                                  setOpeningHours((oH) => {
                                    if (("" + item1.id).endsWith("-local")) {
                                      const i = oH.add[item].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        oH.add[item][i].end_in_min = startInMin;
                                      }
                                    } else {
                                      if (!oH.update) {
                                        oH.update = {};
                                      }
                                      if (!oH.update[item]) {
                                        oH.update[item] = {};
                                      }
                                      oH.update[item][item1.id] = {
                                        ...oH.update[item][item1.id],
                                        end_in_min: startInMin,
                                      };
                                    }
                                    return { ...oH };
                                  });
                                }}
                              />
                              <Font
                                weight={800}
                                className="font-bold pb-[2px] px-1"
                              >
                                :
                              </Font>
                              <TextInput
                                className="font-medium flex-1 w-full text-center"
                                inputMode="numeric"
                                keyboardType="numeric"
                                style={
                                  Platform.OS === "web"
                                    ? {
                                        outlineColor: "transparent",
                                      }
                                    : {}
                                }
                                value={"" + item1.end_in_min.m}
                                onChangeText={(e) => {
                                  if (e?.length > 2) {
                                    return;
                                  }
                                  let intValue = parseInt(e || 0);
                                  if (intValue < 0) {
                                    e = "00";
                                    intValue = 0;
                                  } else if (intValue > 59) {
                                    e = "59";
                                    intValue = parseInt(59);
                                  } else if (isNaN(e)) {
                                    e = e !== "" ? "00" : e;
                                    intValue = 0;
                                  }
                                  const exstHrs = parseInt(
                                      item1.end_in_min.h || 0
                                    ),
                                    startInMin = intValue + exstHrs * 60;
                                  setOpeningHoursData((oHD) => {
                                    oHD = cloneDeep(oHD);
                                    oHD[item][index1].end_in_min = {
                                      h:
                                        parseInt(exstHrs) < 10
                                          ? "0" + exstHrs
                                          : exstHrs,
                                      m: e,
                                    };
                                    return { ...oHD };
                                  });
                                  setOpeningHours((oH) => {
                                    if (("" + item1.id).endsWith("-local")) {
                                      const i = oH.add[item].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        oH.add[item][i].end_in_min = startInMin;
                                      }
                                    } else {
                                      if (!oH.update) {
                                        oH.update = {};
                                      }
                                      if (!oH.update[item]) {
                                        oH.update[item] = {};
                                      }
                                      oH.update[item][item1.id] = {
                                        ...oH.update[item][item1.id],
                                        end_in_min: startInMin,
                                      };
                                    }
                                    return { ...oH };
                                  });
                                }}
                              />
                            </View>
                          </View>
                          <Pressable
                            className="px-2"
                            onPress={() => {
                              setOpeningHoursData((oHD) => {
                                oHD = cloneDeep(oHD);
                                const i = oHD[item].findIndex(
                                  (d1) => d1.id === item1.id
                                );
                                if (i > -1) {
                                  oHD[item].splice(i, 1);
                                }
                                return { ...oHD };
                              });

                              setOpeningHours((openingHours) => {
                                if (("" + item1.id).endsWith("-local")) {
                                  openingHours.add[item] = openingHours.add[
                                    item
                                  ].filter((d) => d.id !== item1.id);
                                } else {
                                  if (!openingHours.delete) {
                                    openingHours.delete = {};
                                  }
                                  openingHours.delete[item] = [
                                    ...(openingHours.delete?.[item] || []),
                                    item1,
                                  ];
                                }
                                return { ...openingHours };
                              });
                            }}
                          >
                            <RemixIcon
                              name="ri-close-fill"
                              size={25}
                              color={colors.app.e}
                            />
                          </Pressable>
                        </View>
                      )}
                    />
                  ) : (
                    <View className="flex-1 justify-center items-center">
                      <Font>Loading...</Font>
                    </View>
                  )}
                </>
              )}
            />
          </View>
          {/* {[...Array(5).keys()].map((v1, i1) => (
            <View
              key={"oh" + i1}
              className="w-full flex-row justify-center items-center border border-app-c rounded-md py-1.5 px-4 mt-2"
            >
              <View className="flex-1 flex-row justify-center items-center">
                <View className="flex-1 flex-row justify-center items-center">
                  <TextInput
                    className="font-medium px-2"
                    inputMode="numeric"
                    keyboardType="numeric"
                    value={"09"}
                  ></TextInput>
                  <Text className="font-medium pb-[2px]">:</Text>
                  <TextInput
                    className="font-medium px-2"
                    inputMode="numeric"
                    keyboardType="numeric"
                    value={"00"}
                  ></TextInput>
                </View>
                <Text className="font-medium">-</Text>
                <View className="flex-1 flex-row justify-center items-center">
                  <TextInput
                    className="font-medium px-2"
                    inputMode="numeric"
                    keyboardType="numeric"
                    value={"21"}
                  ></TextInput>
                  <Text className="font-medium pb-[2px]">:</Text>
                  <TextInput
                    className="font-medium px-2"
                    inputMode="numeric"
                    keyboardType="numeric"
                    value={"00"}
                  ></TextInput>
                </View>
              </View>
              <Pressable
                onPress={() =>
                  setOpeningHours((openingHours) => {
                    openingHours.splice(i1, 1);
                    return [...openingHours];
                  })
                }
              >
                <RemixIcon
                  name="ri-close-fill"
                  className=""
                  size={25}
                  color={colors.app.e}
                />
              </Pressable>
            </View>
          ))} */}
        </View>
      </Card>
      <Card title="Availability">
        <View className="px-6 w-full">
          <View className="mb-4 w-full flex-row flex-wrap">
            {availabilityServices?.length ? (
              <FlatList
                className="w-full"
                data={weekDaysForUI}
                renderItem={({ item, index }) => (
                  <>
                    <View className="flex-row justify-between items-center w-full py-2">
                      <Font weight="600" className="capitalize">
                        {item}
                      </Font>
                      <Pressable
                        onPress={() => {
                          const id = Date.now() + "-local";
                          setAvailableSlotsData((aSD) => {
                            if (!aSD) {
                              aSD = {};
                            }
                            let dataObj = {
                              id: id,
                              start_in_min: { h: "09", m: "00", tim: 540 },
                              end_in_min: { h: "21", m: "00", tim: 1260 },
                              service_unit_capacity: 1,
                              service_id: "",
                            };
                            if (aSD?.[item]?.length) {
                              aSD[item].push(dataObj);
                            } else {
                              aSD[item] = [dataObj];
                            }
                            return { ...aSD };
                          });
                          setAvailableSlots((d) => {
                            if (!d.add) {
                              d.add = {};
                            }
                            if (!d.add[item]) {
                              d.add[item] = [];
                            }
                            let dataObj = {
                              id: id,
                              start_in_min: 540,
                              end_in_min: 1260,
                              service_unit_capacity: 1,
                              service_id: "",
                            };
                            if (d?.[item]?.length) {
                              d.add[item].push(dataObj);
                            } else {
                              d.add[item] = [dataObj];
                            }
                            return d;
                          });
                        }}
                      >
                        <RemixIcon
                          name="ri-add-circle-line"
                          className=""
                          size={25}
                          color={colors.app.g}
                        />
                      </Pressable>
                    </View>
                    {availableSlotsData ? (
                      <FlatList
                        data={availableSlotsData?.[item]}
                        ListEmptyComponent={() => (
                          <View className="flex-1 w-full justify-center items-center border border-app-d3 rounded-md py-3 px-8">
                            <Font>Not Available</Font>
                          </View>
                        )}
                        horizontal={!!availableSlotsData?.[item]?.length}
                        showsHorizontalScrollIndicator={true}
                        persistentScrollbar={true}
                        ItemSeparatorComponent={() => (
                          <View
                            style={{
                              marginHorizontal: 10,
                            }}
                          />
                        )}
                        contentContainerStyle={{ marginBottom: 20 }}
                        renderItem={({ item: item1, index: index1 }) => (
                          <View
                            key={item1.id}
                            className="border border-app-d3 bg-app-b3 w-72 rounded-lg p-3"
                          >
                            <View className="flex-row w-full justify-around items-center border border-app-c rounded py-2">
                              <View className="flex-1 flex-row justify-center items-center border-r border-app-c px-4">
                                <View className="flex-1 flex-row justify-around items-center">
                                  <TextInput
                                    className="font-medium flex-1 w-full text-center"
                                    inputMode="numeric"
                                    keyboardType="numeric"
                                    style={
                                      Platform.OS === "web"
                                        ? {
                                            outlineColor: "transparent",
                                          }
                                        : {}
                                    }
                                    value={"" + item1.start_in_min.h}
                                    onChangeText={(e) => {
                                      if (e?.length > 2) {
                                        return;
                                      }
                                      let intValue = parseInt(e || 0);
                                      if (intValue < 0) {
                                        e = "00";
                                        intValue = 0;
                                      } else if (intValue > 23) {
                                        e = "23";
                                        intValue = 23;
                                      } else if (isNaN(e)) {
                                        e = e !== "" ? "00" : e;
                                        intValue = 0;
                                      }
                                      const exstMins = parseInt(
                                          item1.start_in_min.m || 0
                                        ),
                                        inMin = intValue * 60 + exstMins;
                                      setAvailableSlotsData((aSD) => {
                                        aSD[item][index1].start_in_min = {
                                          h: e,
                                          m:
                                            parseInt(exstMins) < 10
                                              ? "0" + exstMins
                                              : exstMins,
                                          tim: inMin,
                                        };
                                        return { ...aSD };
                                      });
                                      setAvailableSlots((aS) => {
                                        if (
                                          ("" + item1.id).endsWith("-local")
                                        ) {
                                          const i = aS.add[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.add[item][i].start_in_min =
                                              inMin;
                                          }
                                        } else {
                                          if (!aS.update) {
                                            aS.update = {};
                                          }
                                          if (!aS.update[item]) {
                                            aS.update[item] = [];
                                          }
                                          const i = aS.update[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.update[item][i].start_in_min =
                                              inMin;
                                          } else {
                                            aS.update[item].push({
                                              ...item1,
                                              start_in_min: inMin,
                                              end_in_min: item1.end_in_min.tim,
                                            });
                                          }
                                        }
                                        return { ...aS };
                                      });
                                    }}
                                  />
                                  <Font
                                    weight={800}
                                    className="font-bold pb-[2px] px-1"
                                  >
                                    :
                                  </Font>
                                  <TextInput
                                    className="font-medium flex-1 w-full text-center"
                                    inputMode="numeric"
                                    keyboardType="numeric"
                                    style={
                                      Platform.OS === "web"
                                        ? {
                                            outlineColor: "transparent",
                                          }
                                        : {}
                                    }
                                    value={"" + item1.start_in_min.m}
                                    onChangeText={(e) => {
                                      if (e?.length > 2) {
                                        return;
                                      }
                                      let intValue = parseInt(e || 0);
                                      if (intValue < 0) {
                                        e = "00";
                                        intValue = 0;
                                      } else if (intValue > 59) {
                                        e = "59";
                                        intValue = parseInt(59);
                                      } else if (isNaN(e)) {
                                        e = e !== "" ? "00" : e;
                                        intValue = 0;
                                      }
                                      const exstHrs = parseInt(
                                          item1.start_in_min.h || 0
                                        ),
                                        inMin = intValue + exstHrs * 60;
                                      setAvailableSlotsData((aSD) => {
                                        aSD[item][index1].start_in_min = {
                                          h:
                                            parseInt(exstHrs) < 10
                                              ? "0" + exstHrs
                                              : exstHrs,
                                          m: e,
                                          tim: inMin,
                                        };
                                        return { ...aSD };
                                      });
                                      setAvailableSlots((aS) => {
                                        if (
                                          ("" + item1.id).endsWith("-local")
                                        ) {
                                          const i = aS.add[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.add[item][i].start_in_min =
                                              inMin;
                                          }
                                        } else {
                                          if (!aS.update) {
                                            aS.update = {};
                                          }
                                          if (!aS.update[item]) {
                                            aS.update[item] = [];
                                          }
                                          const i = aS.update[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.update[item][i].start_in_min =
                                              inMin;
                                          } else {
                                            aS.update[item].push({
                                              ...item1,
                                              start_in_min: inMin,
                                              end_in_min: item1.end_in_min.tim,
                                            });
                                          }
                                        }
                                        return { ...aS };
                                      });
                                    }}
                                  />
                                </View>
                                <Font className="font-bold px-4">-</Font>
                                <View className="flex-1 flex-row justify-around items-center">
                                  <TextInput
                                    className="font-medium flex-1 w-full text-center"
                                    inputMode="numeric"
                                    keyboardType="numeric"
                                    style={
                                      Platform.OS === "web"
                                        ? {
                                            outlineColor: "transparent",
                                          }
                                        : {}
                                    }
                                    value={"" + item1.end_in_min.h}
                                    onChangeText={(e) => {
                                      if (e?.length > 2) {
                                        return;
                                      }
                                      let intValue = parseInt(e || 0);
                                      if (intValue < 0) {
                                        e = "00";
                                        intValue = 0;
                                      } else if (intValue > 23) {
                                        e = "23";
                                        intValue = 23;
                                      } else if (isNaN(e)) {
                                        e = e !== "" ? "00" : e;
                                        intValue = 0;
                                      }
                                      const exstMins = parseInt(
                                          item1.end_in_min.m || 0
                                        ),
                                        inMin = intValue * 60 + exstMins;
                                      setAvailableSlotsData((aSD) => {
                                        aSD[item][index1].end_in_min = {
                                          h: e,
                                          m:
                                            parseInt(exstMins) < 10
                                              ? "0" + exstMins
                                              : exstMins,
                                          tim: inMin,
                                        };
                                        return { ...aSD };
                                      });
                                      setAvailableSlots((aS) => {
                                        if (
                                          ("" + item1.id).endsWith("-local")
                                        ) {
                                          const i = aS.add[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.add[item][i].end_in_min = inMin;
                                          }
                                        } else {
                                          if (!aS.update) {
                                            aS.update = {};
                                          }
                                          if (!aS.update[item]) {
                                            aS.update[item] = [];
                                          }
                                          const i = aS.update[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.update[item][i].end_in_min =
                                              inMin;
                                          } else {
                                            aS.update[item].push({
                                              ...item1,
                                              start_in_min:
                                                item1.start_in_min.tim,
                                              end_in_min: inMin,
                                            });
                                          }
                                        }
                                        return { ...aS };
                                      });
                                    }}
                                  />
                                  <Font
                                    weight={800}
                                    className="font-bold pb-[2px] px-1"
                                  >
                                    :
                                  </Font>
                                  <TextInput
                                    className="font-medium flex-1 w-full text-center"
                                    inputMode="numeric"
                                    keyboardType="numeric"
                                    style={
                                      Platform.OS === "web"
                                        ? {
                                            outlineColor: "transparent",
                                          }
                                        : {}
                                    }
                                    value={"" + item1.end_in_min.m}
                                    onChangeText={(e) => {
                                      if (e?.length > 2) {
                                        return;
                                      }
                                      let intValue = parseInt(e || 0);
                                      if (intValue < 0) {
                                        e = "00";
                                        intValue = 0;
                                      } else if (intValue > 59) {
                                        e = "59";
                                        intValue = parseInt(59);
                                      } else if (isNaN(e)) {
                                        e = e !== "" ? "00" : e;
                                        intValue = 0;
                                      }
                                      const exstHrs = parseInt(
                                          item1.end_in_min.h || 0
                                        ),
                                        inMin = intValue + exstHrs * 60;
                                      setAvailableSlotsData((aSD) => {
                                        aSD[item][index1].end_in_min = {
                                          h:
                                            parseInt(exstHrs) < 10
                                              ? "0" + exstHrs
                                              : exstHrs,
                                          m: e,
                                          tim: inMin,
                                        };
                                        return { ...aSD };
                                      });
                                      setAvailableSlots((aS) => {
                                        if (
                                          ("" + item1.id).endsWith("-local")
                                        ) {
                                          const i = aS.add[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.add[item][i].end_in_min = inMin;
                                          }
                                        } else {
                                          if (!aS.update) {
                                            aS.update = {};
                                          }
                                          if (!aS.update[item]) {
                                            aS.update[item] = [];
                                          }
                                          const i = aS.update[item].findIndex(
                                            (v) =>
                                              parseInt(v.id) ===
                                              parseInt(item1.id)
                                          );
                                          if (i > -1) {
                                            aS.update[item][i].end_in_min =
                                              inMin;
                                          } else {
                                            aS.update[item].push({
                                              ...item1,
                                              start_in_min:
                                                item1.start_in_min.tim,
                                              end_in_min: inMin,
                                            });
                                          }
                                        }
                                        return { ...aS };
                                      });
                                    }}
                                  />
                                </View>
                              </View>
                              <Pressable
                                className="px-2"
                                onPress={() => {
                                  let delData;
                                  setAvailableSlotsData((aSD) => {
                                    delData = aSD[item].splice(index1, 1);
                                    delData = delData[0];
                                    return { ...aSD };
                                  });
                                  setAvailableSlots((d) => {
                                    if (("" + item1.id).endsWith("-local")) {
                                      d.add[item] = d.add[item].filter(
                                        (d1) => d1.id !== item1.id
                                      );
                                    } else {
                                      if (!d.delete) {
                                        d.delete = {};
                                      }
                                      if (d.delete?.[item]?.length) {
                                        d.delete[item].push(delData);
                                      } else {
                                        d.delete[item] = [delData];
                                      }
                                    }
                                    return { ...d };
                                  });
                                }}
                              >
                                <RemixIcon
                                  name="ri-close-fill"
                                  size={25}
                                  color={colors.app.e}
                                />
                              </Pressable>
                            </View>
                            <View className="flex-row w-full mt-2">
                              <Select
                                placeholder="Choose service"
                                options={availabilityServices || []}
                                value={
                                  item1?.new_service_id || item1.service_id
                                }
                                setValue={(e) => {
                                  const old_sid = item1.service_id;
                                  const intValue = parseInt(e);
                                  setAvailableSlotsData((aSD) => {
                                    aSD[item][index1].new_service_id = intValue;
                                    return { ...aSD };
                                  });
                                  setAvailableSlots((aS) => {
                                    if (("" + item1.id).endsWith("-local")) {
                                      const i = aS.add[item].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        aS.add[item][i].service_id = intValue;
                                      }
                                    } else {
                                      if (!aS.updateServices) {
                                        aS.updateServices = {};
                                      }
                                      if (!aS.updateServices[item]) {
                                        aS.updateServices[item] = [];
                                      }
                                      const i = aS.updateServices[
                                        item
                                      ].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        aS.updateServices[item][i].service_id =
                                          intValue;
                                        aS.updateServices[item][
                                          i
                                        ].old_service_id = old_sid;
                                      } else {
                                        aS.updateServices[item].push({
                                          ...item1,
                                          start_in_min: item1.start_in_min.tim,
                                          end_in_min: item1.end_in_min.tim,
                                          old_service_id: old_sid,
                                          service_id: intValue,
                                        });
                                      }
                                    }
                                    return { ...aS };
                                  });
                                }}
                              />
                            </View>
                            <View className="flex-row w-full mt-2">
                              <Input
                                type="counter"
                                setValue={(e) => {
                                  const intValue = parseInt(
                                    e(item1.service_unit_capacity)
                                  );
                                  setAvailableSlotsData((aSD) => {
                                    aSD[item][index1].service_unit_capacity =
                                      intValue;
                                    return { ...aSD };
                                  });
                                  setAvailableSlots((aS) => {
                                    if (("" + item1.id).endsWith("-local")) {
                                      const i = aS.add[item].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        aS.add[item][i].service_unit_capacity =
                                          intValue;
                                      }
                                    } else {
                                      if (!aS.update) {
                                        aS.update = {};
                                      }
                                      if (!aS.update[item]) {
                                        aS.update[item] = [];
                                      }
                                      const i = aS.update[item].findIndex(
                                        (v) =>
                                          parseInt(v.id) === parseInt(item1.id)
                                      );
                                      if (i > -1) {
                                        aS.update[item][
                                          i
                                        ].service_unit_capacity = intValue;
                                      } else {
                                        aS.update[item].push({
                                          ...item1,
                                          start_in_min: item1.start_in_min.tim,
                                          end_in_min: item1.end_in_min.tim,
                                          service_unit_capacity: intValue,
                                        });
                                      }
                                    }
                                    return { ...aS };
                                  });
                                }}
                                value={item1.service_unit_capacity}
                                placeholder="Available slots"
                                min={1}
                                max={1000}
                              />
                            </View>
                          </View>
                        )}
                      />
                    ) : (
                      <View className="flex-1 justify-center items-center">
                        <Font>Loading...</Font>
                      </View>
                    )}
                  </>
                )}
              />
            ) : (
              <View className="w-full flex-1 justify-center items-center border border-app-d3 rounded-md py-2 px-8">
                <Font>No Services Added</Font>
              </View>
            )}
          </View>
        </View>
      </Card>
      <Card
        title="Availability Calendar"
        Widget={() => (
          <View className="w-8">
            <Input
              type="toggle"
              value={showCalendarAvailabilitySlots}
              wait={isShowCalendarToggling}
              setValue={() => {
                (async (data) => {
                  setIsShowCalendarToggling(true);
                  const token = await isSignedIn();
                  const formData = new FormData();
                  formData.append("enable_availability_calendar_slots", data);
                  axiosInstance
                    .patch(apiURIV2 + "/listings/" + lid, formData, {
                      headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setShowCalendarAvailabilitySlots(data);
                      }
                    })
                    .catch((e) => {
                      console.log("error e.response", e?.response);
                      console.log("error e.response?.data", e?.response?.data);
                    })
                    .finally(() => setIsShowCalendarToggling(false));
                })(!showCalendarAvailabilitySlots);
              }}
            />
          </View>
        )}
      >
        <Calendar1
          slots={calendarSlots}
          onCalendarDateSelect={({
            slots,
            year,
            month,
            date,
            weekday,
            isExpired,
          }) => {
            setShowCalendarAvailabilityModal({
              visible: true,
              onRequestClose: () => {
                setCalendarSlotsUpdateData({
                  added: [],
                  updated: [],
                  removed: [],
                });
                setShowCalendarAvailabilityModal({
                  visible: false,
                  onRequestClose: () => null,
                  slots: [],
                  isExpired: null,
                  year: null,
                  month: null,
                  date: null,
                  weekday: null,
                });
              },
              slots: slots || [],
              isExpired,
              year,
              month,
              date,
              weekday,
            });
          }}
        />
      </Card>
    </>
  );
};

const ListingReviews = ({
  step,
  setStep,
  lid,
  setShowResponseModal,
  resetClicked,
  setResetClicked,
  isEditingForm,
  setIsEditingForm,
  lRIsLoading,
  setLRIsLoading,
  refreshListingReviews,
  setRefreshListingReviews,
}) => {
  const navigation = useNavigation();
  const {
    // listingDetailsData,
    // setListingDetailsData,
    // updateListingDetailsData,
    setListingReviews,
    listingReviews,
    listingReviewsPagination,
    setListingReviewsPagination,
  } = useListingContext();

  const [listingReviewsData, setListingReviewsData] = useState();

  const getListingReviews = React.useCallback(
    async (offset, limit, push) => {
      setLRIsLoading(true);
      let data, total_count;
      try {
        const token = await isSignedIn();
        const res = await axiosInstance.get(
          apiURIV2 + "/listings/" + lid + "/reviews",
          {
            params: { offset, limit: limit || load_records },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          total_count = res.data.total_count;
          data = res.data.data;
        }
      } catch (e) {
        console.log("listing reviews e.res", e?.response, e);

        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(e, "Unknown Error"),
        });
        setLRIsLoading(false);
      } finally {
        const pagObj = {
          from: (offset || -1) + 1 || initial_from,
          to:
            (offset || -1) + 1
              ? offset + (data?.length || load_records)
              : data?.length || load_records,
          total: total_count,
        };
        setListingReviewsPagination({ data: pagObj, lid: lid });
        setListingReviews({ data, lid: lid, push });
        setListingReviewsData(data || {});
        console.log("data:", data);
        setLRIsLoading(false);
      }
    },
    [
      lid,
      setLRIsLoading,
      setListingReviews,
      setListingReviewsPagination,
      setShowResponseModal,
    ]
  );

  useEffect(() => {
    if (refreshListingReviews) {
      setRefreshListingReviews(false);
      getListingReviews();
    }
  }, [refreshListingReviews, setRefreshListingReviews, getListingReviews]);

  if (!listingReviewsData && !lRIsLoading) {
    getListingReviews();
  } else if (!listingReviewsData && listingReviews?.[lid]) {
    setListingReviewsData(listingReviews[lid].slice(0, load_records));
  }

  return (
    <>
      <Card title="Reviews">
        <FlatList
          className="w-full"
          data={listingReviewsData}
          ListEmptyComponent={() => (
            <View className="w-full justify-center items-center pb-4">
              <Font>{lRIsLoading ? "Loading..." : "No Reviews"}</Font>
            </View>
          )}
          ItemSeparatorComponent={() => (
            <View className="w-full my-4 border-b border-gray-200" />
          )}
          renderItem={({ item: review }) => (
            <Pressable
              className="px-4 flex-row w-full"
              key={review.id}
              onPress={() => {
                navigation.setParams({ lrid: review.id });
                setStep(5);
              }}
            >
              <View className="justify-start pt-1 items-center w-24 lg:w-32">
                <Image
                  source={
                    review.user_profile_image_url
                      ? {
                          uri: mediaURI + review.user_profile_image_url,
                        }
                      : DefaultImages.DP_PLACEHOLDER
                  }
                  className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                ></Image>
              </View>
              <View className="justify-start ml-4 flex-1">
                <View className="flex-row items-center flex-wrap pb-1">
                  <Font className="text-app-d text-base mr-2" weight={600}>
                    {review.user_name}
                  </Font>
                  <Font
                    className={`px-1 py-0.5 text-app-b capitalize rounded-full leading-4 ${
                      review.user_role === UserRoles.CUSTOMER
                        ? "bg-app-d3 text-app-d"
                        : "bg-app-e3 text-app-e"
                    }`}
                  >
                    {review.user_role}
                  </Font>
                </View>
                <View className="flex-row items-center flex-wrap">
                  <View className="bg-app-d flex-row items-center rounded-full px-1 py-0.5 mr-1">
                    <Font className="text-app-a text-sm mx-1 leading-4">
                      {parseFloat(review.rating)}/5
                    </Font>
                    <RemixIcon
                      name="star-fill"
                      size={13}
                      color={colors.app.i}
                      className="flex-shrink-0"
                    />
                  </View>
                  <View className="ml-1 flex-row items-center">
                    <RemixIcon
                      name="ri-time-line"
                      size={16}
                      color={colors.app.d}
                      className="mr-1"
                    />
                    <Font className="text-app-d1 leading-4">
                      {moment(review.updated_at).fromNow()}
                    </Font>
                  </View>
                </View>
                <View className="flex-row items-center justify-start py-3">
                  <RemixIcon
                    className="mr-2 self-start h-full"
                    name="ri-discuss-fill"
                    size={16}
                    color={colors.app.d}
                  />
                  <Font
                    numberOfLines={4}
                    className="text-app-d flex flex-row items-center leading-4"
                  >
                    {!!review.is_edited && (
                      <>
                        <RemixIcon
                          className="-mb-px self-start"
                          name="ri-pencil-fill"
                          size={12}
                          color={colors.app.d}
                        />{" "}
                      </>
                    )}
                    {review.message}
                  </Font>
                </View>
                <View className="flex-row items-center">
                  <RemixIcon
                    name="ri-reply-fill"
                    size={16}
                    color={colors.app.d}
                    className="mr-2"
                  />
                  <Font className="text-app-d1">
                    {[0].includes(review.replies?.length)
                      ? review.replies?.length
                      : "-"}{" "}
                    {review.replies?.length == 1 ? "reply" : "replies"}
                  </Font>
                </View>
              </View>
            </Pressable>
          )}
        />

        {/* {load_records < listingReviewsPagination.total && ( */}
        <View className="flex-row justify-between w-full">
          {/* {listingReviewsPagination.from > initial_from && ( */}
          <Pressable
            className="p-4 "
            onPress={() => {
              if (listingReviewsPagination.from > initial_from) {
                const from =
                  listingReviewsPagination.from === 1
                    ? 1
                    : listingReviewsPagination.from - load_records;
                const toModulus = listingReviewsPagination.to % load_records;
                const to =
                  listingReviewsPagination.to -
                  (toModulus > 0
                    ? toModulus
                    : listingReviewsPagination.to > load_records
                    ? load_records
                    : 0);
                const pagObj = {
                  from: from,
                  to: to,
                  total: listingReviewsPagination.total,
                };
                // setReviewsPagination(pagObj);
                setListingReviewsPagination({
                  data: pagObj,
                  lid: lid,
                });
                getListingReviews(from - 1, load_records, true);
                setListingReviewsData(
                  listingReviews?.[lid]?.slice(from - 1, to)
                );
              }
            }}
            disabled={!listingReviewsPagination.from > initial_from}
          >
            <Font
              className={`${
                listingReviewsPagination.from > initial_from
                  ? "text-app-e"
                  : "text-app-d2"
              }`}
            >
              {"<"} Prev
            </Font>
          </Pressable>
          {/* )} */}
          <View className="flex-1" />
          {/* {listingReviewsPagination.to < listingReviewsPagination.total && ( */}
          <Pressable
            className="p-4"
            onPress={() => {
              if (
                listingReviewsPagination.to < listingReviewsPagination.total
              ) {
                const remList =
                  listingReviewsPagination.total - listingReviewsPagination.to;
                const from =
                  listingReviewsPagination.from +
                  (remList > 0 ? load_records : 0);
                const to =
                  listingReviewsPagination.to +
                  (remList < load_records ? remList : load_records);
                const pagObj = {
                  from: from,
                  to: to,
                  total: listingReviewsPagination.total,
                };
                const listData = listingReviews?.[lid]?.slice(from - 1, to);
                if (listData?.length) {
                  // setReviewsPagination(pagObj);
                  setListingReviewsPagination({
                    data: pagObj,
                    lid: lid,
                  });
                  setListingReviewsData(listData);
                } else {
                  getListingReviews(from - 1, load_records, true);
                }
              }
            }}
            disabled={
              !listingReviewsPagination.to < listingReviewsPagination.total
            }
          >
            <Font
              className={`${
                listingReviewsPagination.to < listingReviewsPagination.total
                  ? "text-app-e"
                  : "text-app-d2"
              }`}
            >
              Next {">"}
            </Font>
          </Pressable>
          {/* )} */}
        </View>
        {/* )} */}
      </Card>
    </>
  );
};

export default function UpdateListing({ navigation, route }) {
  const { user } = useContext(AuthContext);
  const { screen } = useSharedContext();
  const {
    deleteListingDetailsData,
    setListings,
    listings,
    setPagination,
    pagination,
  } = useListingContext();
  const [step, setStep] = React.useState(1);
  const [listingDetailsData, setListingDetailsData] = React.useState();
  const [isEditingForm, setIsEditingForm] = React.useState(false);
  const [aLDIsLoading, setALDIsLoading] = React.useState(false);
  const [listingCouponsIsLoading, setListingCouponsIsLoading] = useState();
  // const { listingPackages, setListingPackages } = useListingContext();
  const [saveClicked, setSaveClicked] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState();
  const [listingCoupons, setListingCoupons] = useState();

  const [refreshListingReviews, setRefreshListingReviews] = useState();
  const [lRIsLoading, setLRIsLoading] = useState();
  const [lRDIsLoading, setLRDIsLoading] = useState(false);
  const [listingReviewDetailsData, setListingReviewDetailsData] = useState();
  const [replyText, setReplyText] = React.useState("");
  const [isEditingReply, setIsEditingReply] = React.useState();
  const [operationQueue, setOperationQueue] = React.useState([]);
  const [isInOperation, setIsInOperation] = React.useState();
  const lid = route.params?.lid;

  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [showConfirmModal, setShowConfirmModal] = React.useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);

  const onDeleteListing = async () => {
    try {
      setIsDeleteModal(false);
      const token = await isSignedIn();
      const res = await axiosInstance.delete(apiURIV2 + "/listings/" + lid, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 204 || res.status === 200) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
            navigation.goBack();
          },
          bodyText: "Successfully deleted the listing",
        });
        const index = listings?.findIndex((l) => l.id == lid);
        if (index > -1) {
          listings.splice(index, 1);
          setListings([...(listings || [])]);
          if (pagination)
            setPagination({
              from:
                pagination.from === index
                  ? pagination.from + 1
                  : pagination.from,
              to:
                pagination.to === pagination.total ||
                (pagination.from <= index && index + 1 <= pagination.to)
                  ? pagination.to - 1
                  : pagination.to,
              total: pagination.total - 1,
            });
        }
        deleteListingDetailsData({ id: lid });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
    } finally {
      setIsInOperation(false);
    }
  };
  const {
    // listingDetailsData,
    // setListingDetailsData,
    // updateListingDetailsData,
    setListingReviewDetails,
    listingReviewDetails,
  } = useListingContext();

  // const lid = route.params?.lid;
  const lrid = route.params?.lrid;
  let listingData = React.useMemo(
    () => cloneDeep(listingDetailsData),
    [listingDetailsData]
  ); //listingDetailsData?.[lid];

  React.useEffect(() => {
    const currency = listingData?.service_unit?.currency;
    if (!currencySymbol) {
      setCurrencySymbol(
        currency
          ? currencyList.find((v) => v.value === currency) || currency
          : "$"
      );
    }
  }, [listingData?.service_unit?.currency, currencySymbol]);

  React.useEffect(() => {
    if (!isInOperation && operationQueue?.length) {
      let operation;
      setOperationQueue((data) => {
        data = cloneDeep(data);
        [operation] = data.splice(0, 1);
        operation();
        return data;
      });
    }
  }, [isInOperation, operationQueue]);

  if ((step < 4 && !lid) || ([5].includes(step) && !lrid)) {
    return navigation.replace("listings");
  }

  const getAllListingDetails = async () => {
    setALDIsLoading(true);
    let data;
    try {
      const res = await axiosInstance.get(apiURIV2 + "/listings/" + lid);
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      if ([404].includes(e?.response?.status)) {
        return navigation.replace("listings");
      }
    } finally {
      // setListingDetailsData({ data, id: lid });
      if (data) {
        data.opening_hours = parseSlotsObject(data.opening_hours);
        data.available_slots = parseSlotsObject(data.available_slots);
        setListingDetailsData(data);
      }
      setALDIsLoading(false);
    }
  };
  if (!listingData && !aLDIsLoading) {
    getAllListingDetails();
  }
  const getListingCoupons = async () => {
    setListingCouponsIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + `/coupons/allotments/listing/${lid}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("error:", e);
      console.log("e.response", e?.response);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Listing Coupons"
        ),
      });
    } finally {
      setListingCoupons((data || []).map((v) => v.coupon));
      setListingCouponsIsLoading(false);
    }
  };
  if (!listingCoupons && !listingCouponsIsLoading) {
    getListingCoupons();
  }

  // if (!listingPackages && !lPIsLoading) {
  //   const getListingPackages = async () => {
  //     setLPIsLoading(true);
  //     let data;
  //     try {
  //       const token = await isSignedIn();
  //       const res = await axiosInstance.get(apiURIV2 + "/packages", {
  //         params: { package_item_type: "listing", currency: "USD" },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       console.log("res.status: ", res.status, res.data);
  //       if (res.status === 200) {
  //         data = res.data.data;
  //       }
  //     } catch (e) {
  //       console.log("e.res", e?.response, e);
  //     } finally {
  //       setListingPackages({ data });
  //       setLPIsLoading(false);
  //     }
  //   };
  //   getListingPackages();
  // }

  const getListingReviewDetails = async () => {
    setLRDIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/listings/" + lid + "/reviews/" + lrid,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("listing review details e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
      setLRDIsLoading(false);
    } finally {
      setListingReviewDetailsData(data || {});
      setListingReviewDetails({ data, lrid: lrid });
      setLRDIsLoading(false);
    }
  };

  if (step == 5) {
    if (
      !listingReviewDetailsData &&
      !listingReviewDetails?.[lrid] &&
      !lRDIsLoading
    ) {
      getListingReviewDetails();
    } else if (!listingReviewDetailsData && listingReviewDetails?.[lrid]) {
      setListingReviewDetailsData(listingReviewDetails[lrid] || {});
    }
  }

  if (!listingData) {
    return (
      <View className="flex-1 justify-center items-center">
        <ActivityIndicator color={colors.app.e} size="large" />
      </View>
    );
  }

  const updateListingDetails = (cb) => {
    // updateListingDetailsData((ldd) => {
    //   ldd[lid] = cb(ldd[lid]);
    //   return { ...ldd };
    // });
    setListingDetailsData((ldd) => {
      const data = cb(cloneDeep(ldd));
      return data;
    });
  };

  const saveButtonHandler = () => {
    if (step == 1) {
      setSaveClicked(true);
      return;
    } else if (step == 2) {
      setSaveClicked(true);
      return;
    } else if (step == 3) {
      setSaveClicked(true);
      return;
    }
  };

  const deleteListingReviewReplies = async (lrrid) => {
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const res = await axiosInstance.delete(
        apiURIV2 +
          "/listings/" +
          lid +
          "/reviews/" +
          lrid +
          "/replies/" +
          lrrid,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 204) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Reply successfully deleted",
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const updateListingReviewReplies = async (dataObj) => {
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const res = await axiosInstance.patch(
        apiURIV2 +
          "/listings/" +
          lid +
          "/reviews/" +
          lrid +
          "/replies/" +
          dataObj.id,
        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 201) {
        delete dataObj.processText;
        let reviewData = listingReviewDetails[lrid];
        reviewData.replies = reviewData.replies.filter(
          (r) => r.id !== dataObj.id
        );
        reviewData = {
          ...reviewData,
          replies: [...(reviewData.replies || []), dataObj],
        };
        setListingReviewDetails({ data: reviewData, lrid });
        setListingReviewDetailsData((data) => {
          data = cloneDeep(data);
          if (data.replies) {
            const i = data.replies.findIndex((v) => v.id === dataObj.id);
            data.replies[i] = dataObj;
          } else {
            data.replies = [dataObj];
          }
          return data;
        });
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const addListingReviewReply = async (dataObj) => {
    let data;
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/listings/" + lid + "/reviews/" + lrid + "/replies",
        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
            // "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 201) {
        data = res.data.data;
        delete dataObj.processText;
        let reviewData = listingReviewDetails[lrid];
        reviewData = {
          ...reviewData,
          replies: [...(reviewData.replies || []), data],
        };
        setListingReviewDetails({ data: reviewData, lrid });
        setListingReviewDetailsData((replyData) => {
          replyData = cloneDeep(replyData);
          replyData.replies[replyData.replies.length - 1] = data;
          return replyData;
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data.message,
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const onSendReplyHandler = (replyInput, isEditingReply) => {
    setReplyText("");
    let replyObj;
    if (!isEditingReply) {
      const dateNow = moment.now();
      replyObj = {
        id: "reply-local" + Date.now(),
        user_id: user.id,
        user_role: user.role,
        user_name: user.first_name + " " + user.last_name,
        user_profile_image_url: user.profile_image_url,
        rating: null,
        message: replyInput,
        is_reply: true,
        is_edited: false,
        created_at: dateNow,
        updated_at: dateNow,
        processText: "sending...",
        user,
      };
    }
    setListingReviewDetailsData((data) => {
      data = cloneDeep(data);
      if (isEditingReply) {
        setIsEditingReply();
        isEditingReply = {
          ...isEditingReply,
          message: replyInput,
          is_edited: true,
          processText: "updating...",
        };
        const i = data.replies?.findIndex((v) => v.id === isEditingReply.id);
        if (i > -1) {
          data.replies[i] = isEditingReply;
        }
        setOperationQueue((q) => {
          q = cloneDeep(q);
          q.push(() => updateListingReviewReplies(isEditingReply));
          return q;
        });
      } else {
        if (!data?.replies) {
          data.replies = [replyObj];
        } else {
          data.replies.push(replyObj);
        }
        setOperationQueue((q) => {
          q = cloneDeep(q);
          q.push(() => addListingReviewReply(replyObj));
          return q;
        });
      }
      return data;
    });
  };

  const onDeleteReplyHandler = (replyID, replyIndex) => {
    if (replyIndex > -1) {
      setListingReviewDetailsData((data) => {
        data = cloneDeep(data);
        data.replies.splice(replyIndex, 1);
        return data;
      });
      setOperationQueue((q) => {
        q = cloneDeep(q);
        q.push(() => deleteListingReviewReplies(replyID));
        return q;
      });
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50 relative">
      {showConfirmModal.visible && <ConfirmModal {...showConfirmModal} />}
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <ConfirmModal
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onConfirm={onDeleteListing}
        confirmText={"Delete"}
        bodyText={"Are you sure you want to delete the Listing ?"}
        BodyComponent={false}
      ></ConfirmModal>
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        overScrollMode="never"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={
                step == 4
                  ? lRIsLoading
                  : step == 5
                  ? lRDIsLoading
                  : aLDIsLoading
              }
              onRefresh={() => {
                if (step == 4) {
                  setRefreshListingReviews(true);
                } else {
                  getAllListingDetails();
                }
              }}
            />
          )
        }
        keyboardShouldPersistTaps="always"
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          {step == 5 ? (
            <>
              <View className="mb-4 lg:my-4 p-0 lg:px-4 flex-col w-full">
                <View className="flex flex-row items-center">
                  <View className="hidden lg:flex flex-1 mr-4">
                    <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                      Listing Review
                    </Font>
                  </View>
                </View>
              </View>
              <View className="p-0 lg:px-4">
                <Card title="Review Details">
                  {listingReviewDetailsData ? (
                    Object.keys(listingReviewDetailsData || {})?.length ? (
                      <>
                        <View
                          className="px-4 pb-4 border-b border-gray-200 flex-row w-full"
                          key={listingReviewDetailsData.id}
                        >
                          <View className="justify-start pt-1 items-center w-24 lg:w-32">
                            <Image
                              source={
                                listingReviewDetailsData.user_profile_image_url
                                  ? {
                                      uri:
                                        mediaURI +
                                        listingReviewDetailsData.user_profile_image_url,
                                    }
                                  : DefaultImages.DP_PLACEHOLDER
                              }
                              className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                            ></Image>
                          </View>
                          <View className="justify-start ml-4 flex-1">
                            <View className="flex-row items-center flex-wrap pb-1">
                              <Font
                                className="text-app-d text-base mr-2"
                                weight={600}
                              >
                                {listingReviewDetailsData?.user?.first_name +
                                  " " +
                                  listingReviewDetailsData?.user?.last_name}
                              </Font>
                              <Font
                                className={`px-1 pt-1 pb-[5px] text-app-b capitalize rounded-full leading-3 ${
                                  listingReviewDetailsData?.user?.role ===
                                  "customer"
                                    ? "bg-app-d3 text-app-d"
                                    : "bg-app-e3 text-app-e"
                                }`}
                              >
                                {listingReviewDetailsData?.user?.role}
                              </Font>
                            </View>
                            <View className="flex-row items-center flex-wrap">
                              <View className="bg-app-d flex-row items-center rounded-full px-1 py-0.5">
                                <Font
                                  className="text-app-a mx-1 leading-4"
                                  weight={600}
                                >
                                  {parseFloat(listingReviewDetailsData.rating)}
                                  /5
                                </Font>
                                <RemixIcon
                                  name="star-fill"
                                  size={14}
                                  color={colors.app.i}
                                  className="flex-shrink-0"
                                />
                              </View>
                              <Font className="text-app-d1 leading-3 pl-2">
                                - on{" "}
                                {getFullDateTime(
                                  listingReviewDetailsData.updated_at
                                )}
                              </Font>
                            </View>
                            <View className="flex-row items-center py-3">
                              <RemixIcon
                                className="pr-2 pt-px -mb-px w-6 self-start"
                                name="ri-discuss-fill"
                                size={16}
                                color={colors.app.d}
                              />
                              <Font
                                numberOfLines={4}
                                className="text-app-d flex flex-row items-center"
                              >
                                {listingReviewDetailsData.is_edited && (
                                  <>
                                    <RemixIcon
                                      className="pr-2 -mb-px"
                                      name="ri-pencil-fill"
                                      size={12}
                                      color={colors.app.d}
                                    />
                                    &nbsp;
                                  </>
                                )}
                                {listingReviewDetailsData.message}
                              </Font>
                            </View>
                          </View>
                        </View>
                        {listingReviewDetailsData?.replies?.length ? (
                          <View className="w-full flex-1 items-start justify-start">
                            <View className="border-b border-gray-200 p-4">
                              <Font className="text-app-d py-1 px-2 border border-app-d2 rounded">
                                {listingReviewDetailsData?.replies?.length ||
                                  "-"}{" "}
                                {listingReviewDetailsData?.replies?.length === 1
                                  ? "Reply"
                                  : "Replies"}
                              </Font>
                            </View>
                            {listingReviewDetailsData.replies.map(
                              (reply, i) => (
                                <View
                                  className={`px-4 pt-4 ${
                                    i ===
                                    (listingReviewDetailsData?.replies
                                      ?.length || 0) -
                                      1
                                      ? "pb-8"
                                      : "pb-4"
                                  } border-b border-gray-200 flex-row w-full`}
                                  key={reply.id}
                                >
                                  <View className="justify-start pt-1 items-center w-24 lg:w-32">
                                    <Image
                                      source={
                                        reply.user_profile_image_url
                                          ? {
                                              uri:
                                                mediaURI +
                                                reply.user_profile_image_url,
                                            }
                                          : DefaultImages.DP_PLACEHOLDER
                                      }
                                      className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                                    ></Image>
                                  </View>
                                  <View className="justify-start ml-4 flex-1">
                                    <View className="flex-1 flex-row flex-wrap justify-between items-center">
                                      <View className="flex-row flex-wrap items-center pb-1">
                                        <Font
                                          className="text-app-d text-base mr-2"
                                          weight={600}
                                        >
                                          {reply?.user_name}
                                        </Font>
                                        <Font
                                          className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                                            reply?.user_role ===
                                            UserRoles.CUSTOMER
                                              ? "bg-app-d3 text-app-d"
                                              : "bg-app-e3 text-app-e"
                                          }`}
                                        >
                                          {reply.user_role}
                                        </Font>
                                      </View>
                                      {reply.user_id === user.id && (
                                        <View className="flex-row items-center">
                                          <Pressable
                                            className="p-0.5 mr-2"
                                            onPress={() => {
                                              setIsEditingReply(reply);
                                              setReplyText(reply.message);
                                            }}
                                          >
                                            <RemixIcon
                                              name="ri-pencil-fill"
                                              className="self-start"
                                              size={20}
                                              color={colors.app.d2}
                                            />
                                          </Pressable>
                                          <Pressable
                                            className="p-0.5"
                                            onPress={() => {
                                              setShowConfirmModal((d) => ({
                                                ...d,
                                                visible: true,
                                                BodyComponent: () => (
                                                  <Font>
                                                    Do you want to delete the
                                                    Specified Review?
                                                  </Font>
                                                ),
                                                onConfirm: () => {
                                                  setShowConfirmModal((d1) => {
                                                    d1.visible = false;
                                                    d1.onConfirm = () => null;
                                                    d1.onCancel = () => null;
                                                    d1.cancelText = "Cancel";
                                                    d1.confirmText = "Confirm";
                                                    d1.bodyText = "";
                                                    return { ...d1 };
                                                  });
                                                  onDeleteReplyHandler(
                                                    reply.id,
                                                    i
                                                  );
                                                },
                                                onCancel: () =>
                                                  setShowConfirmModal((d1) => {
                                                    d1.visible = false;
                                                    d1.onConfirm = () => null;
                                                    d1.onCancel = () => null;
                                                    d1.cancelText = "Cancel";
                                                    d1.confirmText = "Confirm";
                                                    d1.bodyText = "";
                                                    return { ...d1 };
                                                  }),
                                              }));
                                            }}
                                          >
                                            <RemixIcon
                                              name="ri-delete-bin-5-fill"
                                              className="self-start"
                                              size={20}
                                              color={colors.app.d2}
                                            />
                                          </Pressable>
                                        </View>
                                      )}
                                    </View>
                                    <View className="flex-row items-center pb-2">
                                      <Font className="text-app-d1 leading-4">
                                        {reply.updated_at === -1
                                          ? "sending..."
                                          : getFullDateTime(reply.updated_at)}
                                      </Font>
                                    </View>
                                    <View className="flex-row items-center">
                                      <RemixIcon
                                        name="ri-reply-fill"
                                        size={16}
                                        color={colors.app.d}
                                        className="mr-2"
                                      />
                                      <Font
                                        numberOfLines={4}
                                        className="text-app-d flex flex-row items-center leading-4"
                                      >
                                        {reply.is_edited && (
                                          <>
                                            <RemixIcon
                                              className="-mb-px self-start"
                                              name="ri-pencil-fill"
                                              size={14}
                                              color={colors.app.d}
                                            />
                                            &nbsp;
                                          </>
                                        )}
                                        {reply.message}
                                      </Font>
                                    </View>
                                  </View>
                                </View>
                              )
                            )}
                          </View>
                        ) : (
                          <View
                            className={`px-4 py-4 border-b border-gray-200 w-full`}
                          >
                            <Font className="text-app-d1 self-center">
                              No Replies Found
                            </Font>
                          </View>
                        )}
                        <View className="h-18 w-full py-4 pl-4 pr-1 flex-row items-center border-t rounded-b-lg lg:bg-app-d/95 border-gray-200">
                          <View className="flex-1 mr-1">
                            {/* <Input type="text" placeholder="Type your message"></Input> */}
                            <View
                              className={`rounded-lg border border-gray-200 overflow-hidden ${
                                replyText?.length > 100 ? "h-20" : "h-10"
                              } bg-app-a`}
                            >
                              <TextInput
                                placeholder="Type your reply..."
                                className={`w-full ${
                                  Platform.OS === "web"
                                    ? "flex-1 py-2.5"
                                    : "h-full"
                                } px-3 min-w-0 lg:bg-app-d/95 lg:text-app-a`}
                                style={
                                  Platform.OS === "web" && {
                                    outlineColor: "transparent",
                                  }
                                }
                                multiline={true}
                                textAlignVertical="center"
                                value={replyText}
                                onChangeText={(e) => setReplyText(e)}
                                onKeyPress={(e) => {
                                  if (
                                    !e.shiftKey &&
                                    ["Enter"].includes(e.code)
                                  ) {
                                    e.preventDefault();
                                    onSendReplyHandler(
                                      replyText,
                                      isEditingReply
                                    );
                                  }
                                }}
                              ></TextInput>
                            </View>
                          </View>
                          {isEditingReply && (
                            <Pressable
                              className={`px-4 py-2 mr-1 self-end items-center justify-center rounded-lg ${
                                Platform.OS === "web"
                                  ? "active:bg-app-c2"
                                  : "active:bg-app-d3"
                              }`}
                              onPress={() => {
                                setReplyText("");
                                setIsEditingReply();
                              }}
                            >
                              <RemixIcon
                                name="ri-close-circle-fill"
                                color={
                                  screen?.width > 1023
                                    ? colors.app.a
                                    : colors.app.d
                                }
                              ></RemixIcon>
                            </Pressable>
                          )}
                          <Pressable
                            className={`pl-4 pr-4 py-2 self-end items-center justify-center rounded-lg ${
                              Platform.OS === "web"
                                ? "active:bg-app-c2"
                                : "active:bg-app-d3"
                            }`}
                            onPress={() => {
                              if (replyText)
                                onSendReplyHandler(replyText, isEditingReply);
                            }}
                          >
                            <RemixIcon
                              name="send-plane-2-line"
                              color={
                                screen?.width > 1023
                                  ? colors.app.a
                                  : colors.app.d
                              }
                            ></RemixIcon>
                          </Pressable>
                        </View>
                      </>
                    ) : (
                      <View className="flex-1 justify-center items-center pb-8">
                        <Font className="text-app-d1">
                          Listing Review Details Not Found
                        </Font>
                      </View>
                    )
                  ) : (
                    <View className="flex-1 justify-center items-center pb-8">
                      <Font className="text-app-d">Loading...</Font>
                    </View>
                  )}
                </Card>
              </View>
            </>
          ) : (
            <>
              <View className="mb-4 lg:my-4 p-0 lg:px-4 flex-col w-full">
                <View className="flex flex-row items-center">
                  <View className="hidden lg:flex flex-1 mr-4">
                    <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                      {step == 4 ? "Listing Reviews" : "Update Listings"}
                    </Font>
                  </View>
                  <View className="self-center justify-self-center flex-1">
                    <View className="relative w-full">
                      {step > 0 ? (
                        <View className="flex-row justify-between w-full">
                          <Pressable
                            onPress={() => {
                              setStep(1);
                            }}
                            className={`items-center px-4 py-2 border border-gray-200 rounded-lg ${
                              step == 1 ? "bg-app-e" : ""
                            }`}
                          >
                            <Font
                              className={`${
                                step == 1 ? "text-app-a" : "text-app-d"
                              }`}
                            >
                              Details
                            </Font>
                          </Pressable>
                          <Pressable
                            onPress={() => {
                              setStep(2);
                            }}
                            className={`items-center px-4 py-2 border border-gray-200 rounded-lg ${
                              step == 2 ? "bg-app-e" : ""
                            }`}
                          >
                            <Font
                              className={`${
                                step == 2 ? "text-app-a" : "text-app-d"
                              }`}
                            >
                              Services
                            </Font>
                          </Pressable>
                          <Pressable
                            onPress={() => {
                              setStep(3);
                            }}
                            className={`items-center px-4 py-2 border border-gray-200 rounded-lg ${
                              step == 3 ? "bg-app-e" : ""
                            }`}
                          >
                            <Font
                              className={`${
                                step == 3 ? "text-app-a" : "text-app-d"
                              }`}
                            >
                              Schedule
                            </Font>
                          </Pressable>
                          <Pressable
                            onPress={() => setStep(4)}
                            className={`items-center px-4 py-2 border border-gray-200 rounded-lg ${
                              step == 4 || step == 5 ? "bg-app-e" : ""
                            }`}
                          >
                            <Font
                              className={`${
                                step == 4 || step == 5
                                  ? "text-app-a"
                                  : "text-app-d"
                              }`}
                            >
                              Reviews
                            </Font>
                          </Pressable>
                        </View>
                      ) : null}
                    </View>
                  </View>
                </View>
              </View>
              <View className="p-0 lg:px-4">
                {/* {step == 0 ? (
                  <UpgradePackages
                    onPackageSelect={() => setStep(2)}
                    currentPackageID={listingData.package_purchase_id}
                    listingPackages={listingPackages}
                    setShowResponseModal={setShowResponseModal}
                    isEditingForm={isEditingForm}
                    setIsEditingForm={setIsEditingForm}
                    lid={lid}
                  />
                ) :  */}
                {step == 1 ? (
                  <ListingDetails
                    listingGeneralData={{ ...listingData }}
                    onUpgradePlanPress={() =>
                      navigation.navigate("manage-subscription")
                    }
                    // currentPackageDetails={
                    //   listingPackages
                    //     ? listingPackages?.find(
                    //         (v) => v.id === listingData.package_purchase_id
                    //       ) || {}
                    //     : undefined
                    // }
                    currencySymbol={currencySymbol}
                    coupons={listingCoupons}
                    setCoupons={setListingCoupons}
                    updateListingDetails={updateListingDetails}
                    lid={lid}
                    saveClicked={saveClicked}
                    setSaveClicked={setSaveClicked}
                    setShowResponseModal={setShowResponseModal}
                    resetClicked={resetClicked}
                    setResetClicked={setResetClicked}
                    isEditingForm={isEditingForm}
                    setIsEditingForm={setIsEditingForm}
                  />
                ) : step == 2 ? (
                  <ListingServices
                    bookable_services={{ ...listingData.bookable_services }}
                    updateListingDetails={updateListingDetails}
                    lid={lid}
                    enable_services={listingData.enable_services}
                    saveClicked={saveClicked}
                    setSaveClicked={setSaveClicked}
                    setShowResponseModal={setShowResponseModal}
                    resetClicked={resetClicked}
                    setResetClicked={setResetClicked}
                    isEditingForm={isEditingForm}
                    setIsEditingForm={setIsEditingForm}
                    currencySymbol={currencySymbol}
                    setCurrencySymbol={setCurrencySymbol}
                  />
                ) : step == 3 ? (
                  <ListingSchedule
                    bookable_services={{ ...listingData.bookable_services }}
                    available_slots={{ ...listingData.available_slots }}
                    opening_hours={{ ...listingData.opening_hours }}
                    updateListingDetails={updateListingDetails}
                    enable_opening_hours={listingData.enable_opening_hours}
                    enable_availability_calendar_slots={
                      listingData.enable_availability_calendar_slots
                    }
                    saveClicked={saveClicked}
                    setSaveClicked={setSaveClicked}
                    lid={lid}
                    setShowResponseModal={setShowResponseModal}
                    resetClicked={resetClicked}
                    setResetClicked={setResetClicked}
                    isEditingForm={isEditingForm}
                    setIsEditingForm={setIsEditingForm}
                  />
                ) : [4, 5].includes(step) ? (
                  <ListingReviews
                    lRIsLoading={lRIsLoading}
                    setLRIsLoading={setLRIsLoading}
                    refreshListingReviews={refreshListingReviews}
                    setRefreshListingReviews={setRefreshListingReviews}
                    lid={lid}
                    lrid={lrid}
                    step={step}
                    setStep={setStep}
                    setShowResponseModal={setShowResponseModal}
                    resetClicked={resetClicked}
                    setResetClicked={setResetClicked}
                    isEditingForm={isEditingForm}
                    setIsEditingForm={setIsEditingForm}
                  />
                ) : null}
              </View>
              <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
            </>
          )}
          {/* {step > 0 && step < 5 && (
            <View className="flex-row justify-between lg:justify-evenly mt-4 mb-8">
              <View className="w-40 lg:w-60">
                <Button
                  label="Reset"
                  type="regular"
                  onPress={() => step > 0 && setResetClicked(true)}
                ></Button>
              </View>
              <View className="w-40 lg:w-60">
                <Button
                  label="Save"
                  type="primary"
                  onPress={saveButtonHandler}
                  shape="rect"
                ></Button>
              </View>
            </View>
          )} */}
        </View>
      </ScrollView>
      {step > 0 && step < 4 && (
        <View
          className="absolute right-0 bottom-0 mb-4 mr-4 lg:mr-8"
          style={{ zIndex: 999999999 }}
        >
          <View className="relative mb-4">
            <Button
              className={isEditingForm ? "" : "h-18"}
              label={"Delete"}
              type="primary-outline"
              shape="circ"
              onPress={() => setIsDeleteModal(true)}
              iconName={"ri-delete-bin-line"}
              disabled={isDeleteModal}
            />
          </View>
          {!!isEditingForm && step == 1 && (
            <View className="mb-4">
              <Button
                label="Reset"
                type="regular"
                shape="circ"
                onPress={() => setResetClicked(true)}
                iconName="ri-restart-line"
              />
            </View>
          )}
          <View className="relative">
            <Button
              className={!!isEditingForm ? "" : "h-18"}
              label={!!isEditingForm ? "Save" : "Saved"}
              type={!!isEditingForm ? "primary" : "accent"}
              shape="circ"
              onPress={saveButtonHandler}
              iconName={
                !!isEditingForm ? "ri-upload-cloud-2-line" : "ri-cloudy-2-line"
              }
              disabled={!isEditingForm}
            />
            {!!isEditingForm ? (
              <></>
            ) : (
              <RemixIcon
                className="absolute self-center top-4 mt-[4px] right-3"
                name="ri-check-line"
                size={18}
                color={colors.app.a}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
}
