import React, { useCallback, useMemo, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Platform,
  Pressable,
  RefreshControl,
  TouchableOpacity,
  View,
} from "react-native";
import { ScrollView } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import {
  getUserProfile,
  logOut,
  sendGroupRequest,
} from "../../actions/auth-actions";
import { useAuthContext } from "../../contexts/auth-context";
import { parseProfileName } from "../../utils";
import { mediaURI } from "../../constants";
import { DefaultImages } from "../../constants/predefined-constants";
import useAppContextActions from "../../contexts";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { Button } from "../../components/input";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ConfirmModal from "../../components/general/modals/ConfirmModal";

export default function Account({ navigation, route }) {
  const [isLoading, setIsLoading] = useState();

  const [gettingUserInfo, setGettingUserInfo] = useState();
  const [text, setText] = useState("Request");
  const [showAPIResponse, setShowAPIResponse] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState({
    visible: false,
    onCancel: () => null,
    onConfirm: () => null,
    bodyText: "",
    confirmText: "",
    cancelText: "",
  });

  const back = navigation.canGoBack();
  const dismiss = () => {
    if (back) {
      navigation.goBack();
    } else {
      navigation.replace("home");
    }
  };

  const { resetAppContexts } = useAppContextActions();

  const { user, setUser } = useAuthContext();
  const {
    email = "",
    first_name = "",
    last_name = "",
    phone = "",
    profile_image_url = "",
    wallet_balance,
    rewards,
  } = useMemo(() => user || {}, [user]);

  const logoutHandler = async () => {
    try {
      setIsLoading(true);
      const res = await logOut();
      if (res?.status === 204) navigation.navigate("signin");
      resetAppContexts();
    } catch (err) {
      console.log(err);
      setShowAPIResponse({
        visible: true,
        onRequestClose: () =>
          setShowAPIResponse({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          }),
        bodyText: (err.response && err.response.data?.message) || err?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getProfile = useCallback(async () => {
    setGettingUserInfo(true);
    try {
      const res = await getUserProfile();
      if (res?.status) {
        if (res.status === 200) {
          setUser(res.data.data);
        } else if (res.status === 401) navigation.replace("signin");
      }
    } catch (error) {
      console.log("error?.code:", error?.code, error);
    } finally {
      setGettingUserInfo(false);
    }
  }, []);

  // const checkRequestSent =  AsyncStorage.setItem("RequestSent","false");
  const sendRequest = useCallback(async () => {
    // setGettingUserInfo(true);
    setText("Requested");
    await AsyncStorage.setItem("RequestSent", "true");
    try {
      const res = await sendGroupRequest(user.id);
      if (res?.status) {
        // setText("Requested");
      }
    } catch (error) {
      console.log("error?.code:", error?.code, error);
    } finally {
      // setGettingUserInfo(false);
    }
  }, []);
  AsyncStorage.setItem("RequestSent", "false");
  const checkRequestSent = AsyncStorage.getItem("RequestSent");
  return (
    <>
      <View className="flex-1 bg-transparent h-full relative">
        <Pressable
          className="w-full h-full absolute top-0 left-0 bg-transparent"
          onPress={Platform.OS === "web" ? dismiss : () => null}
        ></Pressable>
        <View
          className={`absolute w-full h-max lg:w-80 top-0 right-0 bottom-0 lg:bottom-0.5 lg:top-16 lg:right-0.5 bg-app-e lg:bg-white rounded-none lg:rounded-lg lg:border lg:border-gray-200 overflow-hidden`}
        >
          <View className="w-full h-16 bg-app-e lg:bg-app-a  items-center flex-row px-2 lg:px-4 border-b border-b-transparent lg:border-b-gray-200 ">
            <Pressable
              className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 flex lg:hidden"
              onPress={() => navigation.goBack()}
              style={{ marginTop: Platform.OS === "ios" ? 22 : 0 }}
            >
              <RemixIcon
                name="arrow-left-line"
                className="flex lg:hidden"
                color={colors.app.a}
                size="22"
              ></RemixIcon>
              {/* <RemixIcon
              name="arrow-left-line"
              className="hidden lg:flex"
              color={colors.app.d1}
              size="22"
            ></RemixIcon> */}
            </Pressable>
            <Font
              style={{ marginTop: Platform.OS === "ios" ? 22 : 0 }}
              className="text-app-a lg:text-app-d font-semibold text-[15px]  px-2"
            >
              Account
            </Font>
          </View>
          <View className="w-full items-center pt-4">
            <View className="w-20 h-20 rounded-full overflow-hidden border border-app-c3">
              <Image
                source={
                  profile_image_url
                    ? { uri: mediaURI + profile_image_url }
                    : DefaultImages.DP_PLACEHOLDER
                }
                className="w-full h-full"
              ></Image>
            </View>
            <View className="items-center py-4">
              <Font className="text-app-a lg:text-app-d" weight={700}>
                {parseProfileName(first_name, last_name)}
              </Font>
              <Font className="text-app-a1  lg:text-app-d1" weight={500}>
                {email}
              </Font>
            </View>
          </View>
          <View className="flex-1 h-full w-full">
            <View className="absolute w-full h-full top-0 left-0">
              <View className="h-24"></View>
              <View className="flex-1 bg-gray-100"></View>
            </View>
            <View className="absolute w-full h-full top-0 left-0 px-4 pb-4 lg:p-0">
              <ScrollView
                className="flex-1 overflow-y-scroll bg-white rounded-lg lg:rounded-none border lg:border-0 lg:border-t border-gray-200 p-4"
                refreshControl={
                  Platform.OS === "web" ? null : (
                    <RefreshControl
                      refreshing={gettingUserInfo}
                      onRefresh={getProfile}
                    />
                  )
                }
              >
                {/* <View className="flex-row items-center pb-4 border-b border-gray-200">
                <View className="h-full p-4 bg-app-h3 rounded-full mr-4">
                  <RemixIcon
                    name="honour-fill"
                    color={colors.app.h}
                  ></RemixIcon>
                </View>
                <View>
                  <Font className="text-app-h text-lg" weight={700}>
                    $ 100.00
                  </Font>
                  <Font className="text-app-d1 text-xs" weight={500}>
                    Collected Reward Balance
                  </Font>
                </View>
              </View> */}
                <View className="flex-row items-center pb-4 border-b border-gray-200">
                  {/* <View className="w-1/2 flex-row items-center">
                    <View className="h-10 w-10 bg-app-h3 rounded-full mr-4 items-center justify-center">
                      <RemixIcon
                        name="honour-fill"
                        color={colors.app.h}
                        size={22}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font className="text-app-h text-[15px]" weight={700}>
                        {rewards?.currency_symbol
                          ? rewards?.currency_symbol
                          : "$"}
                        &nbsp;
                        {(+(rewards?.amount || 0) / 100).toFixed(2)}
                      </Font>
                      <Font className="text-app-d1 text-xs" weight={500}>
                        Rewards
                      </Font>
                    </View>
                  </View> */}
                  <View className="w-1/2 flex-row items-center">
                    <View className="h-10 w-10 bg-app-e3 rounded-full mr-4 items-center justify-center ">
                      <RemixIcon
                        name="wallet-fill"
                        color={colors.app.e}
                        size={22}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font className="text-app-e text-[15px]" weight={700}>
                        {wallet_balance?.currency_symbol
                          ? wallet_balance?.currency_symbol
                          : "$"}
                        &nbsp;
                        {(+(wallet_balance?.amount || 0) / 100).toFixed(2)}
                      </Font>
                      <Font className="text-app-d1 text-xs" weight={500}>
                        Wallet
                      </Font>
                    </View>
                  </View>
                </View>
                <View className="flex-row justify-center items-center py-2 border-b border-gray-200">
                  <View className="flex-row flex-1 mx-2">
                    <Button
                      className="w-full place-self-center"
                      label="Withdraw"
                      type="primary"
                      onPress={() => navigation.navigate("withdraw")}
                    />
                  </View>
                  {/* <View className="flex-row flex-1 mx-2">
                    <Button
                      className="w-full place-self-center"
                      label="Topup"
                      type="primary"
                      onPress={() => navigation.navigate("top-up")}
                    />
                  </View> */}
                </View>
                {/* <View className="flex-row justify-center items-center py-2 border-b border-gray-200">
                  <Pressable
                    className="flex-row justify-center bg-app-f3 active:bg-app-f/30 items-center px-4 py-2 rounded-full border border-app-f"
                    onPress={() => navigation.replace("vendor")}
                  >
                    <RemixIcon
                      className="mr-2"
                      name="ri-chat-smile-3-fill"
                      color={colors.app.f}
                      size={18}
                    ></RemixIcon>
                    <Font className="text-app-f leading-4" weight={700}>
                      My Feed
                    </Font>
                    {/* </View> *\/}
                  </Pressable>
                  {/* <Button className="w-60" label="My Reviews" type="primary" /> *\/}
                </View> */}
                <View className="pb-8 lg:pb-2 pt-2">
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() =>
                      Platform.OS == "web"
                        ? navigation.replace("vendor")
                        : navigation.navigate("vendor")
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="ri-chat-smile-3-line"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      My Feed
                    </Font>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() =>
                      Platform.OS == "web"
                        ? navigation.replace("transactions")
                        : navigation.navigate("transactions")
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="ri-exchange-funds-line"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Transactions
                    </Font>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 justify-between items-center"
                    onPress={() => {
                      if (user?.is_group_creation_allowed) {
                        Platform.OS == "web"
                          ? navigation.replace("groups")
                          : navigation.navigate("groups");
                      }
                    }}
                  >
                    <View className="flex-row items-center">
                      <View className="mr-4">
                        <RemixIcon
                          name="ri-team-fill"
                          color={`${
                            user?.is_group_creation_allowed
                              ? colors.app.d
                              : "#d3d6dd"
                          }`}
                        ></RemixIcon>
                      </View>
                      <Font
                        className={`${
                          user?.is_group_creation_allowed
                            ? "text-app-d"
                            : "text-[#d3d6dd]"
                        }`}
                        weight={600}
                      >
                        Groups
                      </Font>
                    </View>
                    {!user?.is_group_creation_allowed && (
                      <Pressable
                        onPress={() => {
                          if (checkRequestSent == "true") {
                          } else {
                            sendRequest();
                          }
                        }}
                        className={`px-3 py-1 mr-2 flex justify-center items-center ${
                          text == "Request" ? "bg-app-e" : "bg-[#0b3955]"
                        } rounded-lg`}

                        // className="px-3 py-1 mr-2 flex justify-center items-center bg-app-e rounded-lg"
                      >
                        <Font className="text-xs text-app-a">
                          {/* {checkRequestSent == "true" ? "Requested" : "Request"} */}
                          {text}
                        </Font>
                      </Pressable>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() =>
                      Platform.OS == "web"
                        ? navigation.replace("invite-contact")
                        : navigation.navigate("invite-contact")
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="group-line"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Invite Contact
                    </Font>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() =>
                      Platform.OS == "web"
                        ? navigation.replace("update-profile")
                        : navigation.navigate("update-profile")
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="edit-box-line"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Update Profile
                    </Font>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() =>
                      Platform.OS == "web"
                        ? navigation.navigate("forgot-password")
                        : navigation.navigate("forgot-password")
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="lock-line"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Reset Password
                    </Font>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() =>
                      Platform.OS == "web"
                        ? navigation.replace("manage-addresses")
                        : navigation.navigate("manage-addresses")
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="road-map-line"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Manage Addresses
                    </Font>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() =>
                      Platform.OS == "web"
                        ? navigation.replace("manage-subscription")
                        : navigation.navigate("manage-subscription")
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="ri-secure-payment-fill"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Manage Subscription
                    </Font>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row py-3 items-center"
                    onPress={() => {
                      setShowConfirmModal({
                        visible: true,
                        onCancel: () => {
                          setShowConfirmModal({
                            visible: false,
                            bodyText: "",
                            onConfirm: () => null,
                            onCancel: () => null,
                          });
                        },
                        onConfirm: () => {
                          setShowConfirmModal({
                            visible: false,
                            bodyText: "",
                            onConfirm: () => null,
                            onCancel: () => null,
                          });
                          logoutHandler();
                        },
                        bodyText: "Do you really want to logout?",
                      });
                    }}
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="logout-box-r-line"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Logout
                    </Font>
                  </TouchableOpacity>
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
        {isLoading ? (
          <ActivityIndicator
            animating={isLoading}
            className="w-full lg:w-[134%] h-full absolute top-0 left-0 lg:-left-[34%] bg-red-50/80"
            color={colors.app.e}
            size="large"
          />
        ) : (
          <></>
        )}
        {showAPIResponse.visible ? (
          <ResponseModal
            visible={showAPIResponse.visible}
            onRequestClose={showAPIResponse.onRequestClose}
            bodyText={showAPIResponse.bodyText}
            dismissText={showAPIResponse?.dismissText}
          />
        ) : (
          <></>
        )}
        {showConfirmModal?.visible ? (
          <ConfirmModal
            visible={showConfirmModal.visible}
            onCancel={showConfirmModal?.onCancel}
            bodyText={showConfirmModal?.bodyText}
            confirmText={showConfirmModal?.confirmText}
            cancelText={showConfirmModal?.cancelText}
            onConfirm={showConfirmModal?.onConfirm}
          />
        ) : (
          <></>
        )}
      </View>
    </>
  );
}
