import React, { useContext } from "react";
import { Platform, View } from "react-native";
import * as Linking from "expo-linking";

import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Font } from "../../components/font";
import ListingProvider from "../../contexts/listing-context";
import { AuthProvider } from "../../contexts/auth-context";
import { SharedContext } from "../../contexts/shared-context";
import ProductsProvider from "../../contexts/product-context";
import BookingsProvider from "../../contexts/bookings-context";
import { pageTitle } from "../../utils";
import { navigate, navigationRef, replace } from "../../utils/navigation-util";
import OrdersProvider from "../../contexts/orders-context";
import VendorFeedProvider from "../../contexts/vendor-feed-context";
import MessagesProvider from "../../contexts/messages-context";

import Root from "./root";
import Signup from "./signup";
import Signin from "./signin";
import ForgotPassword from "./forgot-password";
import UpdatePassword from "./update-password";
import ConfirmEmail from "./confirm-email-success";
import ExpiredLink from "./expired-link";
import InvalidLink from "./invalid-link";
import UpdatePasswordSuccessfullyPage from "./update-password-successful";
import EmailSentSuccessfullyPage from "./email-sent-successful";
import { useNotifications } from "../../utils/useNotifications";
import { useEffect } from "react";
import { ConfirmEmailVerify } from "./confirm-email-verify";
import ConfirmEmailSuccess from "./confirm-email-success";
import ConfirmEmailSent from "./confirm-email-sent";
import { StripeContextProvider } from "../../contexts/stripe-context";
import VerifyOtp from "./verifyOtp";
import { BlogsContextProvider } from "../../contexts/blogs-context";
import { NotificationProvider } from "../../contexts/notifications-context";

const prefix = Linking.createURL("/");

const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      "forgot-password": "forgot-password",
      "email-sent-successful": "email-sent-successful",
      "update-password": "update-password",
      "confirm-email-success": "confirm-email-success",
      "confirm-email-sent": "confirm-email-sent",
      "confirm-email-verify": "confirm-email-verify",
      "invalid-link": "invalid-link",
      "expired-link": "expired-link",
      "update-password-successful": "update-password-successful",
      signin: "signin",
      signup: "signup",
      app: {
        path: "/",
        screens: {
          home: "",
          "about-us": "about-us",
          "contact-us": "contact-us",
          post: "post/:slug",
          faq: "faq",
          contacted: "contacted",
          "manage-subscription": "manage-subscription",
          "terms-conditions": "terms-conditions",
          "privacy-policy": "privacy-policy",
          blog: "blog",
          wishlist: "wishlist",
          bookings: "bookings",
          orders: "orders",
          settings: "settings",
          messages: {
            path: "messages/",
            screens: {
              chat: "chat",
            },
          },
          search: "search",
          "search-modal": "search-modal",
          cart: "cart",
          // account: "account",
          vendor: {
            path: "/vendor",
            screens: {
              "vendor-feed": "/feed",
              "vendor-feed-comments": "/feed/:slug/comments",
              "vendor-reviews": "/reviews",
              "vendor-review": "/reviews/:slug",
            },
          },
          withdraw: "withdraw",
          // "top-up": "top-up",
          listings: "listings",
          listing: "listing",
          "add-listing": "add-listing",
          "update-listing": "update-listing",
          "add-coupons": "add-coupons",
          "update-coupons": "update-coupons",
          coupons: "coupons",
          products: "products",
          product: "product",
          "add-product": "add-product",
          "update-product": "update-product",
          // vendor: "vendor",
          checkout: "checkout",
          "booking-details": "booking-details",
          "order-details": "order-details",
          "manage-addresses": "manage-addresses",
          "update-address": "update-address",
          "add-address": "add-address",
          "update-profile": "update-profile",
          transactions: "transactions",
          "invite-contact": "invite-contact",
          verifyOtp: "verifyOtp",
          groups: "groups",
          "view-groups": "view-groups",
          "group-details": "group-details",
          "add-member": "add-member",
          "group-join-requests": "group-join-requests",
        },
      },
    },
  },
};
const Stack = createNativeStackNavigator();

export const VendorRouting = () => {
  // const { user } = useContext(AuthContext);
  const { setRoute } = useContext(SharedContext);

  useEffect(() => {
    // Deep linking event listener
    const handleUrl = ({ url }) => {
      if (!url) {
        return;
      }
      // Process the incoming deep link URL here
      // console.log("Received deep link 1:", url);
      let urlStub = url.split("//")[1];
      if (urlStub) {
        let urlStubArray = urlStub.split("/");
        if (urlStubArray[0] == "") {
          urlStubArray.splice(0, 1);
        }
        if (Platform.OS === "web") {
          urlStubArray.shift();
        }
        let urlHash = urlStubArray[1];

        if (urlStubArray[0] === "set-new-password") {
          navigationRef.current?.navigate("update-password", { urlHash });
        } else if (urlStubArray[0] === "verify-email") {
          navigationRef.current?.navigate("confirm-email-verify", {
            urlHash: urlHash,
          });
        } else if (urlStubArray[0] === "chat") {
          setRedirectChatUserID({
            uid: parseInt(urlStubArray[1].toString()),
            callback: () => navigationRef.current?.navigate("messages"),
          });
        } else if (urlStubArray[0] === "booking_details") {
          navigationRef.current?.navigate("booking-details", {
            bid: parseInt(urlStubArray[1].toString()),
          });
        } else if (urlStubArray[0] === "order_details") {
          navigationRef.current?.navigate("order-details", {
            oid: parseInt(urlStubArray[1].toString()),
          });
        }
      }
    };

    let subscription = Linking.addEventListener("url", handleUrl);
    // Handle initial deep link when the app is opened from a deep link
    Linking.getInitialURL().then((url) => {
      if (url) {
        // Process the initial deep link URL here
        // console.log("Received initial deep link 1:", url);
        handleUrl({ url });
      }
    });
    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <AuthProvider>
      <MessagesProvider>
        <StripeContextProvider>
          <BlogsContextProvider>
            <ListingProvider>
              <ProductsProvider>
                <BookingsProvider>
                  <OrdersProvider>
                    <VendorFeedProvider>
                      <NotificationProvider>
                        <NavigationContainer
                          ref={navigationRef}
                          linking={linking}
                          fallback={
                            <View className="flex-1 justify-center items-center">
                              <Font>Loading...</Font>
                            </View>
                          }
                        >
                          <Stack.Navigator
                            screenOptions={{ animation: "none" }}
                            screenListeners={{
                              state: (e) => {
                                // const checkSignedInStatus = async () => {
                                //   const token = await isSignedIn();
                                //   // if (!token) {
                                //   //   replace("/signin");
                                //   // }
                                // };
                                // checkSignedInStatus();
                                // let name = e?.data?.state?.routes[0]?.state?.routes;
                                const getRoute = (routes) => {
                                  const lastIndexRoute =
                                    routes?.[routes.length - 1]?.state?.routes;
                                  const hasInnerRoutes =
                                    lastIndexRoute?.state?.routes;
                                  if (hasInnerRoutes) {
                                    return getRoute(hasInnerRoutes);
                                  } else return lastIndexRoute;
                                };
                                let routes = e?.data?.state?.routes;
                                if (routes) {
                                  const route = getRoute(routes);
                                  setRoute(route);
                                }
                              },
                            }}
                          >
                            <Stack.Screen
                              name="app"
                              component={Root}
                              options={{
                                title: pageTitle("Home"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="signin"
                              component={Signin}
                              options={{
                                title: pageTitle("Sign In"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="signup"
                              component={Signup}
                              options={{
                                title: pageTitle("Sign Up"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="forgot-password"
                              component={ForgotPassword}
                              options={{
                                title: pageTitle("Forgot Password"),
                                headerShown: true,
                              }}
                            />
                            <Stack.Screen
                              name="email-sent-successful"
                              component={EmailSentSuccessfullyPage}
                              options={{
                                title: pageTitle(
                                  "Email Sent Successfully Page"
                                ),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="update-password"
                              component={UpdatePassword}
                              options={{
                                title: pageTitle("Set New Password"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="update-password-successful"
                              component={UpdatePasswordSuccessfullyPage}
                              options={{
                                title: pageTitle(
                                  "Set New Password Successfully Page"
                                ),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="confirm-email-success"
                              component={ConfirmEmailSuccess}
                              options={{
                                title: pageTitle("Confirm Email Success"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="verifyOtp"
                              component={VerifyOtp}
                              options={{
                                title: pageTitle("verify Otp"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="confirm-email-verify"
                              component={ConfirmEmailVerify}
                              options={{
                                title: pageTitle("Confirm Email Verify"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="confirm-email-sent"
                              component={ConfirmEmailSent}
                              options={{
                                title: pageTitle("Confirm Email Sent"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="expired-link"
                              component={ExpiredLink}
                              options={{
                                title: pageTitle("Expired Link"),
                                headerShown: false,
                              }}
                            />
                            <Stack.Screen
                              name="invalid-link"
                              component={InvalidLink}
                              options={{
                                title: pageTitle("Invalid Link"),
                                headerShown: false,
                              }}
                            />
                          </Stack.Navigator>
                        </NavigationContainer>
                      </NotificationProvider>
                    </VendorFeedProvider>
                  </OrdersProvider>
                </BookingsProvider>
              </ProductsProvider>
            </ListingProvider>
          </BlogsContextProvider>
        </StripeContextProvider>
      </MessagesProvider>
    </AuthProvider>
  );
};
