import React, { useContext, useEffect } from "react";
import { Image, View, Pressable, ImageBackground } from "react-native";
import { Font, A } from "../../components/font";
import colors from "../../../colors";
import { ScrollView } from "react-native-gesture-handler";
import { AntDesign } from "@expo/vector-icons";
import axiosInstance from "../../constants/axios-config";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { apiURIV2 } from "../../constants";
import { useSharedContext } from "../../contexts/shared-context";

export const ConfirmEmailVerify = ({ navigation, route }) => {
  const { setTemporaryData } = useSharedContext();
  useEffect(() => {
    onEmailVerify();
  }, [route.params]);

  const hash = route.params.urlHash;

  const onEmailVerify = async () => {
    try {
      const res = await axiosInstance.post(apiURIV2 + "/auth/verify-email", {
        hash: hash,
      });
      if (res.data.status === "success") {
        navigation.navigate("confirm-email-success", {
          otp_hash: res.data.otp_hash,
        });
        if (res.data?.otp) setTemporaryData({ mobileSMSOTP: res.data.otp });
      } else if (res.data.status === "hash_invalid") {
        navigation.navigate("invalid-link");
      } else if (res.data.status === "hash_expired") {
        navigation.navigate("expired-link");
      }
    } catch (err) {
      console.log("email-verify, onEmailVerify err : ", err);
    }
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, justifyContent: "flex-start" }}
          keyboardShouldPersistTaps="always"
        >
          <View className="w-80 items-center mt-16">
            <Pressable onPress={() => navigation.replace("app")}>
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 mb-8"
              ></Image>
            </Pressable>
            <View className="h-[600px] justify-center items-center">
              <View className="rounded-full w-10 h-10 bg-app-g items-center justify-center">
                <AntDesign name="loading1" size={24} color={colors.app.a} />
              </View>
              <Font className="text-lg my-4 text-center">
                Verifying your Email...
              </Font>
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
    </View>
  );
};
