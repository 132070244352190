import React from "react";

const INITIAL_STATE = {
  tabStatus: { curr: undefined, prev: undefined },
  setTabStatus: () => ({ curr: undefined, prev: undefined }),
  products: null,
  setProducts: () => [],
  pagination: null,
  setPagination: () => ({ from: null, to: null, total: null }),
  productReviews: null,
  setProductReviews: () => [],
  productReviewsPagination: null,
  setProductReviewsPagination: () => [],
  productDetailsData: null,
  setProductDetailsData: () => ({}),
  productVariantData: null,
  productCategories: null,
  setProductCategories: () => null,
  productCoupons: [],
  setProductCoupons: () => null,
  productPackages: null,
  setProductPackages: () => null,
  appendProductVariantGalleryImages: () => null,
  removeProductVariantGalleryImages: () => null,
  productReviewDetails: null,
  setProductReviewDetails: () => null,
  deleteProductDetailsData: () => null,
  resetProductsContext: () => null,
};

export const ProductsContext = React.createContext(INITIAL_STATE);

const ProductsReducer = (state, action) => {
  switch (action.type) {
    case "ADD_PRODUCTS":
      return { ...state, products: action.payload };
    case "PAGINATION":
      return { ...state, pagination: action.payload };
    case "SET_TAB_STATUS":
      return { ...state, tabStatus: action.payload };
    case "SET_PRODUCT_DATA":
      return { ...state, productDetailsData: action.payload };
    case "SET_PRODUCT_REVIEW_DATA":
      return { ...state, productReviewDetails: action.payload };
    case "SET_PRODUCT_VARIANT_DATA":
      return { ...state, productVariantData: action.payload };
    case "SET_PRODUCT_CATEGORIES":
      return { ...state, productCategories: action.payload };
    case "SET_PRODUCT_COUPONS":
      return { ...state, productCoupons: action.payload };
    case "SET_PRODUCT_PACKAGES":
      return { ...state, productPackages: action.payload };
    case "APPEND_PRODUCT_GALLERY":
      return { ...state, productVariantData: action.payload };
    case "ADD_PRODUCT_REVIEWS":
      return { ...state, productReviews: action.payload };
    case "SET_PRODUCT_REVIEWS_PAGINATION":
      return { ...state, productReviewsPagination: action.payload };
    case "RESET_PRODUCTS_STATE":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function ProductsProvider({ children }) {
  const [state, dispatch] = React.useReducer(ProductsReducer, INITIAL_STATE);

  return (
    <ProductsContext.Provider
      value={{
        tabStatus: state.tabStatus,
        setTabStatus: ({ curr, prev }) => {
          dispatch({
            type: "SET_TAB_STATUS",
            payload: { curr: curr, prev: prev },
          });
        },
        products: state.products,
        setProducts: ({ data, push = false, unshift = false }) => {
          dispatch({
            type: "ADD_PRODUCTS",
            payload: unshift
              ? [data, ...(state.products || [])]
              : push
              ? [...(state.products || []), ...(data || [])]
              : Array.isArray(data)
              ? data
              : [data],
          });
        },
        productReviews: state.productReviews,
        setProductReviews: ({ data, pid, push = false }) => {
          dispatch({
            type: "ADD_PRODUCT_REVIEWS",
            payload: {
              ...(state.productReviews || {}),
              [pid]: push
                ? { ...data, ...(state.productReviews?.[pid] || {}) }
                : data,
            },
          });
        },
        productReviewsPagination: state.productReviewsPagination,
        setProductReviewsPagination: ({ from, to, total }) => {
          dispatch({
            type: "SET_PRODUCT_REVIEWS_PAGINATION",
            payload: { from, to, total },
          });
        },
        pagination: state.pagination,
        setPagination: ({ from, to, total }) => {
          dispatch({ type: "PAGINATION", payload: { from, to, total } });
        },
        productDetailsData: state.productDetailsData,
        setProductDetailsData: ({ data, pid, push = true }) => {
          if (data?.variants?.length) {
            const vd = {};
            data?.variants?.forEach((v) => {
              vd[v.id] = v;
            });
            dispatch({
              type: "SET_PRODUCT_VARIANT_DATA",
              payload: { ...(state.productVariantData || {}), [pid]: vd },
            });
            delete data.variants;
          }
          dispatch({
            type: "SET_PRODUCT_DATA",
            payload: push
              ? {
                  ...(state.productDetailsData || {}),
                  [pid]: data || state.productDetailsData?.[pid] || {},
                }
              : { [pid]: data || state.productDetailsData?.[pid] || {} },
          });
        },
        deleteProductDetailsData: ({ pid }) => {
          if (!pid) return;
          delete state.productDetailsData?.[pid];
          dispatch({
            type: "SET_PRODUCT_DATA",
            payload: { ...(state.productDetailsData || {}) },
          });
        },
        productVariantData: state.productVariantData,
        productCategories: state.productCategories,
        setProductCategories: ({ data }) => {
          dispatch({ type: "SET_PRODUCT_CATEGORIES", payload: data || [] });
        },
        productCoupons: state.productCoupons,
        setProductCoupons: (data) => {
          dispatch({ type: "SET_PRODUCT_COUPONS", payload: data || [] });
        },
        appendProductVariantGalleryImages: ({ data, pid, vid }) => {
          let productVariantData = state.productVariantData;
          productVariantData[pid][vid].gallery_images = [
            ...(productVariantData[pid][vid]?.gallery_images || []),
            ...data,
          ];
          dispatch({
            type: "APPEND_PRODUCT_GALLERY",
            payload: productVariantData,
          });
        },
        removeProductVariantGalleryImages: ({ imageId, pid, vid }) => {
          let productVariantData = state.productVariantData;
          let galleryImages = productVariantData[pid][vid]?.gallery_images;
          const i = galleryImages?.findIndex((v) => v.id == imageId);
          if (i > -1) {
            galleryImages.splice(i, 1);
            productVariantData[pid][vid].gallery_images = galleryImages;
            dispatch({
              type: "APPEND_PRODUCT_GALLERY",
              payload: productVariantData,
            });
          }
        },
        productPackages: state.productPackages,
        setProductPackages: ({ data }) => {
          dispatch({ type: "SET_PRODUCT_PACKAGES", payload: data || [] });
        },
        productReviewDetails: state.productReviewDetails,
        setProductReviewDetails: ({ data, prid }) => {
          dispatch({
            type: "SET_PRODUCT_REVIEW_DATA",
            payload: { ...(state.productDetailsData || {}), [prid]: data },
          });
        },
        resetProductsContext: ({ data } = {}) => {
          dispatch({ type: "RESET_PRODUCTS_STATE", payload: data || [] });
        },
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export const useProductsContext = () => React.useContext(ProductsContext);
