import React, { useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { FaqItem } from "../../components/faq";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import colors from "../../../colors";
import { Button } from "../../components/input";

export default function Faq({ navigation, route }) {
  const [isLoading, setIsLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0 });
  const [hasMoreData, setHasMoreData] = useState(true);

  const limitPerPage = 10;
  const getFaqsData = async () => {
    try {
      setIsLoading(false);
      const res = await axiosInstance.get(
        apiURIV2 +
          `/content/faqs?limit=${limitPerPage}&offset=${
            pagination.offset
          }&reader=${"vendor"}`
      );
      if (faqData.length + res.data["data"].length == res.data.total_count) {
        setHasMoreData(false);
      } else {
        setHasMoreData(true);
      }
      setPagination({
        offset: faqData.length + res.data["data"].length - limitPerPage,
      });
      setFaqData([...faqData, ...res.data["data"]]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFaqsData();
  }, []);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="flex-1 rounded-t-2xl lg:rounded-none bg-gray-50">
        <ScrollView>
          <View className=" max-w-screen-lg w-full p-4 mx-auto">
            <View className="my-5 hidden lg:flex">
              <Font className="text-lg font-semibold text-app-d">
                Frequently Asked Questions
              </Font>
            </View>
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <View
                className=" w-full lg:w-4/6 p-0 lg:pr-4 pt-0"
                style={{ height: "auto" }}
              >
                <View className="flex-1">
                  {isLoading ? (
                    <ActivityIndicator
                      animating={true}
                      color={colors.app.e}
                      size={"large"}
                      className="mb-4"
                    />
                  ) : faqData?.length ? (
                    faqData?.map((info) => (
                      <FaqItem key={info.question} info={info}></FaqItem>
                    ))
                  ) : (
                    <View className="w-full flex flex-row justify-center items-center bg-app-a rounded-lg py-8 px-4 border border-gray-200 mb-4">
                      <Font className="text-app-d1">No FAQs here</Font>
                    </View>
                  )}
                </View>
                {hasMoreData && (
                  <View className="w-full flex flex-col justify-center items-end">
                    <Button
                      onPress={getFaqsData}
                      label="Show more"
                      className="self-end flex flex-col lg:w-40"
                    />
                  </View>
                )}
              </View>

              <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
                <Widget></Widget>
              </View>
            </View>
            <Footer></Footer>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
