import _ from "lodash";
import React from "react";

const INITIAL_STATE = {
  coupons: [],
  setCoupons: () => [],
  pagination: { from: null, to: null, total: null },
  setPagination: () => ({ from: null, to: null, total: null }),
  tabStatus: { curr: undefined, prev: undefined },
  setTabStatus: () => ({ curr: undefined, prev: undefined }),
  couponsData: null,
  setCouponsData: () => ({}),
  resetCouponsContext: () => null,
};

export const CouponsContext = React.createContext(INITIAL_STATE);

const CouponsReducer = (state, action) => {
  switch (action.type) {
    case "SET_COUPONS":
      return { ...state, coupons: action.payload };
    case "PAGINATION":
      console.log("action.payload:", action.payload);
      
      return { ...state, pagination: action.payload };
    case "SET_TAB_STATUS":
      return { ...state, tabStatus: action.payload };
    case "SET_COUPONS_DATA":
      return { ...state, couponsData: action.payload };
    case "RESET_COUPONS_STATES":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function CouponsProvider({ children }) {
  const [state, dispatch] = React.useReducer(CouponsReducer, INITIAL_STATE);

  return (
    <CouponsContext.Provider
      value={{
        coupons: state.coupons,
        setCoupons: ({ data, async = true, push = false }) => {
          dispatch({
            type: "SET_COUPONS",
            payload: push ? [...(state.coupons || []), ...data] : data,
          });
        },
        pagination: state.pagination,
        setPagination: ({ from, to, total }) => {
          dispatch({ type: "PAGINATION", payload: { from, to, total } });
        },
        tabStatus: state.tabStatus,
        setTabStatus: ({ curr, prev }) => {
          dispatch({
            type: "SET_TAB_STATUS",
            payload: { curr: curr, prev: prev },
          });
        },

        couponsData: state.couponsData,
        setCouponsData: ({ data, id, push = true }) => {
          const parseSlots = (parseObj) => {
            const getMinutes = (time_in_min) => {
              let m = time_in_min % 60;
              if (m < 10) {
                m = "0" + m;
              }
              return m.toString();
            };
            const getHours = (time_in_min) => {
              let h = parseInt(time_in_min / 60);
              if (h < 10) {
                h = "0" + h;
              }
              return h.toString();
            };
            let data = _.cloneDeep(parseObj);
            for (const key in data) {
              data[key] = data[key].map((v) => {
                v.start_in_min = {
                  h: getHours(v.start_in_min),
                  m: getMinutes(v.start_in_min),
                  tim: v.start_in_min,
                };
                v.end_in_min = {
                  h: getHours(v.end_in_min),
                  m: getMinutes(v.end_in_min),
                  tim: v.end_in_min,
                };
                return v;
              });
            }
            return data;
          };
          data.opening_hours = parseSlots(data.opening_hours);
          data.available_slots = parseSlots(data.available_slots);
          dispatch({
            type: "SET_COUPONS_DATA",
            payload: push
              ? { ...state.couponsData, [id]: data || {} }
              : { [id]: data || {} },
          });
        },
        updateCouponsData: (data) => {
          let newData;
          if (typeof data === "function") {
            newData = data(state.couponsData);
          }
          dispatch({
            type: "SET_COUPONS_DATA",
            payload: { ...(newData ? newData : data) },
          });
        },
        resetCouponsContext: ({ data } = {}) => {
          dispatch({ type: "RESET_COUPONS_STATES", payload: data || [] });
        },
      }}
    >
      {children}
    </CouponsContext.Provider>
  );
}

export const useCouponsContext = () => React.useContext(CouponsContext);
