import React from "react";
import { Image, Platform, Pressable, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../colors";
import { mediaURI } from "../constants";
import { parseProfileName } from "../utils";
import { Font } from "./font";
import { StackActions } from "@react-navigation/native";
import { useSharedContext } from "../contexts/shared-context";
import NumericBadge from "./general/badges/NumericBadge";
import { useNotificationContext } from "../contexts/notifications-context";

export const Navbar = ({ navigation, route, ...props }) => {
  const {
    screen: { width },
  } = useSharedContext();
  const isLargeScreen = width > 1023;
  const { unreadCount } = useNotificationContext();

  let user = props.user;
  const menus = [
    { title: "Home", url: "home" },
    { title: "Wishlist", url: "wishlist" },
    { title: "Listings", url: "listings" },
    { title: "Bookings", url: "bookings" },
    { title: "Products", url: "products" },
    { title: "Orders", url: "orders" },
    { title: "Messages", url: "messages" },
    { title: "Settings", url: "settings" },
    { title: "Coupons", url: "coupons" },
  ];
  const menusGuest = [
    { title: "Home", url: "home" },
    { title: "Blog", url: "blog" },
    { title: "FAQ", url: "faq" },
    { title: "TermsCondition", url: "terms-conditions" },
    { title: "About Us", url: "about-us" },
    { title: "Contact Us", url: "contact-us" },
  ];

  return (
    <View
      className={`w-full h-16 bg-app-e lg:bg-app-a border-b border-b-transparent lg:border-b-gray-200 items-center flex-row px-2 lg:px-4 ${
        route.name === "messages" && user ? "hidden lg:flex" : ""
      }`}
    >
      {props.back &&
      ![...[...menus, ...menusGuest].map((e) => e.url)].includes(route.name) ? (
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 flex lg:hidden"
          onPress={() => navigation.goBack()}
        >
          <RemixIcon
            name="arrow-left-line"
            className="flex lg:hidden"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
          <RemixIcon
            name="arrow-left-line"
            className="hidden lg:flex"
            color={colors.app.d1}
            size="22"
          ></RemixIcon>
        </Pressable>
      ) : null}
      {route.name === "home" ? (
        user ? (
          <>
            <Pressable
              className="p-2 flex-row active:bg-app-c2 rounded-2xl flex lg:hidden  w-40"
              onPress={() => navigation.navigate("account")}
            >
              <Image
                source={
                  user.profile_image_url
                    ? { uri: mediaURI + user.profile_image_url }
                    : require("../../assets/userImage.jpg")
                }
                className="w-10 h-10 rounded-full"
              ></Image>
              <View className="mx-2">
                <Font className="text-xs font-medium text-app-a">
                  {new Date().getHours() < 12
                    ? "Good Morning"
                    : new Date().getHours() < 18
                    ? "Good Afternoon"
                    : "Good Evening"}
                </Font>
                <Font className="font-semibold text-app-a">
                  {parseProfileName(user.first_name, user.last_name)}
                </Font>
              </View>
            </Pressable>
            <View className="flex-1 flex lg:hidden"></View>
          </>
        ) : (
          <>
            <Pressable
              className="p-2 flex-row active:bg-app-c2 rounded-2xl flex lg:hidden w-40"
              onPress={() =>
                navigation.dispatch(StackActions.replace("signin"))
              }
            >
              <Image
                source={require("../../assets/userImage.jpg")}
                className="w-10 h-10 rounded-full"
              />
              <View className="mx-2 justify-center">
                <Font className="font-semibold text-app-a">Sign In</Font>
              </View>
            </Pressable>

            <View className="flex-1 flex lg:hidden"></View>
          </>
        )
      ) : null}
      {route.name === "search" ? (
        <Pressable
          className="bg-app-a rounded flex-1 h-10 mx-2 flex lg:hidden px-2 py-1"
          onPress={() => navigation.navigate("search-modal")}
        >
          <Font className="text-xs text-app-d1" weight={500}>
            Product
          </Font>
          <Font weight={600} className="-mt-1 text-app-d">
            Geen sytlist hoodie
          </Font>
        </Pressable>
      ) : null}
      {route.name !== "home" && route.name !== "search" ? (
        <>
          <Font className="text-app-a lg:text-app-d font-semibold text-[15px] flex lg:hidden px-2">
            {props?.options?.title}
          </Font>
          <View className="flex-1 flex lg:hidden"></View>
        </>
      ) : null}
      {/* Web Header Navbar */}
      <View className="flex-1 hidden lg:flex flex-row">
        {/* {(user ? menus : menusGuest).map((e, i) => (
          <View key={i}>
            <Pressable
              className="p-4 active:bg-app-c2 rounded-lg"
              onPress={() => replace(e.url)}
            >
              <Font weight={600}>{e.title}</Font>
            </Pressable>
            <View className="flex-1 flex lg:hidden"></View>
          </View>
        ))} */}
      </View>
      {/* <Pressable
        className="p-3 rounded-full active:bg-app-c"
        onPress={() => navigation.navigate("cart")}
      >
        <RemixIcon
          name="shopping-cart-line"
          className="flex lg:hidden"
          color={colors.app.a}
          size="22"
        />
        <RemixIcon
          name="shopping-cart-line"
          className="hidden lg:flex"
          color={colors.app.d1}
          size="22"
        />
      </Pressable> */}
      {/*User Profile View*/}
      {user ? (
        <>
          <Pressable
            className="p-3 rounded-full active:bg-app-c2 relative"
            onPress={() => navigation.navigate("notifications")}
          >
            <RemixIcon
              name="notification-4-line"
              className="flex"
              color={isLargeScreen ? colors.app.d1 : colors.app.a}
              size="22"
            />
            <NumericBadge count={unreadCount || 0} />
          </Pressable>
          <Pressable
            className="p-2 flex-row active:bg-app-c2 rounded-2xl hidden lg:flex  w-40"
            onPress={() => navigation.navigate("account")}
          >
            <Image
              source={
                user.profile_image_url
                  ? { uri: mediaURI + user.profile_image_url }
                  : require("../../assets/userImage.jpg")
              }
              className="w-10 h-10 rounded-full"
            />
            <View className="mx-2">
              <Font className="text-xs font-medium text-app-d1">
                {new Date().getHours() < 12
                  ? "Good Morning"
                  : new Date().getHours() < 18
                  ? "Good Afternoon"
                  : "Good Evening"}
              </Font>
              <Font className="font-semibold text-app-d">
                {parseProfileName(user.first_name, user.last_name)}
              </Font>
            </View>
          </Pressable>
        </>
      ) : (
        <Pressable
          className="p-2 flex-row active:bg-app-c2 rounded-2xl hidden lg:flex  w-40"
          onPress={() => navigation.dispatch(StackActions.replace("signin"))}
        >
          <Image
            source={require("../../assets/userImage.jpg")}
            className="w-8 h-8 rounded-full"
          ></Image>
          <View className="mx-2 justify-center">
            <Font className="font-semibold text-app-d">Sign In</Font>
          </View>
        </Pressable>
      )}
    </View>
  );
};
