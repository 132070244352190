import React, { useEffect, useState } from "react";
import { Platform, Pressable, View } from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Footer } from "../../components/footer";
import { ScrollView } from "react-native";
import { Input } from "../../components/input";
import { deleteGroup, EditGroup } from "../../actions/auth-actions";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { PleaseWaitModal } from "../../components/modal";
export default function GroupSettings({
  navigation,
  route,
  groupId,
  checkRefreshList,
  isPrivate,
  groupStatus,
  userId,
}) {
  const [stats, setStats] = useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  const [deleteModal, setIsDeleteModal] = React.useState(false);
  const [isPleaseWaitModal, setIsPleaseWaitModal] = React.useState(false);
  const [IsPrivate, setIsPrivate] = React.useState(isPrivate);
  const [isDisableGroup, setIsDisableGroup] = React.useState(groupStatus == "owner" ? true : false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });

  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };
  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };
  const itemsPerPage = 2;
  const totalItems = activities.length;

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };
  const deleteAGroup = async () => {
    try {
      setIsDeleteModal(false);
      setIsPleaseWaitModal(true);
      let res = await deleteGroup(groupId);
      if (res.status === 204) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Group Deleted Successfully !!!",
          DismissText: "Dismiss",
        });
        setTimeout(() => {
          checkRefreshList();
        }, 1000);
        setTimeout(() => {
          navigation.goBack();
        }, 1000);
        console.log("deleted", res);
      } else {
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
        setIsPleaseWaitModal(false);
        console.log("failed to delete", res);
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };
  const editGroupSettings = async () => {
    try {
      const formData = new FormData();

      console.log("IsPrivate", IsPrivate);

      formData.append("is_private", IsPrivate);
      formData.append(
        "limit_group_posting_for_role",
        isDisableGroup == true ? "owner" : "admin"
      );

      const res = await EditGroup(userId, groupId, formData);
      if ((res.status = 200)) {
        if (isModalVisible==true) {
          setResponseModal({
            visible: isModalVisible,
            message: "Group Updated Successfully !!!",
            DismissText: "Dismiss",
          });
          setTimeout(() => {
            checkRefreshList();
          }, 2000);
          setTimeout(() => {
            navigation.goBack();
          }, 2000);
        }
      } else {
        if (isModalVisible) {
          setResponseModal({
            visible: isModalVisible,
            message: "Something went Wrong !!!",
            DismissText: "Dismiss",
          });
          setTimeout(() => {
            checkRefreshList();
          }, 2000);
          setTimeout(() => {
            navigation.goBack();
          }, 2000);
        }
        console.log("failed", res.data);
        checkRefreshList();
      }
    } catch (error) {}
  };

  useEffect(() => {
    editGroupSettings();
  }, [IsPrivate, isDisableGroup]);

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ConfirmModal
        visible={deleteModal}
        onCancel={closeDeleteModal}
        onConfirm={deleteAGroup}
        confirmText={"Delete"}
        bodyText={"Are you sure you want to delete Group ?"}
        BodyComponent={false}
      ></ConfirmModal>
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <View className=" max-w-screen-lg w-full mx-auto h-full">
        <View className=" p-2 flex-col justify-center item-center max-w-screen-lg w-full">
          {/* --------------------------------Group delete and disable section------------------------------------- */}

          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <Card className="flex-1">
              <View className="w-full p-4 flex-col">
                <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                  <Font className="lg:text-lg text-base font font-bold self-center">
                    Switch to public
                  </Font>
                  <Pressable
                    className="flex justify-center items-center"
                    >
                    <Input
                      type="customToggle"
                      value={!IsPrivate}
                      setValue={setIsPrivate}
                      disabled={false}
                      wait={false}
                      onPress={() => setIsModalVisible(true)}
                    />
                  </Pressable>
                </View>

                <View className="pt-1">
                  <View className="w-full mb-3 flex">
                    <Font className="w-full">
                      Once you switch to public group then this group can be
                      viewed and accessed by all registered users.
                    </Font>
                  </View>
                </View>
              </View>
            </Card>
            <View className="w-4 h-4" />
            <Card className="flex-1">
              <View className="w-full p-4 flex-col">
                <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                  <Font className="lg:text-lg text-base font font-bold self-center">
                    Disable group
                  </Font>
                  <Pressable
                    className="flex justify-center items-center"
                    >
                    <Input
                      type="customToggle"
                      value={isDisableGroup}
                      setValue={setIsDisableGroup}
                      disabled={false}
                      wait={false}
                      onPress={() => setIsModalVisible(true)}
                    />
                  </Pressable>
                </View>

                <View className="pt-1">
                  <View className="w-full mb-3 flex">
                    <Font className="w-full">
                      Allowing this feature will temporarily deactivate the
                      group
                    </Font>
                  </View>
                </View>
              </View>
            </Card>
          </View>
          <View className="w-full lg:w-1/2 flex-row">
            <Pressable
              onPress={() => setIsDeleteModal(true)}
              className="p-2 pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
            >
              <Font className="text-app-e">Delete group</Font>
            </Pressable>
            <View className="hidden lg:flex w-4 h-4" />
          </View>
        </View>
        {Platform.OS == "web" ? <></> : <Footer navigation={navigation} />}
      </View>
    </ScrollView>
  );
}
