import React, { useContext } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import {
  Image,
  Pressable,
  View,
  ScrollView,
  Platform,
  RefreshControl,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Button, Input, Select } from "../../components/input";
import colors from "../../../colors";
import { Galleryupload } from "../../components/galleryupload";
import { useProductsContext } from "../../contexts/product-context";
import { apiURIV2, mediaURI } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import { isSignedIn } from "../../actions/auth-actions";
import useInputHandler from "../../utils/useInputHandler";
import MediaUpload from "../../components/general/uploads/media-upload";
import { cloneDeep } from "lodash";
import ResponseModal from "../../components/general/modals/ResponseModal";
import axios from "axios";
import { useSharedContext } from "../../contexts/shared-context";
import { FlatList } from "react-native";
import moment from "moment";
import { getFullDateTime } from "../../utils/date-time-util";
import { AuthContext } from "../../contexts/auth-context";
import { TextInput } from "react-native";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import { DefaultImages, UserRoles } from "../../constants/predefined-constants";
import { createCrossPlatformFormData } from "../../utils/encDec";
import { catchAPIErrorText } from "../../utils/error-handler";

const initial_from = 1,
  load_records = 5;

export default function UpdateProduct({ navigation, route }) {
  const { user } = useContext(AuthContext);

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);

  const [replyText, setReplyText] = React.useState("");
  const [isEditingReply, setIsEditingReply] = React.useState();
  const [operationQueue, setOperationQueue] = React.useState([]);
  const [isInOperation, setIsInOperation] = React.useState();

  const [step, setStep] = React.useState(1);
  const [pDIsLoading, setPDIsLoading] = React.useState();
  // const [pPIsLoading, setpPIsLoading] = React.useState();
  const [pCIsLoading, setpCIsLoading] = React.useState();
  const [pRDIsLoading, setPRDIsLoading] = React.useState();
  const [isEditingForm, setIsEditingForm] = React.useState();
  const [resetCoverImage, setResetCoverImage] = React.useState();
  const [resetGallery, setResetGallery] = React.useState();
  const [pRIsLoading, setPRIsLoading] = React.useState();
  const [isUpdating, setIsUpdating] = React.useState();
  const [productReviewsData, setProductReviewsData] = React.useState();
  const [selectedCoupons, setSelectedCoupons] = React.useState([]);
  const [unSelectedCoupons, setUnSelectedCoupons] = React.useState([]);
  const [couponIDValue, setCouponIDValue] = React.useState(null);
  const [coupons, setCoupons] = React.useState();

  const [productReviewDetailsData, setProductReviewDetailsData] =
    React.useState();
  const [reviewsPagination, setReviewsPagination] = React.useState({
    from: null,
    to: null,
    total: null,
  });
  const [showConfirmModal, setShowConfirmModal] = React.useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });

  const {
    productDetailsData,
    setProductDetailsData,
    productVariantData,
    productCategories,
    setProductCategories,
    productPackages,
    setProductPackages,
    productCoupons,
    setProductCoupons,
    appendProductVariantGalleryImages,
    removeProductVariantGalleryImages,
    productReviews,
    setProductReviews,
    productReviewsPagination,
    setProductReviewsPagination,
    productReviewDetails,
    setProductReviewDetails,
    deleteProductDetailsData,
    products,
    setProducts,
    pagination,
    setPagination,
  } = useProductsContext();

  const [isFirstCame, setIsFirstCame] = React.useState(true);

  const getProductCoupons = async () => {
    let data = [];
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/coupons", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        data = res.data.data.coupons;
        const selectedCouponArray = selectedCoupons.map((e) => e.value);
        const couponList = data.map((CouponsListMap) => {
          if (!selectedCouponArray.includes(CouponsListMap.id)) {
            return { label: CouponsListMap.code, value: CouponsListMap.id };
          }
        });
        setProductCoupons(couponList || []);
      }
    } catch (e) {
      console.log("e.response", e?.response, e);
    }
  };

  React.useEffect(() => {
    getProductCoupons();
  }, []);

  React.useEffect(() => {
    getAllottedCouponsForProduct();
  }, []);

  const getAllottedCouponsForProduct = async () => {
    const itemType = "product";
    const itemId = pid;
    try {
      const token = await isSignedIn();
      const res = await axios.get(
        `${apiURIV2}/coupons/allotments/${itemType}/${itemId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        const allottedCoupons = res.data.data.map((coupon) => ({
          label: coupon.coupon.code,
          value: coupon.coupon.id,
        }));
        setSelectedCoupons(allottedCoupons);
        getProductCoupons();
      }
    } catch (error) {
      console.error("Failed to fetch allotted coupons:", error);
      return [];
    }
  };

  async function fetchAllottedCoupons() {
    const itemType = "product";
    const itemId = pid;

    const allottedCoupons = await getAllottedCouponsForProduct(
      itemType,
      itemId
    );
  }

  React.useEffect(() => {
    if (step === 2 && isFirstCame) {
      const total_records = productReviewsPagination?.[pid]?.total;
      setIsFirstCame((isFirstCame) => !isFirstCame);
      setProductReviewsPagination((data) => {
        data[pid] = {
          from: 1,
          to: load_records,
          total: total_records,
        };
        return data;
      });
      setReviewsPagination({
        from: 1,
        to: load_records,
        total: total_records,
      });
    }
  }, [
    step,
    isFirstCame,
    pid,
    productReviewsPagination,
    setProductReviewsPagination,
    setReviewsPagination,
  ]);

  const { screen } = useSharedContext();

  const pid = route.params?.pid;
  let vid = route.params?.vid;
  let prid = route.params?.prid;
  let productData = productDetailsData?.[pid];
  let variantData;
  if (productData && !variantData) {
    variantData = productVariantData?.[pid]?.[vid];
  }

  const {
    inputValue: productTitleInputValue,
    currentValue: productTitleValue,
    onValueChangeHandler: onChangeProductTitleText,
    onBlurHandler: onBlurProductTitle,
    hasError: productTitleHasError,
    reset: resetProductTitle,
  } = useInputHandler({ defaultValue: variantData?.title });

  const {
    inputValue: productKeywordsInputValue,
    currentValue: productKeywordsValue,
    onValueChangeHandler: onChangeProductKeywordsText,
    onBlurHandler: onBlurProductKeywords,
    hasError: productKeywordsHasError,
    reset: resetProductKeywords,
  } = useInputHandler({ defaultValue: variantData?.keywords });

  const {
    inputValue: productCategoryIDInputValue,
    currentValue: productCategoryIDValue,
    onValueChangeHandler: onChangeProductCategoryIDText,
    onBlurHandler: onBlurProductCategoryID,
    hasError: productCategoryIDHasError,
    reset: resetProductCategoryID,
  } = useInputHandler({ defaultValue: productData?.product_category?.id });

  const {
    inputValue: productCurrencyInputValue,
    currentValue: productCurrencyValue,
    onValueChangeHandler: onChangeProductCurrencyText,
    onBlurHandler: onBlurProductCurrency,
    hasError: productCurrencyHasError,
    reset: resetProductCurrency,
  } = useInputHandler({ defaultValue: variantData?.currency });

  const {
    inputValue: productRegularPriceInputValue,
    currentValue: productRegularPriceValue,
    onValueChangeHandler: onChangeProductRegularPriceText,
    onBlurHandler: onBlurProductRegularPrice,
    hasError: productRegularPriceHasError,
    reset: resetProductRegularPrice,
  } = useInputHandler({
    defaultValue:
      variantData?.price && !isNaN(variantData?.price)
        ? variantData?.price / 100
        : variantData?.price ?? true
        ? variantData?.price || ""
        : variantData?.price,
  });

  const {
    inputValue: productSalePriceInputValue,
    currentValue: productSalePriceValue,
    onValueChangeHandler: onChangeProductSalePriceText,
    onBlurHandler: onBlurProductSalePrice,
    hasError: productSalePriceHasError,
    reset: resetProductSalePrice,
  } = useInputHandler({
    defaultValue:
      variantData?.sale_price && !isNaN(variantData?.sale_price)
        ? variantData?.sale_price / 100
        : variantData?.sale_price ?? true
        ? variantData?.sale_price || ""
        : variantData?.sale_price,
  });

  const {
    inputValue: productDescriptionInputValue,
    currentValue: productDescriptionValue,
    onValueChangeHandler: onChangeProductDescriptionText,
    onBlurHandler: onBlurProductDescription,
    hasError: productDescriptionHasError,
    reset: resetProductDescription,
  } = useInputHandler({ defaultValue: variantData?.description });

  const {
    inputValue: productVideoLinkInputValue,
    currentValue: productVideoLinkValue,
    onValueChangeHandler: onChangeProductVideoLinkText,
    onBlurHandler: onBlurProductVideoLink,
    hasError: productVideoLinkHasError,
    reset: resetProductVideoLink,
  } = useInputHandler({ defaultValue: productData?.video_link });

  const {
    inputValue: productWeightInputValue,
    currentValue: productWeightValue,
    onValueChangeHandler: onChangeProductWeightText,
    onBlurHandler: onBlurProductWeight,
    hasError: productWeightHasError,
    reset: resetProductWeight,
  } = useInputHandler({
    defaultValue:
      variantData?.weight_in_gm && !isNaN(variantData?.weight_in_gm)
        ? variantData?.weight_in_gm
        : variantData?.weight_in_gm ?? true
        ? variantData?.weight_in_gm || ""
        : variantData?.weight_in_gm,
  });

  const {
    inputValue: productLengthInputValue,
    currentValue: productLengthValue,
    onValueChangeHandler: onChangeProductLengthText,
    onBlurHandler: onBlurProductLength,
    hasError: productLengthHasError,
    reset: resetProductLength,
  } = useInputHandler({
    defaultValue:
      variantData?.length_in_mm && !isNaN(variantData?.length_in_mm)
        ? variantData?.length_in_mm
        : variantData?.length_in_mm ?? true
        ? variantData?.length_in_mm || ""
        : variantData?.length_in_mm,
  });

  const {
    inputValue: productWidthInputValue,
    currentValue: productWidthValue,
    onValueChangeHandler: onChangeProductWidthText,
    onBlurHandler: onBlurProductWidth,
    hasError: productWidthHasError,
    reset: resetProductWidth,
  } = useInputHandler({
    defaultValue:
      variantData?.width_in_mm && !isNaN(variantData?.width_in_mm)
        ? variantData?.width_in_mm
        : variantData?.width_in_mm ?? true
        ? variantData?.width_in_mm || ""
        : variantData?.width_in_mm,
  });

  const {
    inputValue: productHeightInputValue,
    currentValue: productHeightValue,
    onValueChangeHandler: onChangeProductHeightText,
    onBlurHandler: onBlurProductHeight,
    hasError: productHeightHasError,
    reset: resetProductHeight,
  } = useInputHandler({
    defaultValue:
      variantData?.height_in_mm && !isNaN(variantData?.height_in_mm)
        ? variantData?.height_in_mm
        : variantData?.height_in_mm ?? true
        ? variantData?.height_in_mm || ""
        : variantData?.height_in_mm,
  });

  const {
    inputValue: productSKUInputValue,
    currentValue: productSKUValue,
    onValueChangeHandler: onChangeProductSKUText,
    onBlurHandler: onBlurProductSKU,
    hasError: productSKUHasError,
    reset: resetProductSKU,
  } = useInputHandler({ defaultValue: variantData?.sku });

  const {
    inputValue: productStockQTYInputValue,
    currentValue: productStockQTYValue,
    onValueChangeHandler: onChangeProductStockQTYText,
    onBlurHandler: onBlurProductStockQTY,
    hasError: productStockQTYHasError,
    reset: resetProductStockQTY,
  } = useInputHandler({
    defaultValue:
      variantData?.stock && !isNaN(variantData?.stock)
        ? variantData?.stock
        : variantData?.stock ?? true
        ? variantData?.stock || ""
        : variantData?.stock,
  });

  const {
    // inputValue: productCoverImgInputValue,
    currentValue: productCoverImgValue,
    onValueChangeHandler: onChangeProductCoverImg,
    // onBlurHandler: onBlurProductCoverImg,
    hasError: productCoverImgHasError,
    reset: resetProductCoverImg,
  } = useInputHandler();

  const {
    inputValue: couponsInputValue,
    currentValue: couponsValue,
    onValueChangeHandler: onChangeCoupons,
    onBlurHandler: onBlurCoupons,
    hasError: couponsHasError,
    reset: resetCoupons,
  } = useInputHandler({ required: true });

  const {
    inputValue: productCouponIDInputValue,
    currentValue: productCouponIDValue,
    onValueChangeHandler: onProductCouponIDValueChangeHandler,
    onBlurHandler: onBlurProductCouponID,
    hasError: productCouponIDHasError,
    reset: resetProductCouponID,
  } = useInputHandler();

  const {
    // inputValue: productGalleryInputValue,
    currentValue: productGalleryValue,
    onValueChangeHandler: onChangeProductGallery,
    // onBlurHandler: onBlurProductGallery,
    hasError: productGalleryHasError,
    reset: resetProductGallery,
  } = useInputHandler({ defaultValue: { addedImages: [], removedImages: [] } });

  React.useEffect(() => {
    if (
      productTitleInputValue !== undefined ||
      productKeywordsInputValue !== undefined ||
      productCategoryIDInputValue !== undefined ||
      productCurrencyInputValue !== undefined ||
      productRegularPriceInputValue !== undefined ||
      productSalePriceInputValue !== undefined ||
      productDescriptionInputValue !== undefined ||
      productVideoLinkInputValue !== undefined ||
      productWeightInputValue !== undefined ||
      productLengthInputValue !== undefined ||
      productWidthInputValue !== undefined ||
      productHeightInputValue !== undefined ||
      productSKUInputValue !== undefined ||
      productStockQTYInputValue !== undefined ||
      productCouponIDInputValue !== undefined ||
      productCoverImgValue ||
      productGalleryValue.addedImages?.length ||
      productGalleryValue.removedImages?.length
    ) {
      setIsEditingForm(true);
    } else {
      setIsEditingForm(false);
    }
  }, [
    productTitleInputValue,
    productKeywordsInputValue,
    productCategoryIDInputValue,
    productCurrencyInputValue,
    productRegularPriceInputValue,
    productSalePriceInputValue,
    productDescriptionInputValue,
    productVideoLinkInputValue,
    productWeightInputValue,
    productLengthInputValue,
    productWidthInputValue,
    productHeightInputValue,
    productSKUInputValue,
    productStockQTYInputValue,
    productCoverImgValue,
    productCouponIDInputValue,
    productGalleryValue.addedImages?.length,
    productGalleryValue.removedImages?.length,
  ]);

  const resetFormData = React.useCallback(
    ({
      resetAddedGalleryImages,
      resetRemovedGalleryImages,
      resetGalleryImages,
      resetOtherFormData,
    }) => {
      if (resetOtherFormData) {
        resetProductTitle();
        resetProductKeywords();
        resetProductCategoryID();
        resetProductCurrency();
        resetProductRegularPrice();
        resetProductSalePrice();
        resetProductDescription();
        resetProductVideoLink();
        resetProductWeight();
        resetProductLength();
        resetProductWidth();
        resetProductHeight();
        resetProductSKU();
        resetProductStockQTY();
        resetProductCoverImg();
        resetProductCouponID();
        setResetCoverImage(true);
      }
      if (resetAddedGalleryImages) {
        const removedImages = cloneDeep(productGalleryValue.removedImages);
        onChangeProductGallery({
          addedImages: [],
          removedImages: removedImages,
        });
      }
      if (resetRemovedGalleryImages) {
        const images = cloneDeep(productGalleryValue);
        images.removedImages.filter(
          (v) => v.id === resetRemovedGalleryImages.id
        );
        onChangeProductGallery(images);
      }
      if (resetGalleryImages) {
        setResetGallery(true);
        resetProductGallery();
      }
    },
    [
      resetProductTitle,
      resetProductCouponID,
      resetProductKeywords,
      resetProductCategoryID,
      resetProductCurrency,
      resetProductRegularPrice,
      resetProductSalePrice,
      resetProductDescription,
      resetProductVideoLink,
      resetProductWeight,
      resetProductLength,
      resetProductWidth,
      resetProductHeight,
      resetProductSKU,
      resetProductStockQTY,
      resetProductCoverImg,
      resetProductGallery,
      productGalleryValue,
      onChangeProductGallery,
    ]
  );

  React.useEffect(() => {
    if (!isInOperation && operationQueue?.length) {
      let operation;
      setOperationQueue((data) => {
        data = cloneDeep(data);
        [operation] = data.splice(0, 1);
        operation();
        return data;
      });
    }
  }, [isInOperation, operationQueue]);

  if (pid) {
    if (!vid) {
      if (prid) {
        return setStep(3);
      } else {
        return navigation.replace("products");
      }
    }
  } else if (!pid) {
    return navigation.replace("products");
  }

  const getAllProductDetails = async () => {
    setPDIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/products/" + pid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        data = res.data.data;
        // const res1 = await getProductPackages();
        // if (res1.status === 200) {
        //   data.package = res1.data?.data?.find(
        //     (v) => v.id == data?.package_purchase_id
        //   );
        // }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      if ([404].includes(e?.response?.status)) {
        return navigation.replace("products");
      }
    } finally {
      if (data) {
        setProductDetailsData({ data, pid: pid });
      }
      setPDIsLoading(false);
    }
  };

  if (step === 1 && !productData && !pDIsLoading) {
    getAllProductDetails();
  }

  // const getProductPackages = async (currency) => {
  //   setpPIsLoading(true);
  //   let data;
  //   try {
  //     const token = await isSignedIn();
  //     const res = await axiosInstance.get(apiURIV2 + "/packages", {
  //       params: {
  //         package_item_type: "product",
  //         currency: currency || variantData?.currency,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log("res.status: ", res.status, res.data);
  //     if (res.status === 200) {
  //       data = res.data.data;
  //       return res;
  //     }
  //   } catch (e) {
  //     console.log("e.res", e?.response, e);
  //     if (e?.response) {
  //       return e.response;
  //     }
  //   } finally {
  //     setProductPackages({ data });
  //     setpPIsLoading(false);
  //   }
  // };

  // if (step === 0 && !productPackages && !pPIsLoading) {
  //   getProductPackages();
  // }

  const getProductCategories = async () => {
    setpCIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/product-categories", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
    } finally {
      setProductCategories({
        data: data?.map((v) => ({ label: v.title, value: v.id })),
      });
      setpCIsLoading(false);
    }
  };

  if (step === 1 && !productCategories && !pCIsLoading) {
    getProductCategories();
  }

  const getProductReviews = async (offset, limit, push) => {
    setPRIsLoading(true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/products/" + pid + "/reviews",
        {
          params: { offset, limit: limit || load_records },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data.data;
      }
    } catch (e) {
      console.log("products e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
    } finally {
      const pagObj = {
        from: (offset || -1) + 1 || initial_from,
        to:
          (offset || -1) + 1
            ? offset + (data?.length || load_records)
            : data?.length || load_records,
        total: total_count,
      };
      setReviewsPagination(pagObj);
      setProductReviewsPagination({ data: pagObj, pid: pid });
      setProductReviews({ data, pid: pid, push });
      setProductReviewsData(data);
      setPRIsLoading(false);
    }
  };

  if (step === 2) {
    if (!productReviewsData && !productReviews?.[pid] && !pRIsLoading) {
      getProductReviews();
    } else if (productReviews?.[pid] && !productReviewsData) {
      setProductReviewsData(productReviews[pid].slice(0, load_records));
    }
  }

  const getProductReviewDetails = async () => {
    setPRDIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/products/" + pid + "/reviews/" + prid,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        data = res.data.data;
        // const res1 = await getProductPackages();
        // if (res1.status === 200) {
        //   data.package = res1.data?.data?.find(
        //     (v) => v.id == data?.package_purchase_id
        //   );
        // }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
    } finally {
      if (data) {
        setProductReviewDetailsData(data || {});
        setProductReviewDetails({ data, prid: prid });
      }
      setPRDIsLoading(false);
    }
  };

  if (step === 3) {
    // if (!pid || !prid) {
    //   return navigation.replace("products");
    // }

    if (
      !productReviewDetailsData &&
      !productReviewDetails?.[prid] &&
      !pRDIsLoading
    ) {
      getProductReviewDetails();
    } else if (!productReviewDetailsData && productReviewDetails?.[prid]) {
      setProductReviewDetailsData(productReviewDetails[prid] || {});
    }
  }

  if (
    pid &&
    ((vid && step === 1 && !productData) ||
      (step === 2 && !productReviewsData) ||
      (prid && step === 3 && !productReviewDetailsData))
  ) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <Font>Loading...</Font>
      </View>
    );
  }

  // const onUpgradePlanPress = () => {
  //   setStep(0);
  // };

  // const onPackageSelect = () => {
  //   setStep(1);
  // };

  const updateProductDetails = async (dataObj) => {
    let data;
    const formData = createCrossPlatformFormData({
      blobDataKeys: "cover_image",
      data: dataObj,
    });
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.patch(
        apiURIV2 + "/products/" + pid,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        data = res.data?.data;
        setProductDetailsData({ data, pid: pid });
        resetFormData({
          resetOtherFormData: true,
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data.message,
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      throw e;
    }
  };

  const removeGalleryImages = async (giid) => {
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.delete(
        apiURIV2 + "/products/" + pid + "/" + vid + "/gallery/images/" + giid,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 204) {
        removeProductVariantGalleryImages({ imageId: giid, pid, vid });
        resetFormData({ resetRemovedGalleryImages: { id: giid } });
      }
    } catch (e) {
      const response = e?.response;
      console.log("remove gallery e.res", response, e);
      throw e;
    }
  };

  const addGalleryImages = async (dataArray) => {
    let data;
    const formData = createCrossPlatformFormData({
      blobDataKeys: "gallery_images",
      data: { gallery_images: dataArray },
    });
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/products/" + pid + "/" + vid + "/gallery/images",
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 201) {
        data = res.data.data;
        if (data) {
          appendProductVariantGalleryImages({ data, pid, vid });
          resetFormData({
            resetAddedGalleryImages: true,
          });
        }
      }
    } catch (e) {
      console.log("add gallery e.res", e?.response, e);
      throw e;
    }
  };

  const onDeleteProduct = async () => {
    try {
      setIsDeleteModal(true);
      const token = await isSignedIn();
      const res = await axiosInstance.delete(apiURIV2 + "/products/" + pid, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200 || res.status === 204) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
            navigation.goBack();
          },
          bodyText: "Successfully deleted the product",
        });
        const index = products?.findIndex((l) => l.id == pid);
        if (index > -1) {
          products.splice(index, 1);
          setProducts([...(products || [])]);
          if (pagination)
            setPagination({
              from:
                pagination.from === index
                  ? pagination.from + 1
                  : pagination.from,
              to:
                pagination.to === pagination.total ||
                (pagination.from <= index && index + 1 <= pagination.to)
                  ? pagination.to - 1
                  : pagination.to,
              total: pagination.total - 1,
            });
        }
        deleteProductDetailsData({ pid: pid });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const onSaveClickHandler = () => {
    setIsUpdating(true);
    let updateObj = {};
    if (
      productTitleInputValue === productTitleValue &&
      productTitleValue !== variantData?.title
    ) {
      updateObj.title = productTitleInputValue;
    }
    if (
      productKeywordsInputValue === productKeywordsValue &&
      productKeywordsValue !== variantData?.keywords
    ) {
      updateObj.keywords = JSON.stringify(
        (productKeywordsInputValue || "")?.split(",")?.map((v) => v.trim())
      );
      // .split(/\,[ ]|\,/)
      // .filter((v) => v.trim());
    }
    if (
      productCategoryIDInputValue === productCategoryIDValue &&
      productCategoryIDValue !== productData?.product_category?.id
    ) {
      updateObj.category_id = productCategoryIDInputValue;
    }
    if (
      productCurrencyInputValue === productCurrencyValue &&
      productCurrencyValue !== variantData?.currency
    ) {
      updateObj.currency = productCurrencyInputValue;
    }
    if (
      productRegularPriceInputValue === productRegularPriceValue &&
      +productRegularPriceValue !== +variantData?.price / 100
    ) {
      updateObj.price = parseFloat(productRegularPriceInputValue) * 100;
    }
    if (
      productSalePriceInputValue === productSalePriceValue &&
      +productSalePriceValue !== +variantData?.sale_price / 100
    ) {
      updateObj.sale_price = parseFloat(productSalePriceInputValue) * 100;
    }
    if (
      productDescriptionInputValue === productDescriptionValue &&
      productDescriptionValue !== variantData?.description
    ) {
      updateObj.description = productDescriptionInputValue;
    }
    if (
      productVideoLinkInputValue === productVideoLinkValue &&
      productVideoLinkValue !== productData?.video_link
    ) {
      updateObj.video_link = productVideoLinkInputValue;
    }
    if (
      productWeightInputValue === productWeightValue &&
      +productWeightValue !== +variantData?.weight_in_gm
    ) {
      updateObj.weight_in_gm = productWeightInputValue || 0;
    }
    if (
      productLengthInputValue === productLengthValue &&
      +productLengthValue !== +variantData?.length_in_mm
    ) {
      updateObj.length_in_mm = productLengthInputValue || 0;
    }
    if (
      productWidthInputValue === productWidthValue &&
      +productWidthValue !== +variantData?.width_in_mm
    ) {
      updateObj.width_in_mm = productWidthInputValue || 0;
    }
    if (
      productHeightInputValue === productHeightValue &&
      +productHeightValue !== +variantData?.height_in_mm
    ) {
      updateObj.height_in_mm = productHeightInputValue || 0;
    }
    if (
      productSKUInputValue === productSKUValue &&
      productSKUValue !== variantData?.sku
    ) {
      updateObj.sku = productSKUInputValue;
    }
    if (productStockQTYInputValue === productStockQTYValue) {
      updateObj.stock = productStockQTYInputValue;
    }
    if (productCoverImgValue !== undefined) {
      updateObj.cover_image = productCoverImgValue;
    }
    const promiseArray = [];
    if (Object.keys(updateObj)?.length) {
      promiseArray.push(updateProductDetails(updateObj));
    }
    if (productGalleryValue.removedImages?.length) {
      productGalleryValue.removedImages.forEach((v) => {
        promiseArray.push(removeGalleryImages(v.id));
      });
    }
    if (productGalleryValue.addedImages?.length) {
      promiseArray.push(
        addGalleryImages([...(productGalleryValue.addedImages || [])])
      );
    }
    if (productCouponIDInputValue) {
      allotCoupon(productCouponIDInputValue);
    }

    axios
      .all(promiseArray)
      .then((values) => {
        console.log("values:", values);
        // let i = values.length - 1,
        //   bodyText = values[i]?.data?.message;
        // if (i > -1 && [201, 200].includes(values[i]?.status)) {
        //   setShowResponseModal({
        //     visible: true,
        //     onRequestClose: () => {
        //       if (i > -1) {
        //         const res = values[--i];
        //         if ([201, 200].includes(res?.status))
        //           bodyText = res?.data?.message;
        //       } else {
        //         setShowResponseModal({
        //           visible: false,
        //           onRequestClose: () => null,
        //           bodyText: "",
        //         });
        //       }
        //     },
        //     bodyText: bodyText ? bodyText : "Unknown Error",
        //   });
        // }
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Product Successfully Updated",
        });
      })
      .catch((error) => {
        console.log("e.res", error?.response, error);
        // if (e?.response?.data?.message) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: error?.response
            ? `${error.response.status}: ${
                error.response?.data?.message
                  ? error.response?.data?.message
                  : JSON.stringify(error.response?.data)
              }`
            : error?.request
            ? "Oops! Seems Network Error"
            : error,
        });
        // }
      })
      .finally(() => setIsUpdating(false));
  };

  const deleteProductReviewReplies = async (prrid) => {
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const res = await axiosInstance.delete(
        apiURIV2 +
          "/products/" +
          pid +
          "/reviews/" +
          prid +
          "/replies/" +
          prrid,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 204) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Successfully deleted reply",
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const updateProductReviewReplies = async (dataObj) => {
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const res = await axiosInstance.patch(
        apiURIV2 +
          "/products/" +
          pid +
          "/reviews/" +
          prid +
          "/replies/" +
          dataObj.id,
        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 201) {
        delete dataObj.processText;
        let reviewData = productReviewDetails[prid];
        reviewData.replies = reviewData.replies.filter(
          (r) => r.id !== dataObj.id
        );
        reviewData = {
          ...reviewData,
          replies: [...(reviewData.replies || []), dataObj],
        };
        setProductReviewDetails({ data: reviewData, prid });
        setProductReviewDetailsData((data) => {
          data = cloneDeep(data);
          if (data.replies) {
            const i = data.replies.findIndex((v) => v.id === dataObj.id);
            data.replies[i] = dataObj;
          } else {
            data.replies = [dataObj];
          }
          return data;
        });
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const addProductReviewReply = async (dataObj) => {
    let data;
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/products/" + pid + "/reviews/" + prid + "/replies",
        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
            // "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 201) {
        data = res.data.data;
        delete dataObj.processText;
        let reviewData = productReviewDetails[prid];
        reviewData = {
          ...reviewData,
          replies: [...(reviewData.replies || []), data],
        };
        setProductReviewDetails({ data: reviewData, prid });
        setProductReviewDetailsData((replyData) => {
          replyData = cloneDeep(replyData);
          replyData.replies[replyData.replies.length - 1] = data;
          return replyData;
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data.message,
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error"),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const onSendReplyHandler = (replyInput, isEditingReply) => {
    setReplyText("");
    let replyObj;
    if (!isEditingReply) {
      const dateNow = moment.now();
      replyObj = {
        id: "reply-local" + Date.now(),
        user_id: user.id,
        user_role: user.role,
        user_name: user.first_name + " " + user.last_name,
        user_profile_image_url: user.profile_image_url,
        rating: null,
        message: replyInput,
        is_reply: true,
        is_edited: false,
        created_at: dateNow,
        updated_at: dateNow,
        processText: "sending...",
        user,
      };
    }
    setProductReviewDetailsData((data) => {
      data = cloneDeep(data);
      if (isEditingReply) {
        setIsEditingReply();
        isEditingReply = {
          ...isEditingReply,
          message: replyInput,
          is_edited: true,
          processText: "updating...",
        };
        const i = data.replies?.findIndex((v) => v.id === isEditingReply.id);
        if (i > -1) {
          data.replies[i] = isEditingReply;
        }
        setOperationQueue((q) => {
          q = cloneDeep(q);
          q.push(() => updateProductReviewReplies(isEditingReply));
          return q;
        });
      } else {
        if (!data?.replies) {
          data.replies = [replyObj];
        } else {
          data.replies.push(replyObj);
        }
        setOperationQueue((q) => {
          q = cloneDeep(q);
          q.push(() => addProductReviewReply(replyObj));
          return q;
        });
      }
      return data;
    });
  };

  const onDeleteReplyHandler = (replyID, replyIndex) => {
    if (replyIndex > -1) {
      setProductReviewDetailsData((data) => {
        data = cloneDeep(data);
        data.replies.splice(replyIndex, 1);
        return data;
      });
      setOperationQueue((q) => {
        q = cloneDeep(q);
        q.push(() => deleteProductReviewReplies(replyID));
        return q;
      });
    }
  };

  // ==================================== Coupons ====================================

  const onChangeProductCouponIDText = async (selectedValue) => {
    if (selectedValue !== null) {
      await allotCoupon(selectedValue);
    }
    setCouponIDValue(selectedValue);
    onProductCouponIDValueChangeHandler(selectedValue);
  };

  const allotCoupon = async (couponID) => {
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const requestData = {
        product_id: parseInt(pid),
      };
      const res = await axiosInstance.post(
        apiURIV2 + `/coupons/${couponID}/allot`,
        requestData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200) {
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
        resetProductCouponID();
        const couponLabel = res.data.data.coupon.code;
        // console.log(`Coupon ${couponID} allotted successfully.`);
        // handleSelectCoupon({ label: couponID, value: couponID });
        setSelectedCoupons([
          ...selectedCoupons,
          { label: couponLabel, value: couponID },
        ]);
        setProductCoupons(productCoupons.filter((e) => e.value != couponID));
      }
    } catch (e) {
      if (e?.response) {
        if (e.response.status === 409) {
          setProductCoupons(productCoupons.filter((e) => e.value != couponID));
        }
      }
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while alloting coupon"),
      });
      console.error(`Failed to allot coupon ${couponID}.`, e);
    } finally {
      setIsInOperation(false);
    }
  };

  const unAllotCoupon = async (couponID, couponLabel) => {
    try {
      setIsInOperation(true);
      const token = await isSignedIn();
      const requestData = {
        product_id: parseInt(pid),
      };
      const res = await axiosInstance.post(
        apiURIV2 + `/coupons/${couponID}/un-allot`,
        requestData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 204) {
        // console.log(`Coupon ${couponID} un-allotted successfully.`);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: `Coupon code “${couponLabel}” successfully removed`,
        });
        const index = productCoupons.findIndex((v) => v.value === couponID);
        if (index > -1) {
          setProductCoupons(productCoupons || []);
        } else {
          setProductCoupons([
            ...productCoupons,
            { label: couponLabel, value: couponID },
          ]);
        }
        setSelectedCoupons(
          selectedCoupons.filter(
            (selectedCoupon) => selectedCoupon.value !== couponID
          )
        );
      }
    } catch (e) {
      console.error(`Failed to un-allot coupon ${couponID}.`, e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while alloting coupon"),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showConfirmModal.visible ? (
        <ConfirmModal {...showConfirmModal} />
      ) : (
        <></>
      )}
      {showResponseModal.visible ? (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      ) : (
        <></>
      )}
      <ConfirmModal
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onConfirm={onDeleteProduct}
        confirmText={"Delete"}
        bodyText={"Are you sure you want to delete the product ?"}
        BodyComponent={false}
      ></ConfirmModal>
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={
                // step == 0
                //   ? pPIsLoading
                //   :
                step === 1
                  ? pDIsLoading || pCIsLoading
                  : step === 2
                  ? pRIsLoading
                  : pRDIsLoading
              }
              onRefresh={() => {
                if (step === 1) {
                  getAllProductDetails();
                  if (!productCategories?.length) {
                    getProductCategories();
                  }
                } else if (step === 2) {
                  getProductReviews();
                } else if (step === 3) {
                  getProductReviewDetails();
                }
                // if (step < 3 && !productPackages) {
                //   getProductPackages();
                // }
              }}
            />
          )
        }
        keyboardShouldPersistTaps="always"
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          {step == 3 ? (
            <>
              <View className="mb-4 lg:my-4 p-0 lg:px-4 flex-col w-full">
                <View className="flex flex-row items-center">
                  <View className="hidden lg:flex flex-1 mr-4">
                    <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                      Product Review
                    </Font>
                  </View>
                </View>
              </View>
              <View className="p-0 lg:px-4">
                <Card title="Review Details">
                  {productReviewDetailsData ? (
                    Object.keys(productReviewDetailsData || {})?.length ? (
                      <>
                        <View
                          className="px-4 pb-4 border-b border-gray-200 flex-row w-full"
                          key={productReviewDetailsData.id}
                        >
                          <View className="justify-start pt-1 items-center w-24 lg:w-32">
                            <Image
                              source={
                                productReviewDetailsData.user_profile_image_url
                                  ? {
                                      uri:
                                        mediaURI +
                                        productReviewDetailsData.user_profile_image_url,
                                    }
                                  : DefaultImages.DP_PLACEHOLDER
                              }
                              className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                            ></Image>
                          </View>
                          <View className="justify-start ml-4 flex-1">
                            <View className="flex-row items-center flex-wrap pb-1">
                              <Font
                                className="text-app-d text-base font-semibold mr-2"
                                weight={700}
                              >
                                {productReviewDetailsData?.user?.first_name +
                                  " " +
                                  productReviewDetailsData?.user?.last_name}
                              </Font>
                              <Font
                                className={`px-1 pt-1 pb-[5px] text-app-b capitalize rounded-full leading-3 ${
                                  productReviewDetailsData?.user?.role ===
                                  "customer"
                                    ? "bg-app-d3 text-app-d"
                                    : "bg-app-e3 text-app-e"
                                }`}
                              >
                                {productReviewDetailsData?.user?.role}
                              </Font>
                            </View>
                            <View className="flex-row items-center">
                              <View className="bg-app-d flex-row items-center rounded-full px-1 py-0.5">
                                <Font
                                  className="text-app-a mx-1 leading-4"
                                  weight={600}
                                >
                                  {parseFloat(productReviewDetailsData.rating)}
                                  /5
                                </Font>
                                <RemixIcon
                                  name="star-fill"
                                  size={14}
                                  color={colors.app.i}
                                  className="flex-shrink-0"
                                />
                              </View>
                              <Font className="text-app-d1 leading-3 pl-2">
                                - on{" "}
                                {getFullDateTime(
                                  productReviewDetailsData.updated_at
                                )}
                              </Font>
                            </View>
                            <View className="flex-row items-center py-3">
                              <RemixIcon
                                className="pr-2 pt-px -mb-px w-6 self-start"
                                name="ri-discuss-fill"
                                size={16}
                                color={colors.app.d}
                              />
                              <Font
                                numberOfLines={4}
                                className="text-app-d flex-row items-center"
                              >
                                {productReviewDetailsData.is_edited ? (
                                  <RemixIcon
                                    className="pr-2 -mb-px self-start"
                                    name="ri-pencil-fill"
                                    size={12}
                                    color={colors.app.d}
                                  />
                                ) : (
                                  <></>
                                )}
                                {productReviewDetailsData.message}
                              </Font>
                            </View>
                          </View>
                        </View>
                        {productReviewDetailsData?.replies?.length ? (
                          <View className="w-full flex-1 items-start justify-start">
                            <View className="border-b border-gray-200 p-4">
                              <Font className="text-app-d py-1 px-2 border border-app-d2 rounded">
                                {productReviewDetailsData?.replies?.length ||
                                  "-"}{" "}
                                {productReviewDetailsData?.replies?.length === 1
                                  ? "Reply"
                                  : "Replies"}
                              </Font>
                            </View>
                            {productReviewDetailsData.replies.map(
                              (reply, i) => (
                                <View
                                  className={`px-4 pt-4 ${
                                    i ===
                                    (productReviewDetailsData?.replies
                                      ?.length || 0) -
                                      1
                                      ? "pb-8"
                                      : "pb-4"
                                  } border-b border-gray-200 flex-row w-full`}
                                  key={reply.id}
                                >
                                  <View className="justify-start pt-1 items-center w-24 lg:w-32">
                                    <Image
                                      source={
                                        reply.user_profile_image_url
                                          ? {
                                              uri:
                                                mediaURI +
                                                reply.user_profile_image_url,
                                            }
                                          : DefaultImages.DP_PLACEHOLDER
                                      }
                                      className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                                    ></Image>
                                  </View>
                                  <View className="justify-start ml-4 flex-1">
                                    <View className="flex-1 flex-row flex-wrap justify-between items-center">
                                      <View className="flex-row flex-wrap items-center pb-1">
                                        <Font
                                          className="text-app-d text-base mr-2"
                                          weight={600}
                                        >
                                          {reply?.user_name}
                                        </Font>
                                        <Font
                                          className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                                            reply?.user_role ===
                                            UserRoles.CUSTOMER
                                              ? "bg-app-d3 text-app-d"
                                              : "bg-app-e3 text-app-e"
                                          }`}
                                        >
                                          {reply.user_role}
                                        </Font>
                                      </View>
                                      {reply.user_id === user.id ? (
                                        <View className="flex-row items-center">
                                          <Pressable
                                            className="p-0.5 mr-2"
                                            onPress={() => {
                                              setIsEditingReply(reply);
                                              setReplyText(reply.message);
                                            }}
                                          >
                                            <RemixIcon
                                              name="ri-pencil-fill"
                                              className="self-start"
                                              size={20}
                                              color={colors.app.d2}
                                            />
                                          </Pressable>
                                          <Pressable
                                            className="p-0.5"
                                            onPress={() => {
                                              setShowConfirmModal((d) => ({
                                                ...d,
                                                visible: true,
                                                BodyComponent: () => (
                                                  <Font>
                                                    Do you want to delete the
                                                    Specified Review?
                                                  </Font>
                                                ),
                                                onConfirm: () => {
                                                  setShowConfirmModal((d1) => {
                                                    d1.visible = false;
                                                    d1.onConfirm = () => null;
                                                    d1.onCancel = () => null;
                                                    d1.cancelText = "Cancel";
                                                    d1.confirmText = "Confirm";
                                                    d1.bodyText = "";
                                                    return { ...d1 };
                                                  });
                                                  onDeleteReplyHandler(
                                                    reply.id,
                                                    i
                                                  );
                                                },
                                                onCancel: () =>
                                                  setShowConfirmModal((d1) => {
                                                    d1.visible = false;
                                                    d1.onConfirm = () => null;
                                                    d1.onCancel = () => null;
                                                    d1.cancelText = "Cancel";
                                                    d1.confirmText = "Confirm";
                                                    d1.bodyText = "";
                                                    return { ...d1 };
                                                  }),
                                              }));
                                            }}
                                          >
                                            <RemixIcon
                                              name="ri-delete-bin-5-fill"
                                              className="self-start"
                                              size={20}
                                              color={colors.app.d2}
                                            />
                                          </Pressable>
                                        </View>
                                      ) : (
                                        <></>
                                      )}
                                    </View>
                                    <View className="flex-row items-center pb-2">
                                      <Font className="text-app-d1 leading-4">
                                        {reply.processText
                                          ? reply.processText
                                          : getFullDateTime(reply.updated_at)}
                                      </Font>
                                    </View>
                                    <View className="flex flex-row items-center">
                                      <RemixIcon
                                        name="ri-reply-fill"
                                        size={16}
                                        color={colors.app.d}
                                        className="mr-2"
                                      />
                                      <Font
                                        numberOfLines={4}
                                        className="text-app-d flex flex-row items-center leading-4"
                                      >
                                        {reply.is_edited ? (
                                          <>
                                            <RemixIcon
                                              className="-mb-px self-start"
                                              name="ri-pencil-fill"
                                              size={14}
                                              color={colors.app.d}
                                            />
                                            &nbsp;
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {reply.message}
                                      </Font>
                                    </View>
                                  </View>
                                </View>
                              )
                            )}
                          </View>
                        ) : (
                          <View
                            className={`px-4 py-4 border-b border-gray-200 w-full`}
                          >
                            <Font className="text-app-d1 self-center">
                              No Replies Found
                            </Font>
                          </View>
                        )}
                        <View className="h-18 w-full py-4 pl-4 pr-1 flex-row items-center border-t rounded-b-lg lg:bg-app-d/95 border-gray-200">
                          <View className="flex-1 mr-1">
                            {/* <Input type="text" placeholder="Type your message"></Input> */}
                            <View
                              className={`rounded-lg border border-gray-200 overflow-hidden ${
                                replyText?.length > 100 ? "h-20" : "h-10"
                              } bg-app-a`}
                            >
                              <TextInput
                                placeholder="Type your reply..."
                                className={`w-full ${
                                  Platform.OS === "web"
                                    ? "flex-1 py-2.5"
                                    : "h-full"
                                } px-3 min-w-0 lg:bg-app-d/95 lg:text-app-a`}
                                style={
                                  Platform.OS === "web" && {
                                    outlineColor: "transparent",
                                  }
                                }
                                multiline={true}
                                textAlignVertical="center"
                                value={replyText}
                                onChangeText={(e) => setReplyText(e)}
                                onKeyPress={(e) => {
                                  if (
                                    !e.shiftKey &&
                                    ["Enter"].includes(e.code)
                                  ) {
                                    e.preventDefault();
                                    onSendReplyHandler(
                                      replyText,
                                      isEditingReply
                                    );
                                  }
                                }}
                              ></TextInput>
                            </View>
                          </View>
                          {isEditingReply ? (
                            <Pressable
                              className={`px-4 py-2 mr-1 self-end items-center justify-center rounded-lg ${
                                Platform.OS === "web"
                                  ? "active:bg-app-c2"
                                  : "active:bg-app-d3"
                              }`}
                              onPress={() => {
                                setReplyText("");
                                setIsEditingReply();
                              }}
                            >
                              <RemixIcon
                                name="ri-close-circle-fill"
                                color={
                                  screen?.width > 1023
                                    ? colors.app.a
                                    : colors.app.d
                                }
                              ></RemixIcon>
                            </Pressable>
                          ) : (
                            <></>
                          )}
                          <Pressable
                            className={`pl-4 pr-4 py-2 self-end items-center justify-center rounded-lg ${
                              Platform.OS === "web"
                                ? "active:bg-app-c2"
                                : "active:bg-app-d3"
                            }`}
                            onPress={() => {
                              if (replyText)
                                onSendReplyHandler(replyText, isEditingReply);
                            }}
                          >
                            <RemixIcon
                              name="send-plane-2-line"
                              color={
                                screen?.width > 1023
                                  ? colors.app.a
                                  : colors.app.d
                              }
                            ></RemixIcon>
                          </Pressable>
                        </View>
                      </>
                    ) : (
                      <View className="flex-1 justify-center items-center pb-8">
                        <Font className="text-app-d1">
                          Product Review Details Not Found
                        </Font>
                      </View>
                    )
                  ) : (
                    <View className="flex-1 justify-center items-center pb-8">
                      <Font className="text-app-d">Loading...</Font>
                    </View>
                  )}
                </Card>
              </View>
            </>
          ) : (
            <>
              <View className="mb-4 lg:my-4 p-0 lg:px-4 flex-col w-full">
                <View className="flex flex-row items-center">
                  <View className="hidden lg:flex flex-1 mr-4">
                    <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                      {step === 2 ? "Product Reviews" : "Update Product"}
                    </Font>
                  </View>
                  <View className="self-center justify-self-center flex-1">
                    <View className="relative w-full">
                      {step > 0 ? (
                        <View className="flex-row self-center lg:self-end justify-between lg:justify-between w-2/4 lg:w-3/6">
                          <Pressable
                            onPress={() => setStep(1)}
                            className={`items-center px-4 py-2 border border-gray-200 rounded-lg ${
                              step == 1 ? "bg-app-e" : ""
                            }`}
                          >
                            <Font
                              className={`${
                                step == 1 ? "text-app-a" : "text-app-d"
                              }`}
                            >
                              Details
                            </Font>
                          </Pressable>
                          <Pressable
                            onPress={() => setStep(2)}
                            className={`items-center px-4 py-2 border border-gray-200 rounded-lg ${
                              step == 2 ? "bg-app-e" : ""
                            }`}
                          >
                            <Font
                              className={`${
                                step == 2 ? "text-app-a" : "text-app-d"
                              }`}
                            >
                              Review
                            </Font>
                          </Pressable>
                        </View>
                      ) : null}
                    </View>
                  </View>
                </View>
                {/* <View className="flex-row items-center w-3/12"></View> */}
              </View>
              <View className="p-0 lg:px-4">
                {/* {step == 0 ? (
                  <>
                    <Card title="Upgrade Package">
                      <View className="w-full flex-row flex-wrap px-4 pb-4">
                        <FlatList
                          data={productPackages}
                          key={screen?.width}
                          numColumns={screen?.width >= 768 ? 2 : 1}
                          ListEmptyComponent={() => (
                            <View className="flex-1 justify-center items-center h-14">
                              <Font>No Packages Found</Font>
                            </View>
                          )}
                          renderItem={({
                            item: {
                              id,
                              title,
                              subTitle,
                              is_featured,
                              features,
                              prices,
                            },
                          }) => (
                            <View className="w-full md:w-1/2 mb-4 md:m-0 md:p-4">
                              <PlanCard
                                planTitle={title}
                                planPrice={
                                  prices[0].currency_symbol +
                                  " " +
                                  prices[0].discounted_price / 100
                                }
                                planSubtitle={subTitle}
                                planFeatures={features}
                                onPress={onPackageSelect}
                                isCurrentPlan={
                                  id === productData?.package_purchase_id
                                }
                                isFeatured={is_featured}
                              />
                            </View>
                          )}
                        />
                      </View>
                    </Card>
                  </>
                ) :  */}
                {step == 1 ? (
                  <>
                    {/* <Card title="Package">
                      <View className="flex-row flex-wrap px-4 w-full ">
                        <View className="p-4 w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 lg:flex-row">
                          {pPIsLoading ? (
                            <View className="flex-1 justify-center items-center h-14">
                              <Font>Loading...</Font>
                            </View>
                          ) : (
                            <>
                              {productData?.package ? (
                                <>
                                  <View className="flex-col justify-between flex-auto">
                                    <Font
                                      weight={600}
                                      className="font-semibold text-lg leading-5 capitalize pb-2"
                                    >
                                      {productData?.package?.title}
                                    </Font>
                                    <View className="w-full">
                                      <Font className="text-sm text-app-d1">
                                        {productData?.package?.subtitle}
                                      </Font>
                                    </View>
                                  </View>
                                </>
                              ) : (
                                <View className="flex-1 justify-center items-center h-14">
                                  <Font className="text-app-d1">
                                    Active Package Details Not Found
                                  </Font>
                                </View>
                              )}
                              <View className="w-full lg:w-60 justify-center mt-4 lg:m-0">
                                <Pressable
                                  className="border border-app-h bg-app-h3 items-center justify-center p-2 rounded-full"
                                  onPress={onUpgradePlanPress}
                                >
                                  <Font className="text-app-h font-semibold">
                                    Upgrade
                                  </Font>
                                </Pressable>
                              </View>
                            </>
                          )}
                        </View>
                      </View>
                    </Card> */}
                    <Card title="Basic Information">
                      <View className="flex-row flex-wrap px-2 w-full">
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="text"
                            label="Title"
                            value={productTitleValue}
                            onChangeText={onChangeProductTitleText}
                            onBlur={onBlurProductTitle}
                            note={productTitleHasError}
                          />
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Select
                            label="Category"
                            options={productCategories}
                            placeholder={
                              productCategories?.length
                                ? "Select Category"
                                : productData?.product_category?.title
                            }
                            value={productCategoryIDValue}
                            setValue={(e) => {
                              e = parseInt(e);
                              onChangeProductCategoryIDText(e);
                            }}
                            note={productCategoryIDHasError}
                          />
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="text"
                            label="Keywords"
                            placeholder="Office Use, Household"
                            value={productKeywordsValue}
                            onChangeText={onChangeProductKeywordsText}
                            onBlur={onBlurProductKeywords}
                            note={productKeywordsHasError}
                          ></Input>
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Select
                            label="Currency"
                            options={[{ label: "USD ($)", value: "USD" }]}
                            placeholder="Select Currency"
                            enabled={false}
                            value={productCurrencyValue}
                            setValue={onChangeProductCurrencyText}
                          />
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="number"
                            label="Regular Price"
                            leftText={"$"}
                            placeholder="0"
                            value={productRegularPriceValue}
                            onChangeText={onChangeProductRegularPriceText}
                            onBlur={onBlurProductRegularPrice}
                            note={productRegularPriceHasError}
                          ></Input>
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="text"
                            label="Sale Price"
                            placeholder="0"
                            leftText={"$"}
                            value={productSalePriceValue}
                            onChangeText={onChangeProductSalePriceText}
                            onBlur={onBlurProductSalePrice}
                            note={productSalePriceHasError}
                          ></Input>
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="number"
                            label="Weight"
                            placeholder="0"
                            rightText="gm"
                            value={productWeightValue}
                            onChangeText={onChangeProductWeightText}
                            onBlur={onBlurProductWeight}
                            note={productWeightHasError}
                          ></Input>
                        </View>
                        <View className="px-2 w-full lg:w-2/3 pb-4 flex-row flex-wrap">
                          <Input
                            type="number"
                            label="Length"
                            placeholder="0"
                            rightText="mm"
                            value={productLengthValue}
                            onChangeText={onChangeProductLengthText}
                            onBlur={onBlurProductLength}
                            note={productLengthHasError}
                          ></Input>
                          <Font
                            className={`p-2 self-start text-app-d2 ${
                              Platform.OS === "web" ? "mt-9" : "mt-8"
                            }`}
                          >
                            X
                          </Font>
                          <Input
                            type="number"
                            label="Width"
                            placeholder="0"
                            rightText="mm"
                            value={productWidthValue}
                            onChangeText={onChangeProductWidthText}
                            onBlur={onBlurProductWidth}
                            note={productWidthHasError}
                          ></Input>
                          <Font
                            className={`p-2 self-start text-app-d2 ${
                              Platform.OS === "web" ? "mt-9" : "mt-8"
                            }`}
                          >
                            X
                          </Font>
                          <Input
                            type="number"
                            label="Height"
                            placeholder="0"
                            rightText="mm"
                            value={productHeightValue}
                            onChangeText={onChangeProductHeightText}
                            onBlur={onBlurProductHeight}
                            note={productHeightHasError}
                          ></Input>
                        </View>
                        <View className="px-2 w-full pb-4">
                          <Input
                            type="longtext"
                            label="Description"
                            placeholder="No Description"
                            value={productDescriptionValue}
                            onChangeText={onChangeProductDescriptionText}
                            onBlur={onBlurProductDescription}
                            note={productDescriptionHasError}
                          ></Input>
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="text"
                            label="Video Link"
                            placeholder={"https://"}
                            value={productVideoLinkValue}
                            onChangeText={onChangeProductVideoLinkText}
                            onBlur={onBlurProductVideoLink}
                            note={productVideoLinkHasError}
                          ></Input>
                        </View>
                      </View>
                    </Card>
                    <Card title="Stock">
                      <View className="flex-row flex-wrap px-2 w-full">
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="text"
                            label="SKU"
                            placeholder={"SKU Number"}
                            value={productSKUValue}
                            onChangeText={onChangeProductSKUText}
                            onBlur={onBlurProductSKU}
                            note={productSKUHasError}
                          ></Input>
                        </View>
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Input
                            type="number"
                            label="Quantity"
                            placeholder="0"
                            rightText="nos."
                            value={productStockQTYValue}
                            onChangeText={onChangeProductStockQTYText}
                            onBlur={onBlurProductStockQTY}
                            note={productStockQTYHasError}
                          ></Input>
                        </View>
                      </View>
                    </Card>
                    <Card title="Coupons">
                      <View className="flex-row flex-wrap px-2 w-full">
                        <View className="px-2 w-full lg:w-1/3 pb-4">
                          <Select
                            // label="Select Coupons From List"
                            options={productCoupons}
                            placeholder="Select Coupons From List"
                            value={productCouponIDValue}
                            onValueChange={(selectedValue) => {
                              // couponIDValue(selectedValue);

                              // allotCoupon(selectedValue);
                              onProductCouponIDValueChangeHandler(
                                selectedValue
                              );
                            }}
                            note={productCouponIDHasError}
                          />
                        </View>
                      </View>
                      <View className="px-4 w-full pb-4">
                        {/* <Font className="text-lg font-semibold mb-4">
                          Selected Coupons:
                        </Font> */}
                        <View className="flex-row flex-wrap gap-4">
                          {selectedCoupons.map((coupon) => (
                            <View
                              key={coupon.value}
                              className="bg-gray-200 lg:w-1/3 rounded-full px-4 py-2 m-2 flex-row justify-between items-center"
                            >
                              <Font className="mr-2">{coupon.label}</Font>
                              <Pressable
                                className="cursor-pointer"
                                onPress={() =>
                                  unAllotCoupon(coupon.value, coupon.label)
                                }
                              >
                                <MaterialIcons
                                  name="cancel"
                                  size={24}
                                  color={colors.app.e1}
                                />
                              </Pressable>
                            </View>
                          ))}
                        </View>
                      </View>
                    </Card>
                    <Card
                      title="Cover Image"
                      className={
                        Platform.OS === "web" ? "" : "max-h-full flex-1"
                      }
                    >
                      <View className="px-4 pb-4 w-full h-full flex-row flex-1">
                        <View className="w-full lg:w-96">
                          <MediaUpload
                            placeholderImage={
                              variantData?.cover_image_url
                                ? {
                                    uri:
                                      mediaURI + variantData?.cover_image_url,
                                  }
                                : null
                            }
                            onImagePick={onChangeProductCoverImg}
                            note={productCoverImgHasError}
                            reset={resetCoverImage}
                            onReset={() => setResetCoverImage(false)}
                          ></MediaUpload>
                        </View>
                      </View>
                    </Card>
                    <Card
                      title="Gallery"
                      className={
                        Platform.OS === "web" ? "" : "max-h-full flex-1"
                      }
                    >
                      <View className="px-4 pb-4 w-full">
                        <View className="w-full">
                          <Galleryupload
                            images={variantData?.gallery_images?.map((v) => {
                              v.uri = mediaURI + v.image_url;
                              return v;
                            })}
                            onAddImage={(img) => {
                              let imgData = cloneDeep(productGalleryValue);
                              imgData.addedImages.push(img);
                              onChangeProductGallery(imgData);
                            }}
                            onRemoveImage={(img) => {
                              let imgData = cloneDeep(productGalleryValue);
                              if ((img?.id + "").includes("-local")) {
                                const i = imgData.addedImages.findIndex(
                                  (v) => v.id === img.id
                                );
                                if (i > -1) {
                                  imgData.addedImages.splice(i, 1);
                                }
                              } else {
                                imgData.removedImages.push(img);
                              }
                              onChangeProductGallery(imgData);
                            }}
                            reset={resetGallery}
                            onReset={() => setResetGallery(false)}
                          ></Galleryupload>
                        </View>
                      </View>
                    </Card>
                    <View className="mb-8" />
                  </>
                ) : step == 2 ? (
                  <>
                    <Card title="Reviews">
                      <FlatList
                        className="w-full"
                        data={productReviewsData}
                        ListEmptyComponent={() => (
                          <View className="w-full justify-center items-center pb-4">
                            <Font>
                              {pRIsLoading ? "Loading..." : "No Reviews"}
                            </Font>
                          </View>
                        )}
                        ItemSeparatorComponent={() => (
                          <View className="w-full my-4 border-b border-gray-200" />
                        )}
                        renderItem={({ item: review }) => (
                          <Pressable
                            className="px-4 flex-row w-full"
                            key={review.id}
                            onPress={() => {
                              navigation.setParams({ prid: review.id });
                              setStep(3);
                            }}
                          >
                            <View className="justify-start pt-1 items-center w-24 lg:w-32">
                              <Image
                                source={
                                  review.user_profile_image_url
                                    ? {
                                        uri:
                                          mediaURI +
                                          review.user_profile_image_url,
                                      }
                                    : DefaultImages.DP_PLACEHOLDER
                                }
                                className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                              ></Image>
                            </View>
                            <View className="justify-start ml-4 flex-1">
                              <View className="flex-row items-center flex-wrap pb-1">
                                <Font
                                  className="text-app-d text-base mr-2"
                                  weight={600}
                                >
                                  {review.user_name}
                                </Font>
                                <Font
                                  className={`px-1 py-0.5 text-app-b capitalize rounded-full leading-4 ${
                                    review.user_role === UserRoles.CUSTOMER
                                      ? "bg-app-d3 text-app-d"
                                      : "bg-app-e3 text-app-e"
                                  }`}
                                >
                                  {review.user_role}
                                </Font>
                              </View>
                              <View className="flex-row items-center">
                                <View className="bg-app-d flex-row items-center rounded-full px-1 py-0.5 mr-1">
                                  <Font className="text-app-a text-sm mx-1 leading-4">
                                    {parseFloat(review.rating)}/5
                                  </Font>
                                  <RemixIcon
                                    name="star-fill"
                                    size={13}
                                    color={colors.app.i}
                                    className="flex-shrink-0"
                                  />
                                </View>
                                <View className="ml-1 flex-row items-center">
                                  <RemixIcon
                                    name="ri-time-line"
                                    size={16}
                                    color={colors.app.d}
                                    className="mr-1"
                                  />
                                  <Font className="text-app-d1 leading-4">
                                    {moment(review.updated_at).fromNow()}
                                  </Font>
                                </View>
                              </View>
                              <View className="flex-row items-center justify-start py-3">
                                <RemixIcon
                                  className="mr-2 self-start h-full"
                                  name="ri-discuss-fill"
                                  size={16}
                                  color={colors.app.d}
                                />
                                <Font
                                  numberOfLines={4}
                                  className="text-app-d flex flex-row items-center leading-4"
                                >
                                  {review.is_edited ? (
                                    <>
                                      <RemixIcon
                                        className="-mb-px self-start"
                                        name="ri-pencil-fill"
                                        size={12}
                                        color={colors.app.d}
                                      />{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {review.message}
                                </Font>
                              </View>
                              <View className="flex-row items-center">
                                <RemixIcon
                                  name="ri-reply-fill"
                                  size={16}
                                  color={colors.app.d}
                                  className="mr-2"
                                />
                                <Font className="text-app-d1">
                                  {[0].includes(review.replies?.length)
                                    ? review.replies?.length
                                    : "-"}{" "}
                                  {review.replies?.length == 1
                                    ? "reply"
                                    : "replies"}
                                </Font>
                              </View>
                            </View>
                          </Pressable>
                        )}
                      />

                      {/* {load_records < reviewsPagination.total && ( */}
                      <View className="flex-row justify-between w-full">
                        {/* {reviewsPagination.from > initial_from && ( */}
                        <Pressable
                          className="p-4 "
                          onPress={() => {
                            if (reviewsPagination.from > initial_from) {
                              const from =
                                reviewsPagination.from === 1
                                  ? 1
                                  : reviewsPagination.from - load_records;
                              const toModulus =
                                reviewsPagination.to % load_records;
                              const to =
                                reviewsPagination.to -
                                (toModulus > 0
                                  ? toModulus
                                  : reviewsPagination.to > load_records
                                  ? load_records
                                  : 0);
                              const pagObj = {
                                from: from,
                                to: to,
                                total: reviewsPagination.total,
                              };
                              setReviewsPagination(pagObj);
                              setProductReviewsPagination({
                                data: pagObj,
                                pid: pid,
                              });
                              // getProductReviews(from - 1, load_records, true);
                              setProductReviewsData(
                                productReviews?.[pid]?.slice(from - 1, to)
                              );
                            }
                          }}
                          disabled={!reviewsPagination.from > initial_from}
                        >
                          <Font
                            className={`${
                              reviewsPagination.from > initial_from
                                ? "text-app-e"
                                : "text-app-d2"
                            }`}
                          >
                            {"<"} Prev
                          </Font>
                        </Pressable>
                        {/* )} */}
                        <View className="flex-1" />
                        {/* {reviewsPagination.to < reviewsPagination.total && ( */}
                        <Pressable
                          className="p-4"
                          onPress={() => {
                            if (
                              reviewsPagination.to < reviewsPagination.total
                            ) {
                              const remList =
                                reviewsPagination.total - reviewsPagination.to;
                              const from =
                                reviewsPagination.from +
                                (remList > 0 ? load_records : 0);
                              const to =
                                reviewsPagination.to +
                                (remList < load_records
                                  ? remList
                                  : load_records);
                              const pagObj = {
                                from: from,
                                to: to,
                                total: reviewsPagination.total,
                              };
                              const listData = productReviews?.[pid]?.slice(
                                from - 1,
                                to
                              );
                              if (listData?.length) {
                                setReviewsPagination(pagObj);
                                setProductReviewsPagination({
                                  data: pagObj,
                                  pid: pid,
                                });
                                setProductReviewsData(listData);
                              } else {
                                getProductReviews(from - 1, load_records, true);
                              }
                            }
                          }}
                          disabled={
                            !reviewsPagination.to < reviewsPagination.total
                          }
                        >
                          <Font
                            className={`${
                              reviewsPagination.to < reviewsPagination.total
                                ? "text-app-e"
                                : "text-app-d2"
                            }`}
                          >
                            Next {">"}
                          </Font>
                        </Pressable>
                        {/* )} */}
                      </View>
                      {/* )} */}
                    </Card>
                  </>
                ) : null}
              </View>
            </>
          )}
        </View>
      </ScrollView>

      {step === 1 ? (
        <View
          className="absolute right-0 bottom-0 mb-4 mr-4 lg:mr-8"
          style={{ zIndex: 999999999 }}
        >
          <View className="relative mb-4">
            <Button
              className={isEditingForm ? "" : "h-18"}
              label={"Delete"}
              type="primary-outline"
              shape="circ"
              onPress={() => setIsDeleteModal(true)}
              iconName={"ri-delete-bin-line"}
              disabled={isDeleteModal}
            />
          </View>
          {isEditingForm && step == 1 && (
            <View className="mb-4">
              <Button
                label="Reset"
                type="regular"
                shape="circ"
                onPress={() =>
                  resetFormData({
                    resetOtherFormData: true,
                    resetGalleryImages: true,
                  })
                }
                iconName="ri-restart-line"
              />
            </View>
          )}
          <View className="relative">
            <Button
              className={isEditingForm ? "" : "h-18"}
              label={isEditingForm ? "Save" : "Saved"}
              type={isEditingForm ? "primary" : "accent"}
              shape="circ"
              onPress={onSaveClickHandler}
              iconName={
                isEditingForm ? "ri-upload-cloud-2-line" : "ri-cloudy-2-line"
              }
              waitState={isUpdating}
              disabled={!isEditingForm}
            />
            {isEditingForm || isUpdating ? (
              <></>
            ) : (
              <RemixIcon
                className="absolute self-center top-4 mt-[4px] right-3"
                name="ri-check-line"
                size={18}
                color={colors.app.a}
              />
            )}
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}
