import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Maps } from "../../components/maps";
import { Button, Input } from "../../components/input";
import { isSignedIn } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { useAuthContext } from "../../contexts/auth-context";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { catchAPIErrorText } from "../../utils/error-handler";
import Toast from "react-native-toast-message";

export default function AddAddress({ navigation, route }) {
  const { user } = useAuthContext();
  const { checkRefreshList } = route.params;
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const [address, setAddress] = useState({
    title: "",
    recipientName: "",
    phone: "",
    address: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });

  const [isAddressApiLoading, setIsAddressApiLoading] = useState();

  function onSaveAddressHandler(key, value) {
    setAddress((data) => ({ ...data, [key]: value }));
  }

  const onSaveAddress = async () => {
    const token = await isSignedIn();
    const data = {
      title: address?.title,
      recipient_name: address?.recipientName,
      address_line_1: address?.address,
      city: address?.city,
      state: address?.state,
      country: address?.country,
      postal_code: address?.postalCode,
      phone: address?.phone,
    };
    try {
      setIsAddressApiLoading(true);

      const res = await axiosInstance.post(
        apiURIV2 + "/users/me/addresses/shipping",
        { ...data, make_default: false, user_id: user?.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.status === 201) {
        checkRefreshList();
        navigation.navigate("manage-addresses");
      } else {
        console.log("An error occurred while adding the address. : ", res.data);
      }
    } catch (e) {
      console.log("An error occurred while adding the address. : ", e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Bookings"),
      });
    } finally {
      setIsAddressApiLoading(false);
    }
  };

  return (
    <View className="relative flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        overScrollMode="never"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        keyboardShouldPersistTaps="always"
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Add Address
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Manage Shipping Addrsses">
                <View className="flex-row flex-wrap mb-4">
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="text"
                      label="Title"
                      placeholder="Title"
                      value={address.title}
                      setValue={(e) => {
                        onSaveAddressHandler("title", e);
                      }}
                    />
                  </View>
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="text"
                      label="Recipient Name"
                      placeholder="Recipient Name"
                      value={address.recipientName}
                      setValue={(e) => {
                        onSaveAddressHandler("recipientName", e);
                      }}
                    />
                  </View>
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="number"
                      label="Recepient Phone"
                      placeholder="Recepient Phone"
                      value={address.phone}
                      onChangeText={(e) => onSaveAddressHandler("phone", e)}
                    />
                  </View>
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="text"
                      label="Address"
                      placeholder="Address"
                      value={address.address}
                      setValue={(e) => {
                        onSaveAddressHandler("address", e);
                      }}
                    />
                  </View>
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="text"
                      label="City"
                      placeholder="City"
                      value={address.city}
                      setValue={(e) => {
                        onSaveAddressHandler("city", e);
                      }}
                    />
                  </View>
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="text"
                      label="Region"
                      placeholder="Region"
                      value={address?.state}
                      setValue={(e) => {
                        onSaveAddressHandler("state", e);
                      }}
                    />
                  </View>
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="text"
                      label="Country"
                      placeholder="Country"
                      value={address.country}
                      setValue={(e) => {
                        onSaveAddressHandler("country", e);
                      }}
                    />
                  </View>
                  <View className="w-full lg:w-1/2 px-4 py-1">
                    <Input
                      type="text"
                      label="Postal Code"
                      placeholder="Postal Code"
                      value={address.postalCode}
                      setValue={(e) => {
                        onSaveAddressHandler("postalCode", e);
                      }}
                    ></Input>
                  </View>
                  {/* <View className="w-full h-60 p-4">
                    <Maps></Maps>
                  </View> */}
                  <View className="w-full lg:w-1/2 px-4 py-1 justify-end">
                    <Button
                      type="primary"
                      label="Save"
                      onPress={() => {
                        onSaveAddress();
                        Toast.show({
                          type: "success",
                          text1: "SuccessFully Added",
                          text2: "Address Added successfully!",
                        });
                      }}
                    />
                  </View>
                </View>
              </Card>
            </View>
            <View className=" flex-1 w-full  lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="w-full flex-1 p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className=" w-full flex-1 flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
    </View>
  );
}
