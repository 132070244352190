import React, { useEffect } from "react";
import { Pressable, View, StyleSheet } from "react-native";
import {
  getStats,
  getRecentActivites,
  clearAllPackages,
} from "../../services/api";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Footer } from "../../components/footer";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { ScrollView } from "react-native";
import SvgImg1 from "../../../assets/db-1.svg";
import SvgImg2 from "../../../assets/db-2.svg";
import SvgImg3 from "../../../assets/db-3.svg";
import SvgImg4 from "../../../assets/db-4.svg";
import SVGLoader from "../../utils/SVGLoader";
import ConfirmModal from "../../components/general/modals/ConfirmModal";

export default function Home({ navigation, route }) {
  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  const [deleteModal, setIsDeleteModal] = React.useState(false);
  const itemsPerPage = 2;
  const totalItems = activities.length;

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      let res = await getStats();

      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();

      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const clearAllActivities = async () => {
    setIsDeleteModal(false);
    try {
      let res = await clearAllPackages();
      console.log(res, "check clear activities");
      if (res.status === 200 || res.data?.status == "success") {
        setActivities([]);
      }
    } catch (err) {
      console.log("clearAllActivities err:", err);
    }
  };

  function timeAgo(timestamp) {
    const currentTime = Math.floor(Date.now() / 1000);
    const secondsAgo = currentTime - Math.floor(timestamp / 1000);

    const minutes = Math.floor(secondsAgo / 60);
    const hours = Math.floor(minutes / 60);

    if (hours === 0) {
      if (minutes === 0) {
        return "Just now";
      } else if (minutes === 1) {
        return "1 minute ago";
      } else {
        return `${minutes} minutes ago`;
      }
    } else if (hours === 1 && minutes <= 59) {
      return "1 hour ago";
    } else {
      return `${hours} hours ago`;
    }
  }

  const handleNextRecentActivities = () => {
    if (visibleIndex + itemsPerPage < totalItems) {
      setVisibleIndex(visibleIndex + itemsPerPage);
    }
  };

  const handlePrevRecentActivities = () => {
    if (visibleIndex - itemsPerPage >= 0) {
      setVisibleIndex(visibleIndex - itemsPerPage);
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ConfirmModal
          visible={deleteModal}
          onCancel={() => setIsDeleteModal(false)}
          onConfirm={() => clearAllActivities()}
          confirmText={"Clear"}
          bodyText={"Are you sure you want to Clear all Activities ?"}
          BodyComponent={false}
        ></ConfirmModal>
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-5 hidden lg:flex px-4">
            <Font className="text-lg font-semibold text-app-d">Home</Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className="w-full p-0 pt-0">
              <View className="p-0 lg:px-4">
                <Card title="Summary" className="mb-4">
                  <View className="w-full pt-0 -mt-1">
                    <View className="flex-row w-full flex-wrap px-2 lg:px-0 pb-4">
                      <View className="p-2 lg:p-4 w-1/2 lg:w-1/4 h-40 lg:h-60">
                        <View className="w-full h-full bg-gray-50 border border-gray-200 p-4 rounded-lg justify-between ">
                          <View className="w-10 h-10 lg:w-16 lg:h-16">
                            <SVGLoader
                              src={SvgImg1}
                              height={40}
                              width={40}
                              webHeight={"40em"}
                              webWidth={"auto"}
                            />
                          </View>
                          <Font className="mt-2 lg:mt-8 text-2xl lg:text-4xl font-semibold text-app-d">
                            {stats?.total_listings}
                          </Font>
                          <Font className="text-sm lg:text-lg text-app-d1 font-medium">
                            Total Listings
                          </Font>
                        </View>
                      </View>
                      <View className="p-2 lg:p-4 w-1/2 lg:w-1/4 h-40 lg:h-60">
                        <View className="w-full h-full bg-gray-50 border border-gray-200 p-4 rounded-lg justify-between ">
                          <View className="w-10 h-10 lg:w-16 lg:h-16">
                            <SVGLoader
                              src={SvgImg2}
                              height={40}
                              width={40}
                              webHeight={"40em"}
                              webWidth={"auto"}
                            />
                          </View>
                          <Font className="mt-2 lg:mt-8 text-2xl lg:text-4xl font-semibold text-app-d">
                            {stats?.total_products}
                          </Font>
                          <Font className="text-sm lg:text-lg text-app-d1 font-medium">
                            Total Products
                          </Font>
                        </View>
                      </View>
                      <View className="p-2 lg:p-4 w-1/2 lg:w-1/4 h-40 lg:h-60">
                        <View className="w-full h-full bg-gray-50 border border-gray-200 p-4 rounded-lg justify-between ">
                          <View className="w-10 h-10 lg:w-16 lg:h-16">
                            <SVGLoader
                              src={SvgImg3}
                              height={40}
                              width={40}
                              webHeight={"40em"}
                              webWidth={"auto"}
                            />
                          </View>
                          <Font className="mt-2 lg:mt-8 text-2xl lg:text-4xl font-semibold text-app-d">
                            {stats?.bookings_completed}
                          </Font>
                          <Font className="text-sm lg:text-lg text-app-d1 font-medium ">
                            Bookings Completed
                          </Font>
                        </View>
                      </View>
                      <View className="p-2 lg:p-4 w-1/2 lg:w-1/4 h-40 lg:h-60">
                        <View className="w-full h-full bg-gray-50 border border-gray-200 p-4 rounded-lg justify-between ">
                          <View className="w-10 h-10 lg:w-16 lg:h-16">
                            <SVGLoader
                              src={SvgImg4}
                              height={40}
                              width={40}
                              webHeight={"40em"}
                              webWidth={"auto"}
                            />
                          </View>
                          <Font className="mt-4 lg:mt-8 text-2xl lg:text-4xl font-semibold text-app-d">
                            {stats?.orders_fulfilled}
                          </Font>
                          <Font className="text-sm lg:text-lg text-app-d1 font-medium">
                            Orders Fulfilled
                          </Font>
                        </View>
                      </View>
                    </View>
                  </View>
                </Card>
              </View>
              <View className="flex-col lg:flex-row">
                <View className="p-0 lg:p-4 flex-1">
                  <Card
                    title="Recent Activities"
                    Widget={() =>
                      activities?.length ? (
                        <Pressable
                          onPress={() => {
                            setIsDeleteModal(true);
                          }}
                        >
                          <Font className="text-app-e">Clear All</Font>
                        </Pressable>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <View className="w-full p-4 pt-0">
                      {activities?.length > 0 ? (
                        activities
                          .slice(visibleIndex, visibleIndex + itemsPerPage)
                          .map((item, index) => {
                            return (
                              <View
                                key={index}
                                className="bg-gray-50 border border-gray-200 p-2 flex-row rounded-lg my-1 items-center"
                                style={{ marginBottom: 8 }}
                              >
                                <View className="h-12 w-12 p-2 bg-gray-100 rounded-full items-center justify-center">
                                  <RemixIcon
                                    name="ri-stack-line"
                                    size={28}
                                    color={colors.app.e}
                                  ></RemixIcon>
                                </View>
                                <View className="justify-center ml-4 flex-1">
                                  <Font
                                    className="text-app-d"
                                    numberOfLines={2}
                                  >
                                    {item.title}
                                  </Font>
                                  <Font className="text-app-d1">
                                    {timeAgo(item.timestamp)}
                                  </Font>
                                </View>
                              </View>
                            );
                          })
                      ) : (
                        <View className="flex flex-row justify-center items-center py-8">
                          <Font className="text-app-d1">
                            No Activities Found
                          </Font>
                        </View>
                      )}
                    </View>
                    {activities?.length ? (
                      <View className="flex-row justify-between w-full pb-2">
                        <Pressable
                          onPress={() => handlePrevRecentActivities()}
                          className="p-4 pt-0"
                        >
                          <Font className="text-app-e"> {"<"} Prev</Font>
                        </Pressable>
                        <Pressable
                          onPress={() => handleNextRecentActivities()}
                          className="p-4 pt-0"
                        >
                          <Font className="text-app-e"> Next {">"}</Font>
                        </Pressable>
                      </View>
                    ) : (
                      <></>
                    )}
                  </Card>
                </View>
              </View>
            </View>
          </View>
          <Footer navigation={navigation} />
        </View>
      </ScrollView>
    </View>
  );
}
